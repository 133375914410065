import React, { useEffect, useContext, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Parallax } from 'swiper/modules';
import dishesServices from '../../api/DishesServices/disheseService.js';
import RoleRedirects from '../../enums/roleRedirects.js';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import '../../css/login.css';

function PagesLogin() {
  const context = useContext(AppSettings);
  const [redirect, setRedirect] = useState(null);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    rememberMe: false,
  });
  const [errors, setErrors] = useState({});
  const [loginError, setLoginError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);
    context.setAppContentClass('p-0');
    context.setAppContentFullHeight(true);

    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass('');
      context.setAppContentFullHeight(false);
    };
  }, [context]);

  function validateForm() {
    const newErrors = {};
    if (!formData.username.trim()) {
      newErrors.username = 'Username is required';
    }
    if (!formData.password) {
      newErrors.password = 'Password is required';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (validateForm()) {
        setIsLoading(true);
        try {
            const response = await dishesServices().login(formData.username, formData.password);
            const { token, roleName, stallID, tableStatus, employeeID, displayName } = response.data;
            
            let defaultStallID = stallID;

            if ((roleName === "Cashier" || roleName === "Outlet_Cashier") && stallID?.length > 0) {
                defaultStallID = "DefaultStallID";
            }

            localStorage.setItem('token', token);
            localStorage.setItem('displayName', displayName);
            localStorage.setItem('roleName', roleName);
            localStorage.setItem('stallID', defaultStallID);
            localStorage.setItem('tableStatus', tableStatus);
            localStorage.setItem('employeeID', employeeID);
            localStorage.setItem('floatValue', true);
            localStorage.setItem('loginResponse', JSON.stringify(response.data));

            const redirectPath = RoleRedirects[roleName] || RoleRedirects.Default;
            setRedirect(redirectPath);
          } catch (error) {
              setLoginError(error.response?.data?.message || 'Login failed. Please check your credentials.');
              console.error("Login error:", error);
          } finally {
              setIsLoading(false);
          }
      }
  }

  if (redirect) {
    return <Navigate to={redirect} />;
  }


  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  }

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <div className="login-container">
      <div className="login-form">
        <div className="login-form-inner">
          <div className="logo">
          </div>
          <h1>Sign In</h1>

          <form onSubmit={handleSubmit} noValidate>
            {loginError && <div className="alert alert-danger" role="alert">{loginError}</div>}
            <div className="login-form-group">
              <label htmlFor="username">Username <span className="required-star">*</span></label>
              <input
                type="text"
                placeholder="Username"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
              />
              {errors.username && <div className="error-message" style={{ color: 'red' }}>{errors.username}</div>}
            </div>
            <div className="login-form-group">
              <label htmlFor="pwd">Password <span className="required-star">*</span></label>
              <div className="password-wrapper">
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  id="pwd"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <span 
                  className="show-password-toggle"
                  onClick={() => setShowPassword(prev => !prev)}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </span>
              </div>
              {errors.password && <div className="error-message" style={{ color: 'red' }}>{errors.password}</div>}
            </div>

            <div className="login-form-group single-row">
              <div className="custom-check">
                <input
                  type="checkbox"
                  id="rememberMe"
                  name="rememberMe"
                  checked={formData.rememberMe}
                  onChange={handleChange}
                />
                <label htmlFor="rememberMe">Remember me</label>
              </div>
              <Link to="/login" className="link forgot-link">Forgot Password ?</Link>
            </div>

            <button 
              type="submit" 
              className="rounded-button login-cta"
              disabled={isLoading}
            >
              {isLoading ? 'Signing In...' : 'Login'}
            </button>
          </form>

        </div>
      </div>

      {/* removed unnecessary part */}
      {/* Swiper Slider Section */}
      {/* <div className="onboarding">
        <Swiper
          pagination={{ clickable: true }}
          autoplay={{ delay: 3500, disableOnInteraction: false }} 
          parallax={true}
          loop={true}
          speed={600}
          grabCursor={true}
          modules={[Pagination, Autoplay, Parallax]} 
          className="swiper-container"
        >
          <SwiperSlide>
            <div className="slide-content">
              <img src="/assets/img/1.png" alt="Slide 1" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-content">
              <img src="/assets/img/2.png" alt="Slide 2" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-content">
              <img src="/assets/img/3.png" alt="Slide 3" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div> */}
    </div>
  );
}

export default PagesLogin;
