const MenuItemsAccessPermissions = Object.freeze({
    MONGOLIAN: ["MONGOLIAN"],
    CAFEWALK: ["CAFEWALK"],
    GELETERIA: ["GELETERIA"],
    BAKESNCAKE: ["BAKESNCAKE"],
    FOODBOOTH: ["FOODBOOTH"],
    TERRACEBAR: ["TERRACEBAR"],
    THEFRESH: ["THEFRESH"],
    SRILANKAN: ["SRILANKAN"],


    //----Temporaraly commented - untill requirement confirmation------- 
    // MONGOLIAN: ["MONGOLIAN", "SRILANKAN", "TERRACEBAR", "WSDESSERT", "GELETERIA"],
    // CAFEWALK: ["CAFEWALK"],
    // GELETERIA: ["GELETERIA"],
    // BAKESNCAKE: ["BAKESNCAKE"],
    // FOODBOOTH: ["FOODBOOTH"],
    // TERRACEBAR: ["TERRACEBAR", "SRILANKAN", "MONGOLIAN", "KITCHEN"],
    // THEFRESH: ["THEFRESH", "WSDESSERT"],
    // SRILANKAN: ["SRILANKAN", "MONGOLIAN", "TERRACEBAR", "WSDESSERT", "GELETERIA", "KITCHEN"],
});

export default MenuItemsAccessPermissions;