import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import acumaticaServices from '../../api/DishesServices/acumaticaServices.js';
import pushDataAcumaticaServices from '../../api/DishesServices/pushDataAcumaticaService.js';
import ExecutiveListAcumaticaServices from '../../api/ExecutiveServices/executiveListAcumaticaService.js';
import Swal from 'sweetalert2';
import dishesServices from '../../api/DishesServices/disheseService.js';

function Header() {
    const context = useContext(AppSettings);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const roleName = localStorage.getItem('roleName');
    const token = localStorage.getItem('token');
    const currentEmployeeID = localStorage.getItem('employeeID');
    const [isLoggingOut, setIsLoggingOut] = useState(false);


    const notificationData = [{
        icon: 'fa fa-receipt fa-lg fa-fw text-success',
        title: 'Your store has a new order for 2 items totaling $1,299.00',
        time: 'just now'
    }, {
        icon: 'far fa-user-circle fa-lg fa-fw text-body text-opacity-25',
        title: '3 new customer accounts created',
        time: '2 minutes ago'
    }, {
        img: '/assets/img/icon/android.svg',
        title: 'Your android application has been approved',
        time: '5 minutes ago'
    }, {
        img: '/assets/img/icon/paypal.svg',
        title: 'Paypal payment method has been enabled for your store',
        time: '10 minutes ago'
    }];

    const toggleAppSidebarDesktop = () => {
        context.handleToggleAppSidebarMinified();
    }

    const toggleAppSidebarMobile = () => {
        context.handleToggleAppSidebarMobile();
    }

    const handleSync = async () => {
        setIsLoading(true);
        let allSuccessful = true;
    
        try {
            await acumaticaServices().acumaticaUserRoleIntegration().catch(error => {
                allSuccessful = false;
                Swal.fire({
                    icon: 'warning',
                    title: 'User Role Integration Failed',
                    text: 'An error occurred during User Role Integration. Please check and try again.'
                });
            });
    
            await acumaticaServices().acumaticaTaxList().catch(error => {
                allSuccessful = false;
                Swal.fire({
                    icon: 'warning',
                    title: 'Tax List Integration Failed',
                    text: 'An error occurred during Tax List Integration. Please check and try again.'
                });
            });
    
            await acumaticaServices().acumaticaDiscountIntegration().catch(error => {
                allSuccessful = false;
                Swal.fire({
                    icon: 'warning',
                    title: 'Discount Integration Failed',
                    text: 'An error occurred during Discount Integration. Please check and try again.'
                });
            });
    
            await acumaticaServices().acumaticaStallMenuItemIntegration().catch(error => {
                allSuccessful = false;
                Swal.fire({
                    icon: 'warning',
                    title: 'Stall Menu Item Integration Failed',
                    text: 'An error occurred during Stall Menu Item Integration. Please check and try again.'
                });
            });
    
            await acumaticaServices().acumaticaUserStallIntegration().catch(error => {
                allSuccessful = false;
                Swal.fire({
                    icon: 'warning',
                    title: 'User Stall Integration Failed',
                    text: 'An error occurred during User Stall Integration. Please check and try again.'
                });
            });
    
            await acumaticaServices().acumaticaVoidReasonIntegration().catch(error => {
                allSuccessful = false;
                Swal.fire({
                    icon: 'warning',
                    title: 'Void Reason Integration Failed',
                    text: 'An error occurred during Void Reason Integration. Please check and try again.'
                });
            });
    
            if (allSuccessful) {
                Swal.fire({
                    icon: 'success',
                    title: 'Sync completed successfully!',
                    showConfirmButton: false,
                    timer: 1500
                });
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Partial Sync Completed',
                    text: 'Some integrations failed. Please check the warnings above.',
                    showConfirmButton: true
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An unexpected error occurred during sync. Please try again.'
            });
        } finally {
            setIsLoading(false); // Stop loader
        }
    };
    

    const syncTran = async () => {
        setIsLoading(true);
        try {
            await pushDataAcumaticaServices().pushTransactionTables();
            
            Swal.fire({
                icon: 'success',
                title: 'Transactions synced successfully!',
                showConfirmButton: false,
                timer: 1500
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred during sync. Please try again.'
            });
        }
        finally {
            setIsLoading(false); // Stop loader
        }
    };

    const syncExecList = async () => {
        setIsLoading(true);
        try {
            await ExecutiveListAcumaticaServices().syncExecutiveList();
            
            Swal.fire({
                icon: 'success',
                title: 'Executive List synced successfully!',
                showConfirmButton: false,
                timer: 1500
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred during sync. Please try again.'
            });
        }
        finally {
            setIsLoading(false); // Stop loader
        }
    };

    const handleLogout = async () => {
        setIsLoggingOut(true);
        try {
            let logDetailsResponse = await dishesServices().getLogDetails();
            let logDetails = logDetailsResponse.data;
    
            const employeeLogDetails = logDetails.filter(log => log.employeeID === currentEmployeeID);
    
            if (employeeLogDetails.length > 0) {
                const lastLogRecord = employeeLogDetails.sort((a, b) => new Date(b.loginTime) - new Date(a.loginTime))[0];
    
                if (!lastLogRecord.loginOutTime) {
                    // Get the current UTC time
                    const utcNow = new Date(Date.now());
    
                    await dishesServices().updateLogoutTime(lastLogRecord.logId, utcNow);
                    console.log("Logout time updated successfully.");
                } else {
                    console.log("Logout time already set.");
                }
            } else {
                console.warn("No log records found for current employee.");
            }
    
            // Clear local storage and redirect
            localStorage.removeItem('employeeID');  
            localStorage.removeItem('roleName');
            localStorage.removeItem('stallID');
            localStorage.removeItem('token');
            localStorage.removeItem("floatValue");
    
    
            Swal.fire({
                icon: 'info',
                title: 'Logged Out',
                text: 'You have been logged out.',
                showConfirmButton: false,
                timer: 2000
            }).then(() => {
                navigate('/login');
            });
    
          } catch (error) {
              console.error("Error during logout process:", error);
              Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong during logout!',
              });
          } finally {
              setIsLoggingOut(false);
          }
      };

    return (
        <div id="header" className="app-header">
         
            <div className="mobile-toggler">
                <button type="button" className="menu-toggler" onClick={toggleAppSidebarMobile}>
                    <span className="bar"></span>
                    <span className="bar"></span>
                </button>
            </div>

            <div className="brand">
                <div className="desktop-toggler">
                    <button type="button" className="menu-toggler" onClick={toggleAppSidebarDesktop}>
                        <span className="bar"></span>
                        <span className="bar"></span>
                    </button>
                </div>

                <Link to="/" className="brand-logo">
                    <img src="/assets/img/kcc-logo.png" className="invert-dark" alt="" height="70" />
                </Link>
            </div>
         
            <div className="menu">
                <form className="menu-search" method="POST" name="header_search_form">
                    <div className="menu-search-icon"><i className="fa fa-search"></i></div>
                    <div className="menu-search-input">
                        <input type="text" className="form-control" placeholder="Search menu..." />
                    </div>
                </form>
                {roleName === "Admin" && (
                <div className="d-flex flex-wrap">
                    <button className="btn btn-theme flex-fill d-flex align-items-center justify-content-center m-10px me-10px h-40px" onClick={handleSync}>
                        Start Sync
                    </button>
                    <button className="btn btn-theme flex-fill d-flex align-items-center justify-content-center m-10px me-10px h-40px" onClick={syncTran}>
                        Send Transactions
                    </button>
                    <button className="btn btn-theme flex-fill d-flex align-items-center justify-content-center m-10px me-10px h-40px" onClick={syncExecList}>
                        Sync Executive List
                    </button>
                </div>
                    
                )}
                <div className="menu-item dropdown">
                    <a href="#/" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
                        <div className="menu-img online">
                            <img src="/assets/img/user/user.jpg" alt="" className="ms-100 mh-100 rounded-circle" />
                        </div>
                        <div className="menu-text">johnsmith@studio.com</div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end me-lg-3">
                        <Link to="/profile" className="dropdown-item d-flex align-items-center">Edit Profile <i className="fa fa-user-circle fa-fw ms-auto text-body text-opacity-50"></i></Link>
                        <Link to="/email/inbox" className="dropdown-item d-flex align-items-center">Inbox <i className="fa fa-envelope fa-fw ms-auto text-body text-opacity-50"></i></Link>
                        <Link to="/calendar" className="dropdown-item d-flex align-items-center">Calendar <i className="fa fa-calendar-alt fa-fw ms-auto text-body text-opacity-50"></i></Link>
                        <Link to="/settings" className="dropdown-item d-flex align-items-center">Setting <i className="fa fa-wrench fa-fw ms-auto text-body text-opacity-50"></i></Link>
                        <div className="dropdown-divider"></div>
                        <a
                            href="/login"
                            onClick={(e) => {
                                e.preventDefault();
                                if (!isLoggingOut) {
                                    handleLogout();
                                }
                            }}
                            className="dropdown-item d-flex align-items-center"
                        >
                            {isLoggingOut ? 'Logging Out...' : 'Log Out'} 
                            <i className="fa fa-toggle-off fa-fw ms-auto text-body text-opacity-50"></i>
                        </a>
                    </div>
                </div>
            </div>
          
           {isLoading && (
            <div style={loaderStyle}>
                <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )}
        </div>
        
    );
}

const loaderStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
};

export default Header;
