import React from "react";
import {API_CONFIGURATIONS} from "../constants/apiConfigurations";
import axios from "axios";

const discountService = () => {
    
    const getAlldiscounts = async () => {
        const response = await axios
            .get(`${API_CONFIGURATIONS.GET_ALL_DISCOUNTS}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error.response;
            });
        return response;
    };
   
    return {
        getAlldiscounts,
        
    };
}
export default discountService;