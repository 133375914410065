import React, { useEffect, useState, useRef  } from 'react';
import Header from './components/header/header.jsx';
import TopNav from './components/top-nav/top-nav.jsx';
import Sidebar from './components/sidebar/sidebar.jsx';
import Content from './components/content/content.jsx';
import Footer from './components/footer/footer.jsx';
// import ThemePanel from './components/theme-panel/theme-panel.jsx';
import { AppSettings } from './config/app-settings.js';
import { slideToggle } from './composables/slideToggle.js';

function App() {
	var defaultOptions = {
		appMode: '',
		appTheme: '',
		appCover: '',
		appHeaderNone: false,
		appSidebarNone: true,
		appSidebarMinified: true,
		appSidebarMobile: false,
		appContentNone: false,
		appContentClass: '',
		appContentFullHeight: false,
		appBoxedLayout: false,
		appFooter: false,
		appTopNav: false
	};
	const [appHeaderNone, setAppHeaderNone] = useState(defaultOptions.appHeaderNone);
	const [appSidebarNone, setAppSidebarNone] = useState(defaultOptions.appSidebarNone);
	const [appSidebarMinified, setAppSidebarMinified] = useState(defaultOptions.appSidebarMinified);
	const [appSidebarMobile, setAppSidebarMobile] = useState(defaultOptions.appSidebarMobile);
	const [appContentNone, setAppContentNone] = useState(defaultOptions.appContentNone);
	const [appContentClass, setAppContentClass] = useState(defaultOptions.appContentClass);
	const [appContentFullHeight, setAppContentFullHeight] = useState(defaultOptions.appContentFullHeight);
	const [appBoxedLayout, setAppBoxedLayout] = useState(defaultOptions.appBoxedLayout);
	const [appFooter, setAppFooter] = useState(defaultOptions.appFooter);
	const [appTopNav, setAppTopNav] = useState(defaultOptions.appTopNav);
	
	var handleToggleAppSidebarMinified = () => {
    setAppSidebarMinified((prevValue) => !prevValue);
  	if (localStorage) {
      localStorage.appSidebarMinified = !appSidebarMinified;
    }
  };
  
  var handleToggleAppSidebarMobile = () => {
  	setAppSidebarMobile((prevValue) => !prevValue);
  };
		
	var handleSetAppTheme = (value) => {
		if (value) {
			var newTheme = value;
			for (var x = 0; x < document.body.classList.length; x++) {
				if ((document.body.classList[x]).indexOf('theme-') > -1 && document.body.classList[x] !== newTheme) {
					document.body.classList.remove(document.body.classList[x]);
				}
			}
			
			if (localStorage && value) {
				localStorage.appTheme = value;
			}
			
			document.body.classList.add(newTheme);
			document.dispatchEvent(new Event('theme-reload'));
		}
	}
	
	var handleSetAppMode = (value) => {
		if (value) {
			document.documentElement.setAttribute('data-bs-theme', value);
		}
	}
		
	var handleSetAppSidebarMinified = (value) => {
		if (value) {
			var elm = document.querySelector('.app');
			if (elm) {
				if (!(elm.classList.contains('app-with-top-nav') && elm.classList.contains('app-without-sidebar')) && value === 'true') {
					setAppSidebarMinified(value);
					setTimeout(() => {
						window.dispatchEvent(new Event('resize'));
					}, 150);
				}
			}
		}
	}
		
	var handleSetAppSidebarMobile = (value) => {
		if (value) {
			var elm = document.querySelector('.app');
			if (elm) {
				if (!(elm.classList.contains('app-with-top-nav') && elm.classList.contains('app-without-sidebar'))) {
					setAppSidebarMobile(value);
				} else {
					slideToggle(document.querySelector('.app-top-nav'));
				}
			}
		}
	}
		
	var handleSetAppBoxedLayout = (value) => {
		if (value) {
			document.body.classList.add('app-with-bg');
		} else {
			document.body.classList.remove('app-with-bg');
		}
		setAppBoxedLayout(value);
	}

	const hasWindowOpened = useRef(false); // In-memory flag

	useEffect(() => {
	  if (hasWindowOpened.current) return; // Prevent multiple opens
  
	  const isCustomerWindowOpened = sessionStorage.getItem('customerWindowOpened');

if (!isCustomerWindowOpened) {
  const primaryScreenWidth = window.screen.width;
  const primaryScreenHeight = window.screen.height;

  // Debug screen properties
  console.log("Screen Properties:", {
    primaryScreenWidth,
    primaryScreenHeight,
    availWidth: window.screen.availWidth,
    availHeight: window.screen.availHeight,
    left: window.screen.left,
    top: window.screen.top,
  });

  // Calculate position for the second screen
  const extendedScreenX = primaryScreenWidth + 5; // Position on second screen
  const extendedScreenY = 0;

  // Define dimensions for the new window
  const width = Math.min(800, window.screen.availWidth);
  const height = Math.min(600, window.screen.availHeight);

  const windowFeatures = [
    `width=${width}`,
    `height=${height}`,
    `left=${extendedScreenX}`,
    `top=${extendedScreenY}`,
    'noopener',
  ].join(',');

  console.log("Window Features:", windowFeatures);

  // Open the new window
  const customerWindow = window.open('/customer', '_blank', windowFeatures);

  if (customerWindow) {
    sessionStorage.setItem('customerWindowOpened', 'true');
    hasWindowOpened.current = true;
    console.log("Customer window opened successfully.");
  } else {
    console.error("Failed to open customer window. Check pop-up blocker settings.");
	sessionStorage.setItem('customerWindowOpened', 'true');
  }

  // Cleanup when the component unmounts
  return () => {
    if (customerWindow && !customerWindow.closed) {
      customerWindow.close();
      console.log("Customer window closed.");
    }
    sessionStorage.removeItem('customerWindowOpened');
	
  };
  
}

	  
	}, []);

	const providerValue = {
		setAppHeaderNone, 
		setAppSidebarNone, 
		setAppSidebarMinified, 
		setAppSidebarMobile,
		setAppContentNone, 
		setAppContentClass,
		setAppContentFullHeight,
		setAppBoxedLayout,
		setAppFooter,
		setAppTopNav,
		handleSetAppTheme,
		handleSetAppMode,
		handleSetAppBoxedLayout,
		handleSetAppSidebarMinified,
		handleSetAppSidebarMobile,
		handleToggleAppSidebarMinified,
		handleToggleAppSidebarMobile
	};

	
	return (
		<AppSettings.Provider value={providerValue}>
			<div className={
				'app ' +
				(appBoxedLayout ? 'app-boxed-layout ' : '') + 
				(appContentFullHeight ? 'app-content-full-height ' : '') + 
				(appHeaderNone ? 'app-without-header ' : '') + 
				(appSidebarNone ? 'app-without-sidebar ' : '') + 
				(appSidebarMinified ? 'app-sidebar-minified ' : '') + 
				(appSidebarMobile ? 'app-sidebar-mobile-toggled' : '') +
				(appFooter ? 'app-footer-fixed ' : '') + 
				(appTopNav ? 'app-with-top-nav ' : '')
			}>
				{!appHeaderNone && (<Header />)}
	
				
				{!appContentNone && (<Content className={appContentClass} />)}
				{appFooter && (<Footer />)}
				{/* <ThemePanel /> */}
			</div>
		</AppSettings.Provider>
	)
}

export default App;