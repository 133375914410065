import React from "react";
import {API_CONFIGURATIONS} from "../constants/apiConfigurations";
import axios from "axios";

const taxServices = () => {
    
    const getAllTax = async () => {
        const response = await axios
            .get(API_CONFIGURATIONS.GET_ALL_TAX)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error.response;
            });
        return response;
    };
   
    return {
        getAllTax,
        
    };
}
export default taxServices;