import React, { useEffect, useContext, useState, useRef } from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import NotificationModal from '../notification/notification'; 
import { useLocation } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import PosHeader from '../../components/topbar/posHeader.jsx';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const TableManagement = () => {
    const [tableId, setTableCode] = useState('');
    const [selectedStallId, setSelectedStallId] = useState('');
    const [tables, setTables] = useState([]);
    const [stallIds, setStallIds] = useState([]); 
    const [selectedTableId, setSelectedTableId] = useState(null); 
    const [error, setError] = useState('');
    const [message, setMessage] = useState(''); 
    const [showDeleteModal, setShowDeleteModal] = useState(false); 
    const [showNotification, setShowNotification] = useState(false); 
    const [tableData, setTableData] = useState([]);
    const [filteredTables, setFilteredTables] = useState([]);

    useEffect(() => {
        fetchTables();
        fetchStalls();

    }, []);

    useEffect(() => {
        if (selectedStallId) {
            const filtered = tables.filter(table => table.stallId === selectedStallId);
            setFilteredTables(filtered);
        } else {
            setFilteredTables(tables);
        }
    }, [selectedStallId, tables]);

    const fetchTables = async () => {
        try {
            const response = await fetch(`${BASE_URL}/Table`);
            if (response.ok) {
                const data = await response.json();
                setTables(data);
                setFilteredTables(data);
            } else if (response.status === 404) {
                setError('No tables found. The table might be empty.');
            } else {
                setError('Failed to fetch tables');
            }
        } catch (error) {
            setError('Error fetching tables: ' + error.message);
        }
    };

    const fetchStalls = async () => {
        try {
            const response = await fetch(`${BASE_URL}/Stall`);
            if (response.ok) {
                const data = await response.json();
                const stallIds = data.map(stall => stall.stallId); 
                setStallIds(stallIds); 
            } else if (response.status === 404) {
                setError('No stalls found. The stall list might be empty.');
            } else {
                setError('Failed to fetch stalls');
            }
        } catch (error) {
            setError('Error fetching stalls: ' + error.message);
        }
    };
    
    const showNotificationMessage = (msg) => {
        setMessage(msg);
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false);
            setMessage(''); 
        }, 3000); 
    };

    const handleAdd = async () => {
        if (!tableId || !selectedStallId) {
            setError('Please enter both table code and select a stall code');
            return;
        }

        try {
            const response = await fetch(`${BASE_URL}/Table`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ tableId, stallId: selectedStallId }),
            });

            if (response.ok) {
                fetchTables(); 
                fetchStalls();
                setTableCode('');
                setSelectedStallId('');
                showNotificationMessage('Table added successfully!'); 
                setError('');
            } else {
                const errorData = await response.json();
                setError('Failed to add table: ' + (errorData.message || response.statusText));
            }
        } catch (error) {
            setError('Error adding table: ' + error.message);
        }
    };

    const handleUpdate = async () => {
        if (!selectedTableId || !tableId || !selectedStallId) {
            setError('Please select a row and ensure all fields are filled');
            return;
        }

        try {
            const response = await fetch(`${BASE_URL}/Table`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: selectedTableId, tableId, stallId: selectedStallId }),
            });

            if (response.ok) {
                fetchTables(); 
                fetchStalls();
                setTableCode('');
                setSelectedStallId('');
                setSelectedTableId(null);
                showNotificationMessage('Table updated successfully!'); 
                setError('');
            } else {
                const errorData = await response.json();
                setError('Failed to update table: ' + (errorData.message || response.statusText));
            }
        } catch (error) {
            setError('Error updating table: ' + error.message);
        }
    };

    const handleDelete = async () => {
        if (!selectedTableId) {
            setError('Please select a row to delete');
            return;
        }

        try {
            const response = await fetch(`${BASE_URL}/Table/${selectedTableId}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                fetchTables(); 
                fetchStalls();
                setTableCode('');
                setSelectedStallId('');
                setSelectedTableId(null);
                setShowDeleteModal(false); 
                showNotificationMessage('Table deleted successfully!'); 
                setError('');
            } else {
                setError('Failed to delete table');
            }
        } catch (error) {
            setError('Error deleting table: ' + error.message);
        }
    };

    const handleRowClick = (table) => {
        setSelectedTableId(table.id); 
        setTableCode(table.tableId);
        setSelectedStallId(table.stallId);
    };

    const confirmDelete = () => {
        setShowDeleteModal(true);
    };

    return (
        <div style={{ padding: '1rem' }}>
            <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
                <input
                    type="text"
                    placeholder="Table code"
                    value={tableId}
                    onChange={(e) => setTableCode(e.target.value)}
                    style={{ padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px' }}
                />
                <select
                    value={selectedStallId}
                    onChange={(e) => setSelectedStallId(e.target.value)}
                    style={{ padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px', width: '15%' }}
                >
                    <option value="">All Stalls</option>
                    {stallIds.map((id) => (
                        <option key={id} value={id}>{id}</option>
                    ))}
                </select>

                <button
                onClick={handleAdd}
                style={{
                    padding: '0.5rem 0.9rem',
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    width: '40px',
                    transition: 'background-color 0.3s',
                }}
                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#45a049')}
                onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#4CAF50')}
            >
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: '0.5rem' }} />
            </button>

            <button
                onClick={handleUpdate}
                style={{
                    padding: '0.5rem 0.9rem',
                    backgroundColor: '#008CBA',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    width: '40px',
                    transition: 'background-color 0.3s',
                }}
                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#007BB5')}
                onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#008CBA')}
            >
                <FontAwesomeIcon icon={faEdit} style={{ marginRight: '0.5rem' }} /> 
            </button>

            <button
                onClick={confirmDelete}
                style={{
                    padding: '0.5rem 0.9rem',
                    backgroundColor: '#f44336',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    width: '40px',
                    transition: 'background-color 0.3s',
                }}
                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#d32f2f')}
                onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#f44336')}
            >
                <FontAwesomeIcon icon={faTrash} style={{ marginRight: '0.5rem' }} />
            </button>
            </div>

            <div style={{ height: '40px', overflow: 'hidden', marginBottom: '1rem' }}>
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>

            <table 
                style={{ 
                    width: '75%', 
                    borderCollapse: 'collapse', 
                    position: 'fixed', 
                    top: '210px', 
                    marginTop: '25px' 
                }}
            >
                <thead>
                    <tr>
                        <th style={{ border: '1px solid #ccc', padding: '0.5rem' }}>Table Code</th>
                        <th style={{ border: '1px solid #ccc', padding: '0.5rem' }}>Stall Code</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredTables.map((table) => (
                        <tr
                            key={table.id}
                            onClick={() => handleRowClick(table)}
                            style={{ cursor: 'pointer', backgroundColor: selectedTableId === table.id ? '#f0f0f0' : 'white' }}
                        >
                            <td style={{ border: '1px solid #ccc', padding: '0.5rem' }}>{table.tableId}</td>
                            <td style={{ border: '1px solid #ccc', padding: '0.5rem' }}>{table.stallId}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {showDeleteModal && (
                <div style={{
                    position: 'fixed', top: '0', left: '0', width: '100%', height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}>
                    <div style={{
                        backgroundColor: 'white', padding: '1rem', borderRadius: '8px', textAlign: 'center', width: '300px'
                    }}>
                        <p>Are you sure?</p>
                        <button
                            onClick={handleDelete}
                            style={{ marginRight: '1rem', padding: '0.5rem 1rem', backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                            Yes
                        </button>
                        <button
                            onClick={() => setShowDeleteModal(false)}
                            style={{ padding: '0.5rem 1rem', backgroundColor: '#008CBA', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                            No
                        </button>
                    </div>
                </div>
            )}

            {showNotification && (
                <NotificationModal 
                    message={message}
                    onClose={() => setShowNotification(false)} 
                />
            )}
        </div>
    );
};

export default TableManagement;
