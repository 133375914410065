import React from 'react';
import App from './../app.jsx';
import { Navigate } from 'react-router-dom';

import PrivateRoute from '../utils/PrivateRoute.js';
import Dashboard from './../pages/dashboard/dashboard.js';
import Analytics from './../pages/analytics/analytics.js';
import EmailInbox from './../pages/email/inbox.js';
import EmailCompose from './../pages/email/compose.js';
import EmailDetail from './../pages/email/detail.js';
import Widgets from './../pages/widgets/widgets.js';
import PosCustomerOrder from '../pages/pos/customerOder/customer-order.js';
import PosOutletCustomerOrder from "../pages/pos/customerOder/customer-order-outlet.js";
import PosKitchenOrder from './../pages/pos/kitchen-order.js';
import PosCounterCheckout from '../pages/pos/counterCheckout/counter-checkout.js';
import PosOutletCounterCheckout from "../pages/pos/counterCheckout/counter-checkout-outlet.js";
import TableManagement from '../pages/pos/table-management.js';
import PosOngoingOrders from "./../pages/pos/ongoing-orders.js";
import PosTableBooking from './../pages/pos/table-booking.js';
import PosMenuStock from './../pages/pos/menu-stock.js';
import CashierCheckout from './../pages/pos/cashier-view.js';
import UiBootstrap from './../pages/ui/bootstrap.js';
import UiButtons from './../pages/ui/buttons.js';
import UiCard from './../pages/ui/card.js';
import UiIcons from './../pages/ui/icons.js';
import UiModalNotifications from './../pages/ui/modal-notifications.js';
import UiTypography from './../pages/ui/typography.js';
import UiTabsAccordions from './../pages/ui/tabs-accordions.js';
import FormElements from './../pages/form/elements.js';
import FormPlugins from './../pages/form/plugins.js';
import FormWizards from './../pages/form/wizards.js';
import TableElements from './../pages/table/elements.js';
import TablePlugins from './../pages/table/plugins.js';
import ChartJs from './../pages/charts/js.js';
import ChartApex from './../pages/charts/apex.js';
import Map from './../pages/map/map.js';
import LayoutStarter from './../pages/layout/starter.js';
import LayoutFixedFooter from './../pages/layout/fixed-footer.js';
import LayoutFullHeight from './../pages/layout/full-height.js';
import LayoutFullWidth from './../pages/layout/full-width.js';
import LayoutBoxedLayout from './../pages/layout/boxed-layout.js';
import LayoutMinifiedSidebar from './../pages/layout/minified-sidebar.js';
import LayoutTopNav from './../pages/layout/top-nav.js';
import LayoutMixedNav from './../pages/layout/mixed-nav.js';
import LayoutMixedNavBoxedLayout from './../pages/layout/mixed-nav-boxed-layout.js';
import PagesScrumBoard from './../pages/pages/scrum-board.js';
import PagesProducts from './../pages/pages/products.js';
import PagesProductDetails from './../pages/pages/product-details.js';
import PagesOrders from './../pages/pages/orders.js';
import PagesOrderDetails from './../pages/pages/order-details.js';
import PagesGallery from './../pages/pages/gallery.js';
import PagesSearchResults from './../pages/pages/search-results.js';
import PagesComingSoon from './../pages/pages/coming-soon.js';
import PagesError from './../pages/pages/error.js';
import PagesLogin from './../pages/pages/login.js'
import PagesRegister from './../pages/pages/register.js';
import PagesMessenger from './../pages/pages/messenger.js';
import PagesDataManagement from './../pages/pages/data-management.js';
import PagesFileManager from './../pages/pages/file-manager.js';
import PagesPricing from './../pages/pages/pricing.js';
import Landing from './../pages/landing/landing.js';
import Profile from './../pages/profile/profile.js';
import Calendar from './../pages/calendar/calendar.js';
import Settings from './../pages/settings/settings.js';
import Helper from './../pages/helper/helper.js';
import CustomerScreen from '../pages/pos/cutomer-display/CustomerScreen.jsx';
import PosCounterCheckoutStall from  '../pages/pos/counterCheckout/counter-checkout-stall.js';
import DayEnd from '../pages/pos/day-end.js';


const AppRoute = [
	{ path: 'customer', element: <CustomerScreen /> },
  {
    path: '*',
    element: <App />,
    children: [
      { path: '', element: <Navigate to='/login' /> },
      { path: 'login', element: <PagesLogin /> },
      { 
        path: 'dashboard',
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
      },
      { 
        path: 'analytics',
        element: (
          <PrivateRoute>
            <Analytics />
          </PrivateRoute>
        ),
      },
      {
        path: 'email/*',
        children: [
          { 
            path: 'inbox',
            element: (
              <PrivateRoute>
                <EmailInbox />
              </PrivateRoute>
            ),
          },
          { 
            path: 'compose',
            element: (
              <PrivateRoute>
                <EmailCompose />
              </PrivateRoute>
            ),
          },
          { 
            path: 'detail',
            element: (
              <PrivateRoute>
                <EmailDetail />
              </PrivateRoute>
            ),
          },
        ],
      },
      { 
        path: 'widgets',
        element: (
          <PrivateRoute>
            <Widgets />
          </PrivateRoute>
        ),
      },
      {
        path: 'pos/*',
        children: [
          {
            path: 'customer-order',
            element: (
              <PrivateRoute>
                <PosCustomerOrder />
              </PrivateRoute>
            ),
          },
          {
            path: "customer-order-outlet",
            element: (
              <PrivateRoute>
                <PosOutletCustomerOrder />
              </PrivateRoute>
            ),
          },
          {
            path: 'kitchen-order',
            element: (
              <PrivateRoute>
                <PosKitchenOrder />
              </PrivateRoute>
            ),
          },
          {
            path: 'void-orders',
            element: (
              <PrivateRoute>
                <PosOngoingOrders />
              </PrivateRoute>
            ),
          },
          {
            path: 'counter-checkout',
            element: (
              <PrivateRoute>
                <PosCounterCheckout />
              </PrivateRoute>
            ),
          },
          {
            path: 'counter-checkout-outlet',
            element: (
              <PrivateRoute>
                <PosOutletCounterCheckout />
              </PrivateRoute>
            ),
          },
          { 
            path: 'counter-checkout-stall',
            element: <PosCounterCheckoutStall /> },

          {
            path: 'table-booking',
            element: (
              <PrivateRoute>
                <PosTableBooking />
              </PrivateRoute>
            ),
          },
          {
            path: 'menu-stock',
            element: (
              <PrivateRoute>
                <PosMenuStock />
              </PrivateRoute>
            ),
          },
          {
            path: 'cashier-checkout',
            element: (
              <PrivateRoute>
                <CashierCheckout />
              </PrivateRoute>
            ),
          },
          { 
            path: 'table-management', 
            element: (
              <PrivateRoute>
                <TableManagement />
              </PrivateRoute>
            ), 
          },
          { 
            path: 'day-end', 
            element: (
              <PrivateRoute>
                <DayEnd />
              </PrivateRoute>
            ), 
          },
        ],
      },
      {
        path: 'ui/*',
        children: [
          {
            path: 'bootstrap',
            element: (
              <PrivateRoute>
                <UiBootstrap />
              </PrivateRoute>
            ),
          },
          {
            path: 'buttons',
            element: (
              <PrivateRoute>
                <UiButtons />
              </PrivateRoute>
            ),
          },
          {
            path: 'card',
            element: (
              <PrivateRoute>
                <UiCard />
              </PrivateRoute>
            ),
          },
          {
            path: 'icons',
            element: (
              <PrivateRoute>
                <UiIcons />
              </PrivateRoute>
            ),
          },
          {
            path: 'modal-notifications',
            element: (
              <PrivateRoute>
                <UiModalNotifications />
              </PrivateRoute>
            ),
          },
          {
            path: 'typography',
            element: (
              <PrivateRoute>
                <UiTypography />
              </PrivateRoute>
            ),
          },
          {
            path: 'tabs-accordions',
            element: (
              <PrivateRoute>
                <UiTabsAccordions />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: 'form/*',
        children: [
          {
            path: 'elements',
            element: (
              <PrivateRoute>
                <FormElements />
              </PrivateRoute>
            ),
          },
          {
            path: 'plugins',
            element: (
              <PrivateRoute>
                <FormPlugins />
              </PrivateRoute>
            ),
          },
          {
            path: 'wizards',
            element: (
              <PrivateRoute>
                <FormWizards />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: 'table/*',
        children: [
          {
            path: 'elements',
            element: (
              <PrivateRoute>
                <TableElements />
              </PrivateRoute>
            ),
          },
          {
            path: 'plugins',
            element: (
              <PrivateRoute>
                <TablePlugins />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: 'chart/*',
        children: [
          {
            path: 'chart-js',
            element: (
              <PrivateRoute>
                <ChartJs />
              </PrivateRoute>
            ),
          },
          {
            path: 'chart-apex',
            element: (
              <PrivateRoute>
                <ChartApex />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: 'layout/*',
        children: [
          {
            path: 'starter-page',
            element: (
              <PrivateRoute>
                <LayoutStarter />
              </PrivateRoute>
            ),
          },
          {
            path: 'fixed-footer',
            element: (
              <PrivateRoute>
                <LayoutFixedFooter />
              </PrivateRoute>
            ),
          },
          {
            path: 'full-height',
            element: (
              <PrivateRoute>
                <LayoutFullHeight />
              </PrivateRoute>
            ),
          },
          {
            path: 'full-width',
            element: (
              <PrivateRoute>
                <LayoutFullWidth />
              </PrivateRoute>
            ),
          },
          {
            path: 'boxed-layout',
            element: (
              <PrivateRoute>
                <LayoutBoxedLayout />
              </PrivateRoute>
            ),
          },
          {
            path: 'minified-sidebar',
            element: (
              <PrivateRoute>
                <LayoutMinifiedSidebar />
              </PrivateRoute>
            ),
          },
          {
            path: 'top-nav',
            element: (
              <PrivateRoute>
                <LayoutTopNav />
              </PrivateRoute>
            ),
          },
          {
            path: 'mixed-nav',
            element: (
              <PrivateRoute>
                <LayoutMixedNav />
              </PrivateRoute>
            ),
          },
          {
            path: 'mixed-nav-boxed-layout',
            element: (
              <PrivateRoute>
                <LayoutMixedNavBoxedLayout />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: 'pages/*',
        children: [
          {
            path: 'scrum-board',
            element: (
              <PrivateRoute>
                <PagesScrumBoard />
              </PrivateRoute>
            ),
          },
          {
            path: 'products',
            element: (
              <PrivateRoute>
                <PagesProducts />
              </PrivateRoute>
            ),
          },
          {
            path: 'product-details',
            element: (
              <PrivateRoute>
                <PagesProductDetails />
              </PrivateRoute>
            ),
          },
          {
            path: 'orders',
            element: (
              <PrivateRoute>
                <PagesOrders />
              </PrivateRoute>
            ),
          },
          {
            path: 'order-details',
            element: (
              <PrivateRoute>
                <PagesOrderDetails />
              </PrivateRoute>
            ),
          },
          {
            path: 'gallery',
            element: (
              <PrivateRoute>
                <PagesGallery />
              </PrivateRoute>
            ),
          },
          {
            path: 'search-results',
            element: (
              <PrivateRoute>
                <PagesSearchResults />
              </PrivateRoute>
            ),
          },
          { 
            path: 'error', 
            element: (
              <PrivateRoute>
                <PagesError />
              </PrivateRoute>
            ), 
          },
          { 
            path: 'register', 
            element: (
              <PrivateRoute>
                <PagesRegister />
              </PrivateRoute>
            ), 
          },
          { 
            path: 'messenger', 
            element: (
              <PrivateRoute>
                <PagesMessenger />
              </PrivateRoute>
            ), 
          },
          { 
            path: 'data-management', 
            element: (
              <PrivateRoute>
                <PagesDataManagement />
              </PrivateRoute>
            ), 
          },
          { 
            path: 'file-manager', 
            element: (
              <PrivateRoute>
                <PagesFileManager />
              </PrivateRoute>
            ), 
          },
          { 
            path: 'pricing', 
            element: (
              <PrivateRoute>
                <PagesPricing />
              </PrivateRoute>
            ), 
          },
        ],
      },
      { path: '*', element: <PagesError /> },
    ],
  },
];


export default AppRoute;