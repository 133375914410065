import React, { useState, useEffect } from "react";
import dishesServices from "../../../api/DishesServices/disheseService";

const OpenItemModal = ({ isOpen, onClose, onSubmit }) => {
  const initialState = {
    foodItemName: "",
    price: "",
    quantity: 1,
    managerUsername: "",
    managerPassword: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ type: "", message: "" });
  const [showManagerValidation, setShowManagerValidation] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setFormData(initialState);
      setErrors({});
      setSubmitStatus({ type: "", message: "" });
      setShowManagerValidation(false);
    }
  }, [isOpen]);

  const validateItemDetails = () => {
    const newErrors = {};
    
    if (!formData.foodItemName.trim()) {
      newErrors.foodItemName = "Food item name is required";
    } else if (formData.foodItemName.length < 2) {
      newErrors.foodItemName = "Food item name must be at least 2 characters";
    }

    if (!formData.price) {
      newErrors.price = "Price is required";
    } else if (parseFloat(formData.price) <= 0) {
      newErrors.price = "Price must be greater than 0";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleItemDetailsSubmit = (e) => {
    e.preventDefault();
    if (validateItemDetails()) {
      setShowManagerValidation(true);
    }
  };

  const handleManagerValidation = async (e) => {
    e.preventDefault();
    setSubmitStatus({ type: "", message: "" });

    if (!formData.managerUsername.trim() || !formData.managerPassword) {
      setErrors({
        ...errors,
        managerUsername: !formData.managerUsername.trim() ? "Manager username is required" : "",
        managerPassword: !formData.managerPassword ? "Manager password is required" : ""
      });
      return;
    }

    setIsSubmitting(true);
    try {
      const validationResult = await dishesServices().validManager(
        formData.managerUsername,
        formData.managerPassword
      );

      if (!validationResult.success) {
        setSubmitStatus({
          type: "error",
          message: "Invalid username or password"
        });
        return;
      }

      onSubmit({
        foodItemName: formData.foodItemName,
        price: parseFloat(formData.price),
        quantity: formData.quantity,
      });
      
      setSubmitStatus({
        type: "success",
        message: "Item added successfully!"
      });

      onClose();

    } catch (error) {
      setSubmitStatus({
        type: "error",
        message: "An error occurred. Please try again."
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h3>Add Open Item</h3>
        {!showManagerValidation ? (
          <form onSubmit={handleItemDetailsSubmit}>
            <div className="form-group">
              <label htmlFor="foodItemName">Menu Item Name:</label>
              <input
                id="foodItemName"
                name="foodItemName"
                type="text"
                value={formData.foodItemName}
                onChange={handleInputChange}
                className={errors.foodItemName ? "error" : ""}
              />
              {errors.foodItemName && (
                <span className="error-message">{errors.foodItemName}</span>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="price">Price:</label>
              <input
                id="price"
                name="price"
                type="number"
                step="0.01"
                value={formData.price}
                onChange={handleInputChange}
                className={errors.price ? "error" : ""}
              />
              {errors.price && (
                <span className="error-message">{errors.price}</span>
              )}
            </div>

            <div className="button-group">
              <button type="submit" className="submit-button">
                Add Item
              </button>
              <button type="button" className="cancel-button" onClick={onClose}>
                Cancel
              </button>
            </div>
          </form>
        ) : (
          <form onSubmit={handleManagerValidation}>
            <div className="form-group">
              <label htmlFor="managerUsername">Manager Username:</label>
              <input
                id="managerUsername"
                name="managerUsername"
                type="text"
                value={formData.managerUsername}
                onChange={handleInputChange}
                className={errors.managerUsername ? "error" : ""}
                disabled={isSubmitting}
              />
              {errors.managerUsername && (
                <span className="error-message">{errors.managerUsername}</span>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="managerPassword">Manager Password:</label>
              <input
                id="managerPassword"
                name="managerPassword"
                type="password"
                value={formData.managerPassword}
                onChange={handleInputChange}
                className={errors.managerPassword ? "error" : ""}
                disabled={isSubmitting}
              />
              {errors.managerPassword && (
                <span className="error-message">{errors.managerPassword}</span>
              )}
            </div>

            {submitStatus.message && (
              <div className={`status-message ${submitStatus.type}`}>
                {submitStatus.message}
              </div>
            )}

            <div className="button-group">
              <button
                type="button"
                className="cancel-button"
                onClick={() => setShowManagerValidation(false)}
                disabled={isSubmitting}
              >
                Back
              </button>
              <button
                type="submit"
                className="submit-button"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        )}
      </div>

      <style jsx>{`
        .modal-backdrop {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999;
        }

        .modal-content {
          background: white;
          padding: 30px;
          border-radius: 12px;
          width: 450px;
          max-width: 90%;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
        }

        .form-group {
          margin-bottom: 20px;
        }

        label {
          display: block;
          margin-bottom: 8px;
          font-weight: 500;
          color: #333;
        }

        input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 6px;
          transition: border-color 0.3s;
        }

        input:focus {
          outline: none;
          border-color: #7b7b7b;
          box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
        }

        input.error {
          border-color: #dc3545;
        }

        .error-message {
          color: #dc3545;
          font-size: 0.875rem;
          margin-top: 4px;
          display: block;
        }

        .status-message {
          padding: 10px;
          border-radius: 6px;
          margin-bottom: 15px;
          text-align: center;
        }

        .status-message.error {
          background-color: #ffeaea;
          color: #dc3545;
        }

        .status-message.success {
          background-color: #e8f5e9;
          color: #28a745;
        }

        .button-group {
          display: flex;
          gap: 10px;
          justify-content: flex-end;
          margin-top: 20px;
        }

        button {
          padding: 10px 20px;
          border-radius: 6px;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.3s;
        }

        .submit-button {
          background-color: #F57C00;
          color: white;
          border: none;
        }

        .submit-button:hover:not(:disabled) {
          background-color: #dd6800;
        }

        .cancel-button {
          background-color: #f8f9fa;
          border: 1px solid #ddd;
        }

        .cancel-button:hover:not(:disabled) {
          background-color: #e9ecef;
        }

        button:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      `}</style>
    </div>
  );
};

export default OpenItemModal;