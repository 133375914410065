import { disableCache } from "@iconify/react/dist/iconify.js";

const userRole = localStorage.getItem('roleName');

const checkRoleAccess = (roles) => {
  if (!roles || roles.length === 0) {
    return true;
  }
  return roles.includes(userRole);
};

// Filter the menu based on roles
const Menu = [
	{ is_header: true, title: 'Navigation' },
	{ path: '/dashboard', icon: 'fa fa-laptop', title: 'Dashboard' },
	{ path: '/analytics', icon: 'fa fa-chart-pie', title: 'Analytics' },
	{ path: '/email', icon: 'fa fa-envelope', title: 'Email', badge: '4',
		children: [
			{ path: '/email/inbox', title: 'Inbox' }, 
			{ path: '/email/compose', title: 'Compose' }, 
			{ path: '/email/detail', title: 'Detail' }
		]
	}, 
	{ is_divider: true }, 
	{ is_header: true, title: 'Components' }, 
	{ path: '/pos', icon: 'fa fa-wallet', title: 'POS System', roles: ['Admin', 'Manager', 'Cashier', 'Chef', 'Stall7_Cashier', 'Outlet_Cashier', 'Outlet_Manager'],
		children: [
            { path: '/pos/customer-order', title: 'Customer Order', roles: ['Admin', 'Manager', 'Chef', 'Outlet_Cashier','Cashier','Outlet_Manager'] }, 
            { path: '/pos/kitchen-order', title: 'Kitchen Order', roles: ['Admin', 'Manager', 'Chef','Cashier'] }, 
						{ path: '/pos/void-orders', title: 'Void Orders' }, 
            { path: '/pos/counter-checkout', title: 'Counter Checkout', roles: ['Admin', 'Manager', 'Cashier'] }, 
			{ path: '/pos/counter-checkout-stall', title: 'Counter Checkout', roles: ['Admin', 'Manager', 'Cashier'] }, 
            // { path: '/pos/table-booking', title: 'Table Booking', roles: ['Manager', 'Chef', 'Outlet_Cashier'] }, 
						{ path: '/pos/cashier-view', title: 'Cashire View', roles: ['Admin', 'Manager', 'Cashier','Outlet_Cashier','Outlet_Manager'] }, 
            { path: '/pos/menu-stock', title: 'Menu Stock', roles: ['Admin', 'Manager', 'Chef','Cashier','Outlet_Cashier'] },

						{ path: '/pos/table-management', title: 'Table Management', roles: ['Outlet_Manager'] }

		]
	},
	{ is_divider: true }, 
	{ is_header: true, title: 'Users' }, 
	{ path: '/profile', icon: 'fa fa-user-circle', title: 'Profile' }, 
	{ path: '/calendar', icon: 'fa fa-calendar', title: 'Calendar' }, 
	{ path: '/settings', icon: 'fa fa-cog', title: 'Settings' }, 
	{ path: '/helper', icon: 'fa fa-question-circle',	title: 'Helper' }
];

export default Menu;