import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');

  // Check if the token exists and is not expired
  if (!token) {
    return <Navigate to="/login" />;
  }

  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    // Check if the token is expired
    if (decoded.exp < currentTime) {
      localStorage.clear(); // clear expired token
      return <Navigate to="/login" />;
    }
    
    return children;
  } catch (error) {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;
