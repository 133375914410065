import React, { useState } from 'react';

export const AppSettings = React.createContext();

export const AppProvider = ({ children }) => {
    const [roleName, setRoleName] = useState(null);
    const [stallId, setStallId] = useState(null);
  
    return (
      <AppSettings.Provider value={{ roleName, setRoleName, stallId, setStallId }}>
        {children}
      </AppSettings.Provider>
    );
  };