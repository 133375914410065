const RoleRedirects = Object.freeze({
    Chef: '/pos/customer-order',
    Admin: '/dashboard',
    Cashier: '/pos/counter-checkout',
    Outlet_Manager: '/dashboard',
    Outlet_Cashier: '/pos/counter-checkout-outlet',
    Manager: '/pos/day-end',
    Accountant: '/pos/cashier-checkout',
    Default: '/dashboard',
});

export default RoleRedirects;