import TableManagement from "../pages/pos/table-management";

const RolePermissions = Object.freeze({
    //CustomerOrder: ['Chef', 'Outlet_Cashier', 'Outlet_Manager'],
    CustomerOrder: ['Chef'],
    OutletCustomerOrder: ['Outlet_Cashier', 'Outlet_Manager'],
    //KitchenOrder: ['Chef', 'Outlet_Cashier', 'Outlet_Manager'],
    KitchenOrder: ['Chef'],
    //VoidOrder: ['Chef', 'Outlet_Manager'],
    VoidOrder: ['Chef'],
    CashierView: ['Accountant'],
    //CounterCheckout: ['Cashier', 'Outlet_Cashier', 'Outlet_Manager'],
    CounterCheckout: ['Cashier'],
    OutletCounterCheckout: ['Outlet_Cashier', 'Outlet_Manager'],
    TableManagement: ['Outlet_Manager'],
    DayEnd: ['Manager', 'Outlet_Manager'],
    ReFresh:['Cashier','Chef', 'Outlet_Manager','Manager','Outlet_Cashier']
});

export default RolePermissions;