import React from "react";
import {API_CONFIGURATIONS} from "../constants/apiConfigurations";
import axios from "axios";


const acumaticaServices = () => {
    const acumaticaUserRoleIntegration = async () => {
        try {
            const response = await axios.post(API_CONFIGURATIONS.SYNC_ACUMATICA_USER_ROLE_INTERGRATION, {},
                {
                headers: { "Content-Type": "application/json" },
            });
        } catch (error) {
            console.error("Error syncing user roles:", error.response?.data || error.message);
        }
    };

    const acumaticaTaxList = async () => {
        try {
            const response = await axios.post(API_CONFIGURATIONS.SYNC_ACUMATICA_TAX_LIST, {},
                {
                headers: { "Content-Type": "application/json" },
            });
        } catch (error) {
            console.error("Error syncing tax list:", error.response?.data || error.message);
        }
    };

    const acumaticaStallMenuItemIntegration = async () => {
        try {
            const response = await axios.post(API_CONFIGURATIONS.SYNC_ACUMATICA_STALL_MENUITEM_INTERGRATION, {},
                {
                headers: { "Content-Type": "application/json" },
            });
        } catch (error) {
            console.error("Error syncing menu items:", error.response?.data || error.message);
        }
    };

    const acumaticaDiscountIntegration = async () => {
        try {
            const response = await axios.post(API_CONFIGURATIONS.SYNC_ACUMATICA_DISCOUNT_INTERGRATION, {},
                {
                headers: { "Content-Type": "application/json" },
            });
        } catch (error) {
            console.error("Error syncing discounts:", error.response?.data || error.message);
        }
    };

    const acumaticaUserStallIntegration = async () => {
        try {
            const response = await axios.post(API_CONFIGURATIONS.SYNC_ACUMATICA_USER_STALLS_INTERGRATION, {},
                {
                headers: { "Content-Type": "application/json" },
            });
        } catch (error) {
            console.error("Error syncing User Role Stalls:", error.response?.data || error.message);
        }
    };

    const acumaticaVoidReasonIntegration = async () => {
        try {
            const response = await axios.post(API_CONFIGURATIONS.SYNC_ACUMATICA_VOID_REASONS_INTEGRATION, {},
                {
                headers: { "Content-Type": "application/json" },
            });
        } catch (error) {
            console.error("Error syncing Void Reasons:", error.response?.data || error.message);
        }
    };

    return {
        acumaticaUserRoleIntegration,
        acumaticaTaxList,
        acumaticaStallMenuItemIntegration,
        acumaticaDiscountIntegration,
        acumaticaUserStallIntegration,
        acumaticaVoidReasonIntegration
    };
}

export default acumaticaServices;
 