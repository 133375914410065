
import React, { useEffect } from 'react';

const NotificationModal = ({ message, onClose }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 3000); 

        return () => clearTimeout(timer); 
    }, [onClose]);

    return (
        <div style={{
            position: 'fixed',
            top: '115px', 
            right: '5px', 
            width: 'auto',
            maxWidth: '300px', 
            backgroundColor: 'rgba(255, 255, 0, 0.7)', 
            border: '1px solid #ccc',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', 
            zIndex: 1000, 
            padding: '1rem',
            textAlign: 'center',
        }}>
            <p style={{ margin: 0 }}>{message}</p> 
        </div>
    );
};

export default NotificationModal;
