import React, { useEffect, useContext, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';

function FormLabel({ children, required }) {
	return (
	  <label className="form-label">
		{children}
		{required && <span className="text-danger ms-1" aria-label="required">*</span>}
	  </label>
	);
  }

function PagesRegister() {
  const context = useContext(AppSettings);
  const [redirect, setRedirect] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    country: 'United States',
    gender: 'Female',
    birthMonth: '',
    birthDay: '',
    birthYear: '',
    termsAgreed: false,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);
    context.setAppContentClass('p-0');
    context.setAppContentFullHeight(true);

    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass('');
      context.setAppContentFullHeight(false);
    };

    // eslint-disable-next-line
  }, []);

  function validateForm() {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!formData.password) newErrors.password = 'Password is required';
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }
    if (!formData.termsAgreed) newErrors.termsAgreed = 'You must agree to the terms';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (validateForm()) {
      setRedirect(true);
    }
  }

  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  }

  if (redirect) {
    return <Navigate to='/' />;
  }

  return (
    <div className="register">
      <div className="register-content">
        <form onSubmit={handleSubmit}>
          <h1 className="text-center">Sign Up</h1>
          <p className="text-muted text-center">One Admin ID is all you need to access all the Admin services.</p>
          
          <div className="mb-3">
		  <FormLabel required>Name</FormLabel>
            <input
              type="text"
              className={`form-control form-control-lg fs-15px ${errors.name && 'is-invalid'}`}
              placeholder="e.g John Smith"
              name="name"
              value={formData.name}
              onChange={handleChange}
			  aria-required="true"
            />
            {errors.name && <div className="invalid-feedback">{errors.name}</div>}
          </div>

          <div className="mb-3">
		  <FormLabel required>Email Address</FormLabel>
            <input
              type="text"
              className={`form-control form-control-lg fs-15px ${errors.email && 'is-invalid'}`}
              placeholder="username@address.com"
              name="email"
              value={formData.email}
              onChange={handleChange}
			  aria-required="true"
            />
            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
          </div>

          <div className="mb-3">
		  <FormLabel required>Password</FormLabel>
            <input
              type="password"
              className={`form-control form-control-lg fs-15px ${errors.password && 'is-invalid'}`}
              name="password"
              value={formData.password}
              onChange={handleChange}
			  aria-required="true"
            />
            {errors.password && <div className="invalid-feedback">{errors.password}</div>}
          </div>

          <div className="mb-3">
		  <FormLabel required>confirm Password</FormLabel>
            <input
              type="password"
              className={`form-control form-control-lg fs-15px ${errors.confirmPassword && 'is-invalid'}`}
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
			  aria-required="true"
            />
            {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
          </div>

          <div className="mb-3">
		  <FormLabel required>Country</FormLabel>
            <select
              className="form-control form-control-lg fs-15px"
              name="country"
              value={formData.country}
              onChange={handleChange}
			  aria-required="true"
            >
              <option>United States</option>
            </select>
          </div>

          <div className="mb-3">
		  <FormLabel required>Gender</FormLabel>
            <select
              className="form-control form-control-lg fs-15px"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
			  aria-required="true"
            >
              <option>Female</option>
            </select>
          </div>

          <div className="mb-3">
		  <FormLabel required>Date of Birth</FormLabel>
            <div className="row">
              <div className="col-6">
                <select
                  className="form-select form-select-lg fs-15px"
                  name="birthMonth"
                  value={formData.birthMonth}
                  onChange={handleChange}
				  aria-required="true"
                >
                  <option>Month</option>
                </select>
              </div>
              <div className="col-3">
                <select
                  className="form-select form-select-lg fs-15px"
                  name="birthDay"
                  value={formData.birthDay}
                  onChange={handleChange}
                >
                  <option>Day</option>
                </select>
              </div>
              <div className="col-3">
                <select
                  className="form-select form-select-lg fs-15px"
                  name="birthYear"
                  value={formData.birthYear}
                  onChange={handleChange}
                >
                  <option>Year</option>
                </select>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <div className="form-check">
              <input
                className={`form-check-input ${errors.termsAgreed && 'is-invalid'}`}
                type="checkbox"
                name="termsAgreed"
                checked={formData.termsAgreed}
                onChange={handleChange}
                id="customCheck1"
              />
              <label className="form-check-label fw-500" htmlFor="customCheck1">
                I have read and agree to the <a href="#/">Terms of Use</a> and <a href="#/">Privacy Policy</a>.
              </label>
              {errors.termsAgreed && <div className="invalid-feedback">{errors.termsAgreed}</div>}
            </div>
          </div>
          <div className="mb-3">
            <button type="submit" className="btn btn-theme btn-lg fs-15px fw-500 d-block w-100">Sign Up</button>
          </div>
          <div className="text-muted text-center">
            Already have an Admin ID? <Link to="/login">Sign In</Link>
          </div>
        </form>
      </div>
    </div>
  )
}

export default PagesRegister;