import React from "react";
import {API_CONFIGURATIONS} from "../constants/apiConfigurations";
import axios from "axios";


const ExecutiveListAcumaticaServices = () => {
    const syncExecutiveList = async () => {
        try {
            const response = await axios.post(API_CONFIGURATIONS.SYNC_ACUMATICA_EXECUTIVE_LIST_INTEGRATION, {},
                {
                headers: { "Content-Type": "application/json" },
            });
        } catch (error) {
            console.error("Error syncing executive list:", error.response?.data || error.message);
        }
    };

    return {
        syncExecutiveList
    };
}

export default ExecutiveListAcumaticaServices;