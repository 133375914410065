import React from "react";
import {API_CONFIGURATIONS} from "../constants/apiConfigurations";
import axios from "axios";

const tableServices = () => {
    
  const getStalls = async () => {
    const response = await axios
        .get(API_CONFIGURATIONS.GET_STALLS)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            throw error.response;
        });
    return response;
    };

  return {
    getStalls,
  };

}
export default tableServices;