import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardImgOverlay } from './../../components/card/card.jsx';
import Chart from 'react-apexcharts';

function Dashboard() {
	const [chartOptions, setChartOptions] = useState(getChartOptions());
	const [userRole, setUserRole] = useState(null);

	useEffect(() => {
	  // Retrieve the user role from localStorage
	  const role = localStorage.getItem('roleName');
	  setUserRole(role);
  
	  setChartOptionsState();
	  document.addEventListener('theme-reload', () => {
		setChartOptionsState();
	  });
  
	}, []);

	var chartData = [{
		data: [
			8107, 8128, 8122, 8165, 8340, 8423, 8423, 8514, 8481, 8487, 
			8506, 8626, 8668, 8602, 8607, 8512, 8496, 8600, 8881, 9340
		]
	}];
	
	function getChartOptions() {
		var primary = (getComputedStyle(document.body).getPropertyValue('--bs-primary')).trim();
		var indigo = (getComputedStyle(document.body).getPropertyValue('--bs-indigo')).trim();
		var orange = (getComputedStyle(document.body).getPropertyValue('--bs-orange')).trim();
		var teal = (getComputedStyle(document.body).getPropertyValue('--bs-teal')).trim();
		var yellow = (getComputedStyle(document.body).getPropertyValue('--bs-yellow')).trim();
		var pink = (getComputedStyle(document.body).getPropertyValue('--bs-pink')).trim();
		var white = (getComputedStyle(document.body).getPropertyValue('--bs-white')).trim();
		var gray900 = (getComputedStyle(document.body).getPropertyValue('--bs-gray-900')).trim();
		var bodyColor = (getComputedStyle(document.body).getPropertyValue('--bs-body-color')).trim();
		var bodyColorRgb = (getComputedStyle(document.body).getPropertyValue('--bs-body-color-rgb')).trim();
		var bodyFontFamily = (getComputedStyle(document.body).getPropertyValue('--bs-body-font-family')).trim();
		var bodyFontSize = '13px';
	
		return {
			colors: [primary],
			labels: [
				'13 Nov 2024', '14 Nov 2024', '15 Nov 2024', '16 Nov 2024',
				'17 Nov 2024', '20 Nov 2024', '21 Nov 2024', '22 Nov 2024',
				'23 Nov 2024', '24 Nov 2024', '27 Nov 2024', '28 Nov 2024',
				'29 Nov 2024', '30 Nov 2024', '01 Dec 2024', '04 Dec 2024', 
				'05 Dec 2024', '06 Dec 2024', '07 Dec 2024', '08 Dec 2024'
			],
			title: { style: { bodyColor } },
			legend: { labels: { bodyColor } },
			chart: { height: 256, type: 'line', toolbar: { show: false } },
			annotations: {
				yaxis: [
					{ y: 8200, borderColor: indigo, label: { borderColor: indigo, style: { color: white, background: indigo }, text: 'Support' } },
					{ y: 8600, y2: 9000, borderColor: orange, fillColor: orange, opacity: 0.1, label: { borderColor: yellow, style: { fontSize: '10px', color: gray900, background: yellow }, text: 'Earning' } }
				],
				xaxis: [
					{ x: new Date('23 Nov 2024').getTime(), strokeDashArray: 0, borderColor: gray900, label: { borderColor: gray900, style: { color: white, background: gray900, },  text: 'Anno Test' } }, 
					{ x: new Date('26 Nov 2024').getTime(), x2: new Date('28 Nov 2024').getTime(), fillColor: teal, opacity: 0.4, label: { borderColor: teal, style: { fontSize: '10px', color: '#fff', background: teal }, offsetY: -7, text: 'X-axis range' } }
				],
				points: [{ 
					x: new Date('01 Dec 2024').getTime(), 
					y: 8607.55, 
					marker: { size: 8, fillColor: white, strokeColor: pink, radius: 2 }, 
					label: { borderColor: pink, offsetY: 0, style: { color: white, background: pink, }, text: 'Point Annotation' }
				}]
			},
			dataLabels: { enabled: false },
			stroke: { curve: 'straight' },
			grid: { padding: { right: 30, left: 20 }, borderColor: 'rgba('+ bodyColorRgb + ', .15)' },
			xaxis: {
				type: 'datetime',
				axisBorder: { show: true, color: 'rgba('+ bodyColorRgb + ', .25)', height: 1, width: '100%', offsetX: 0, offsetY: -1 },
				axisTicks: { show: true, borderType: 'solid', color: 'rgba('+ bodyColorRgb + ', .25)', height: 6, offsetX: 0, offsetY: 0 },
				labels: { style: { colors: bodyColor, fontSize: bodyFontSize, fontFamily: bodyFontFamily, fontWeight: 400, cssClass: 'apexcharts-xaxis-label' } }
			},
			yaxis: {
				labels: { style: { colors: bodyColor, fontSize: bodyFontSize, fontFamily: bodyFontFamily, fontWeight: 400, cssClass: 'apexcharts-xaxis-label' } }
			}
		 };
	}
  
  function setChartOptionsState() {
  	setChartOptions(getChartOptions());
  };

  // Function to display role-based greeting
  const getGreetingMessage = (role) => {
    switch (role) {
      case 'Manager':
        return "Hi, Welcome to the Manager Dashboard";
      case 'Chef':
        return "Hi, Welcome to the Chef Dashboard";
      case 'Cashier':
        return "Hi, Welcome to the Cashier Dashboard";
      default:
        return "Hi, Welcome to the Dashboard";
    }
  };
  
  useEffect(() => {
		setChartOptionsState();
		
		document.addEventListener('theme-reload', () => {
			setChartOptionsState();
		});
  }, []);
	
	return (
		<div>
			<h1 className="page-header mb-3">
				{getGreetingMessage(userRole)}
			</h1>
			
			<div className="row">
				<div className="col-xl-6 mb-3">
					<Card className="h-100 overflow-hidden">
						<CardImgOverlay className="d-block d-lg-none bg-blue rounded" />
						
						<CardImgOverlay className="d-none d-md-block bg-blue rounded mb-n1 mx-n1" style={{ backgroundImage: 'url(/assets/img/bg/wave-bg.png)', backgroundPosition: 'right bottom', backgroundRepeat: 'no-repeat', backgroundSize: '100%' }}></CardImgOverlay>
						
						<CardImgOverlay className="d-none d-md-block bottom-0 top-auto">
							<div className="row">
								<div className="col-md-8 col-xl-6"></div>
								<div className="col-md-4 col-xl-6 mb-n2">
									<img src="/assets/img/page/dashboard.svg" alt="" className="d-block ms-n3 mb-5" style={{ maxHeight: '310px' }} />
								</div>
							</div>
						</CardImgOverlay>
						
						<CardBody className="position-relative text-white text-opacity-70">
							<div className="row">
								<div className="col-md-8">
									<div className="d-flex">
										<div className="me-auto">
											<h5 className="text-white text-opacity-80 mb-3">Weekly Earning</h5>
											<h3 className="text-white mt-n1 mb-1">$2,999.80</h3>
											<p className="mb-1 text-white text-opacity-60 text-truncate">
												<i className="fa fa-caret-up"></i> <b>32%</b> increase compare to last week
											</p>
										</div>
									</div>
									
									<hr className="bg-white bg-opacity-75 mt-3 mb-3" />
									
									<div className="row">
										<div className="col-6 col-lg-5">
											<div className="mt-1">
												<i className="fa fa-fw fa-shopping-bag fs-28px text-black text-opacity-50"></i>
											</div>
											<div className="mt-1">
												<div>Store Sales</div>
												<div className="fw-600 text-white">$1,629.80</div>
											</div>
										</div>
										<div className="col-6 col-lg-5">
											<div className="mt-1">
												<i className="fa fa-fw fa-retweet fs-28px text-black text-opacity-50"></i>
											</div>
											<div className="mt-1">
												<div>Referral Sales</div>
												<div className="fw-600 text-white">$700.00</div>
											</div>
										</div>
									</div>
									
									<hr className="bg-white bg-opacity-75 mt-3 mb-3" />
									
									<div className="mt-3 mb-2">
										<a href="#/" className="btn btn-yellow btn-rounded btn-sm ps-5 pe-5 pt-2 pb-2 fs-14px fw-600"><i className="fa fa-wallet me-2 ms-n2"></i> Withdraw money</a>
									</div>
									<p className="fs-12px">
										It usually takes 3-5 business days for transferring the earning to your bank account.
									</p>
								</div>
								
								<div className="col-md-4 d-none d-md-block" style={{ minHeight: '380px' }}></div>
							</div>
						</CardBody>
					</Card>
				</div>
				
				<div className="col-xl-6">
					<div className="row">
						<div className="col-sm-6">
							<Card className="card mb-3 overflow-hidden fs-13px border-0 bg-gradient-custom-orange" style={{ minHeight: '199px' }}>
								<CardImgOverlay className="mb-n4 me-n4 d-flex" style={{ bottom: 0, top: 'auto' }}>
									<img src="/assets/img/icon/order.svg" alt="" className="ms-auto d-block mb-n3" style={{ maxHeight: '105px' }} />
								</CardImgOverlay>
								
								<CardBody className="position-relative">
									<h5 className="text-white text-opacity-80 mb-3 fs-16px">New Orders</h5>
									<h3 className="text-white mt-n1">56</h3>
									<div className="progress bg-black bg-opacity-50 mb-2" style={{ height: '6px' }}>
										<div className="progrss-bar progress-bar-striped bg-white" style={{ width: '80%' }}></div>
									</div>
									<div className="text-white text-opacity-80 mb-4"><i className="fa fa-caret-up"></i> 16% increase <br />compare to last week</div>
									<div><a href="#/" className="text-white d-flex align-items-center text-decoration-none">View report <i className="fa fa-chevron-right ms-2 text-white text-opacity-50"></i></a></div>
								</CardBody>
							</Card>
							
							<Card className="mb-3 overflow-hidden fs-13px border-0 bg-gradient-custom-teal" style={{ minHeight: '199px' }}>
								<CardImgOverlay className="mb-n4 me-n4 d-flex" style={{ bottom: 0, top: 'auto' }}>
									<img src="/assets/img/icon/visitor.svg" alt="" className="ms-auto d-block mb-n3" style={{ maxHeight: '105px' }} />
								</CardImgOverlay>
								
								<CardBody className="position-relative">
									<h5 className="text-white text-opacity-80 mb-3 fs-16px">Page Visitors</h5>
									<h3 className="text-white mt-n1">60.5k</h3>
									<div className="progress bg-black bg-opacity-50 mb-2" style={{ height: '6px' }}>
										<div className="progrss-bar progress-bar-striped bg-white" style={{ width: '50%' }}></div>
									</div>
									<div className="text-white text-opacity-80 mb-4"><i className="fa fa-caret-up"></i> 33% increase <br />compare to last week</div>
									<div><a href="#/" className="text-white d-flex align-items-center text-decoration-none">View report <i className="fa fa-chevron-right ms-2 text-white text-opacity-50"></i></a></div>
								</CardBody>
							</Card>
						</div>
						
						<div className="col-sm-6">
							<Card className="mb-3 overflow-hidden fs-13px border-0 bg-gradient-custom-pink" style={{ minHeight: '199px' }}>
								<CardImgOverlay className="mb-n4 me-n4 d-flex" style={{ bottom: 0, top: 'auto' }}>
									<img src="/assets/img/icon/email.svg" alt="" className="ms-auto d-block mb-n3" style={{ maxHeight: '105px' }} />
								</CardImgOverlay>
								
								<CardBody className="position-relative">
									<h5 className="text-white text-opacity-80 mb-3 fs-16px">Unread email</h5>
									<h3 className="text-white mt-n1">30</h3>
									<div className="progress bg-black bg-opacity-50 mb-2" style={{ height: '6px' }}>
										<div className="progrss-bar progress-bar-striped bg-white" style={{ width: '80%' }}></div>
									</div>
									<div className="text-white text-opacity-80 mb-4"><i className="fa fa-caret-down"></i> 5% decrease <br />compare to last week</div>
									<div><a href="#/" className="text-white d-flex align-items-center text-decoration-none">View report <i className="fa fa-chevron-right ms-2 text-white text-opacity-50"></i></a></div>
								</CardBody>
							</Card>
							
							<Card className="mb-3 overflow-hidden fs-13px border-0 bg-gradient-custom-indigo" style={{ minHeight: '199px' }}>
								<CardImgOverlay className="mb-n4 me-n4 d-flex" style={{ bottom: 0, top: 'auto' }}>
									<img src="/assets/img/icon/browser.svg" alt="" className="ms-auto d-block mb-n3" style={{ maxHeight: '105px' }} />
								</CardImgOverlay>
								
								<CardBody className="position-relative">
									<h5 className="text-white text-opacity-80 mb-3 fs-16px">Page Views</h5>
									<h3 className="text-white mt-n1">320.4k</h3>
									<div className="progress bg-black bg-opacity-50 mb-2" style={{ height: '6px' }}>
										<div className="progrss-bar progress-bar-striped bg-white" style={{ width: '80%' }}></div>
									</div>
									<div className="text-white text-opacity-80 mb-4"><i className="fa fa-caret-up"></i> 20% increase <br />compare to last week</div>
									<div><a href="#/" className="text-white d-flex align-items-center text-decoration-none">View report <i className="fa fa-chevron-right ms-2 text-white text-opacity-50"></i></a></div>
								</CardBody>
							</Card>
						</div>
					</div>
				</div>
			</div>
			
			<div className="row">
				<div className="col-xl-6">
					<div className="row">
						<div className="col-sm-6 mb-3 d-flex flex-column">
							<Card className="mb-3 flex-1">
								<CardBody>
									<div className="d-flex mb-3">
										<div className="flex-grow-1">
											<h5 className="mb-1">Total Users</h5>
											<div>Store user account registration</div>
										</div>
										<a href="#/" className="text-secondary"><i className="fa fa-redo"></i></a>
									</div>
									
									<div className="d-flex">
										<div className="flex-grow-1">
											<h3 className="mb-1">184,593</h3>
											<div className="text-success fw-600 fs-13px">
												<i className="fa fa-caret-up"></i> +3.59%
											</div>
										</div>
										<div className="w-50px h-50px bg-primary bg-opacity-20 rounded-circle d-flex align-items-center justify-content-center">
											<i className="fa fa-user fa-lg text-primary"></i>
										</div>
									</div>
								</CardBody>
							</Card>
							
							<Card>
								<CardBody>
									<div className="d-flex mb-3">
										<div className="flex-grow-1">
											<h5 className="mb-1">Social Media</h5>
											<div>Facebook page stats overview</div>
										</div>
										<a href="#/" className="text-secondary"><i className="fa fa-redo"></i></a>
									</div>
									
									<div className="row">
										<div className="col-6 text-center">
											<div className="w-50px h-50px bg-primary bg-opacity-20 rounded-circle d-flex align-items-center justify-content-center mb-2 ms-auto me-auto">
												<i className="fa fa-thumbs-up fa-lg text-primary"></i>
											</div>
											<div className="fw-600 text-body">306.5k</div>
											<div className="fs-13px">Likes</div>
										</div>
										
										<div className="col-6 text-center">
											<div className="w-50px h-50px bg-primary bg-opacity-20 rounded-circle d-flex align-items-center justify-content-center mb-2 ms-auto me-auto">
												<i className="fa fa-comments fa-lg text-primary"></i>
											</div>
											<div className="fw-600 text-body">27.5k</div>
											<div className="fs-13px">Comments</div>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
						
						<div className="col-sm-6 mb-3">
							<Card className="h-100">	
								<CardBody>
									<div className="d-flex mb-3">
										<div className="flex-grow-1">
											<h5 className="mb-1">Web Traffic</h5>
											<div className="fs-13px">Traffic source and category</div>
										</div>
										<a href="#/" className="text-secondary"><i className="fa fa-redo"></i></a>
									</div>
									
									<div className="mb-4">
										<h3 className="mb-1">320,958</h3>
										<div className="text-success fs-13px fw-600">
											<i className="fa fa-caret-up"></i> +20.9%
										</div>
									</div>
									
									<div className="progress mb-4" style={{ height: '10px'}}>
										<div className="progress-bar bg-primary" style={{ width: '42.66%'}}></div>
										<div className="progress-bar bg-teal" style={{ width: '36.80%'}}></div>
										<div className="progress-bar bg-yellow" style={{ width: '15.34%'}}></div>
										<div className="progress-bar bg-pink" style={{ width: '9.20%'}}></div>
										<div className="progress-bar bg-gray-200" style={{ width: '5.00%'}}></div>
									</div>
									
									<div className="fs-13px">
										<div className="d-flex align-items-center mb-2">
											<div className="flex-grow-1 d-flex align-items-center">
												<i className="fa fa-circle fs-9px fa-fw text-primary me-2"></i> Direct visit
											</div>
											<div className="fw-600 text-body">42.66%</div>
										</div>
										<div className="d-flex align-items-center mb-2">
											<div className="flex-grow-1 d-flex align-items-center">
												<i className="fa fa-circle fs-9px fa-fw text-teal me-2"></i> Organic Search
											</div>
											<div className="fw-600 text-body">36.80%</div>
										</div>
										<div className="d-flex align-items-center mb-2">
											<div className="flex-grow-1 d-flex align-items-center">
												<i className="fa fa-circle fs-9px fa-fw text-warning me-2"></i> Referral Website
											</div>
											<div className="fw-600 text-body">15.34%</div>
										</div>
										<div className="d-flex align-items-center mb-2">
											<div className="flex-grow-1 d-flex align-items-center">
												<i className="fa fa-circle fs-9px fa-fw text-danger me-2"></i> Social Networks
											</div>
											<div className="fw-600 text-body">9.20%</div>
										</div>
										<div className="d-flex align-items-center mb-15px">
											<div className="flex-grow-1 d-flex align-items-center">
												<i className="fa fa-circle fs-9px fa-fw text-gray-200 me-2"></i> Others
											</div>
											<div className="fw-600 text-body">5.00%</div>
										</div>
										<div className="fs-12px text-end">
											<span className="fs-10px">powered by </span>
											<span className="d-inline-flex fw-600">
												<span className="text-primary">G</span>
												<span className="text-danger">o</span>
												<span className="text-warning">o</span>
												<span className="text-primary">g</span>
												<span className="text-green">l</span>
												<span className="text-danger">e</span>
											</span>
											<span className="fs-10px">Analytics API</span>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
					</div>
				</div>
				
				<div className="col-xl-6 mb-3">
					<Card className="h-100">
						<CardBody>
							<div className="d-flex mb-3">
								<div className="flex-grow-1">
									<h5 className="mb-1">Sales Analytics</h5>
									<div className="fs-13px">Weekly sales performance chart</div>
								</div>
								<a href="#/" className="text-secondary"><i className="fa fa-redo"></i></a>
							</div>
							<Chart type="line" height="256" options={chartOptions} series={chartData} />
						</CardBody>
					</Card>
				</div>	
			</div>
			
			<div className="row">
				<div className="col-xl-6 mb-3">
					<Card className="h-100">
						<CardBody>
							<div className="d-flex align-items-center mb-4">
								<div className="flex-grow-1">
									<h5 className="mb-1">Bestseller</h5>
									<div className="fs-13px">Top 3 product sales this week</div>
								</div>
								<a href="#/" className="text-decoration-none">See All</a>
							</div>
							
							<div className="d-flex align-items-center mb-3">
								<div className="d-flex align-items-center justify-content-center me-3 w-50px h-50px bg-white p-3px rounded">
									<img src="/assets/img/product/product-1.jpg" alt="" className="ms-100 mh-100" />
								</div>
								<div className="flex-grow-1">
									<div>
										<div className="text-primary fs-10px fw-600">TOP SALES</div>
										<div className="text-body fw-600">iPhone 11 Pro Max (256GB)</div>
										<div className="fs-13px">$1,099</div>
									</div>
								</div>
								<div className="ps-3 text-center">
									<div className="text-body fw-600">382</div>
									<div className="fs-13px">sales</div>
								</div>
							</div>
							
							<div className="d-flex align-items-center mb-3">
								<div className="d-flex align-items-center justify-content-center me-3 w-50px h-50px bg-white p-3px rounded">
									<img src="/assets/img/product/product-2.jpg" alt="" className="ms-100 mh-100" />
								</div>
								<div className="flex-grow-1">
									<div>
										<div className="text-body fw-600">Macbook Pro 13 inch (2021)</div>
										<div className="fs-13px">$1,120</div>
									</div>
								</div>
								<div className="ps-3 text-center">
									<div className="text-body fw-600">102</div>
									<div className="fs-13px">sales</div>
								</div>
							</div>
							
							<div className="d-flex align-items-center mb-3">
								<div className="d-flex align-items-center justify-content-center me-3 w-50px h-50px bg-white p-3px rounded">
									<img src="/assets/img/product/product-3.jpg" alt="" className="ms-100 mh-100" />
								</div>
								<div className="flex-grow-1">
									<div>
										<div className="text-body fw-600">Apple Watch Series 4(2021)</div>
										<div className="fs-13px">$349</div>
									</div>
								</div>
								<div className="ps-3 text-center">
									<div className="text-body fw-600">75</div>
									<div className="fs-13px">sales</div>
								</div>
							</div>
							
							<div className="d-flex align-items-center mb-3">
								<div className="d-flex align-items-center justify-content-center me-3 w-50px h-50px bg-white p-3px rounded">
									<img src="/assets/img/product/product-4.jpg" alt="" className="ms-100 mh-100" />
								</div>
								<div className="flex-grow-1">
									<div>
										<div className="text-body fw-600">12.9-inch iPad Pro (256GB)</div>
										<div className="fs-13px">$1,099</div>
									</div>
								</div>
								<div className="ps-3 text-center">
									<div className="text-body fw-600">62</div>
									<div className="fs-13px">sales</div>
								</div>
							</div>
							
							<div className="d-flex align-items-center">
								<div className="d-flex align-items-center justify-content-center me-3 w-50px h-50px bg-white p-3px rounded">
									<img src="/assets/img/product/product-5.jpg" alt="" className="ms-100 mh-100" />
								</div>
								<div className="flex-grow-1">
									<div>
										<div className="text-body fw-600">iPhone 11 (128gb)</div>
										<div className="fs-13px">$799</div>
									</div>
								</div>
								<div className="ps-3 text-center">
									<div className="text-body fw-600">59</div>
									<div className="fs-13px">sales</div>
								</div>
							</div>
						</CardBody>
					</Card>
				</div>
				
				<div className="col-xl-6 mb-3">
					<Card className="h-100">
						<CardBody>
							<div className="d-flex align-items-center mb-2">
								<div className="flex-grow-1">
									<h5 className="mb-1">Transaction</h5>
									<div className="fs-13px">Latest transaction history</div>
								</div>
								<a href="#/" className="text-decoration-none">See All</a>
							</div>
							
							<div className="table-responsive mb-n2">
								<table className="table table-borderless mb-0">
									<thead>
										<tr className="text-body">
											<th className="ps-0">No</th>
											<th>Order Details</th>
											<th className="text-center">Status</th>
											<th className="text-end pe-0">Amount</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="ps-0">1.</td>
											<td>
												<div className="d-flex align-items-center">
													<div className="w-40px h-40px">
														<img src="/assets/img/icon/paypal2.svg" alt="" className="ms-100 mh-100" />
													</div>
													<div className="ms-3 flex-grow-1">
														<div className="fw-600 text-body">Macbook Pro 15 inch</div>
														<div className="fs-13px">5 minutes ago</div>
													</div>
												</div>
											</td>
											<td className="text-center"><span className="badge bg-success bg-opacity-20 text-success" style={{ minWidth: '60px' }}>Success</span></td>
											<td className="text-end pe-0">$1,699.00</td>
										</tr>
										<tr>
											<td className="ps-0">2.</td>
											<td>
												<div className="d-flex align-items-center">
													<div className="w-40px h-40px rounded">
														<img src="/assets/img/icon/mastercard.svg" alt="" className="ms-100 mh-100" />
													</div>
													<div className="ms-3 flex-grow-1">
														<div className="fw-600 text-body">Apple Watch 5 Series</div>
														<div className="fs-13px">5 minutes ago</div>
													</div>
												</div>
											</td>
											<td className="text-center"><span className="badge bg-success bg-opacity-20 text-success" style={{ minWidth: '60px' }}>Success</span></td>
											<td className="text-end pe-0">$699.00</td>
										</tr>
										<tr>
											<td className="ps-0">3.</td>
											<td>
												<div className="d-flex align-items-center">
													<div className="w-40px h-40px rounded">
														<img src="/assets/img/icon/visa.svg" alt="" className="ms-100 mh-100" />
													</div>
													<div className="ms-3 flex-grow-1">
														<div className="fw-600 text-body">iPhone 11 Pro Max</div>
														<div className="fs-13px">12 minutes ago</div>
													</div>
												</div>
											</td>
											<td className="text-center"><span className="badge bg-warning bg-opacity-20 text-warning" style={{ minWidth: '60px' }}>Pending</span></td>
											<td className="text-end pe-0">$1,299.00</td>
										</tr>
										<tr>
											<td className="ps-0">4.</td>
											<td>
												<div className="d-flex align-items-center">
													<div className="w-40px h-40px rounded">
														<img src="/assets/img/icon/paypal2.svg" alt="" className="ms-100 mh-100" />
													</div>
													<div className="ms-3 flex-grow-1">
														<div className="fw-600 text-body">Apple Magic Keyboard</div>
														<div className="fs-13px">15 minutes ago</div>
													</div>
												</div>
											</td>
											<td className="text-center"><span className="badge text-body text-opacity-50 bg-dark bg-opacity-10" style={{ minWidth: '60px' }}>Cancelled</span></td>
											<td className="text-end pe-0">$199.00</td>
										</tr>
										<tr>
											<td className="ps-0">5.</td>
											<td>
												<div className="d-flex align-items-center">
													<div className="w-40px h-40px rounded">
														<img src="/assets/img/icon/mastercard.svg" alt="" className="ms-100 mh-100" />
													</div>
													<div className="ms-3 flex-grow-1">
														<div className="fw-600 text-body">iPad Pro 15 inch</div>
														<div className="fs-13px">15 minutes ago</div>
													</div>
												</div>
											</td>
											<td className="text-center"><span className="badge bg-success bg-opacity-20 text-success" style={{ minWidth: '60px' }}>Cancelled</span></td>
											<td className="text-end pe-0">$1,099.00</td>
										</tr>
									</tbody>
								</table>
							</div>
						</CardBody>
					</Card>
				</div>
			</div>
		</div>
	)
};

export default Dashboard;