import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function CenteredModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      dialogClassName="responsive-modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" style={{ width: "100%", textAlign: "center" }}>
          <div className="brand" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <img
              src="/assets/img/kcc-logo.png"
              className="invert-dark"
              alt="KCC Logo"
              height="50"
              style={{ marginRight: "10px" }}
            />
            <h1 style={{ margin: 0, fontSize: "1.5rem" }}>KCC</h1>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: "center" }}>
        <h4 style={{ fontSize: "1.2rem" }}>CLICK THE BUTTON OR TAP CARD TO ADD ORDERS</h4>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <Button onClick={props.onHide} style={{ width: "30%" }}>ORDER</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CenteredModal;
