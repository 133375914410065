import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import dishesServices from "../../api/DishesServices/disheseService.js";
import printService from "../../api/PrintServices/printService.js";
import Swal from "sweetalert2";
import { toast, ToastContainer } from 'react-toastify';
import CenteredModal from "./customerOder/CenteredModal.js";
import PosHeader from '../../components/topbar/posHeader.jsx';

function DayEnd() {
  const [summaryData, setSummaryData] = useState([]);
  const [exitedRows, setExitedRows] = useState({});
  const [cardList, setCardList] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const moment = require("moment");

  useEffect(() => {
    const transactionOrder = async () => {
      try {
        const response = await dishesServices().getAllOreders();
        const orderData = response.data;

        // Filter and group orders
        const filteredData = orderData
          .filter((order) => order.statusChange === "INPROGRESS")
          .reduce((acc, curr) => {
            const { rfidCardId, cardId, transactionID, totalAmount } = curr;

            if (!acc[cardId]) {
              acc[cardId] = {

                card: cardId,
                kcc_Id: rfidCardId,               
                OrderID: [],
                totalAmount: 0,
              };
            }

            acc[cardId].OrderID.push(transactionID);
            acc[cardId].totalAmount += totalAmount;

            return acc;
          }, {});

        // Convert grouped object to array
        const processedData = Object.values(filteredData);

        setSummaryData(processedData);
      } catch (error) {
      }
    };

    transactionOrder();
  }, []);

  useEffect(() => {
    // Update cardList whenever summaryData changes
    const updatedCardList = summaryData.map((data) => ({
      card: data.card,
      kcc_Id: data.rfidCardId,
      totalAmount: data.totalAmount,
      OrderID: data.OrderID,
    }));
    setCardList(updatedCardList);
  }, [summaryData]);

  const onUnsettle = async (card) => {
    const confirmation = await Swal.fire({
      title: "Are you sure?",
      text: `This will mark all orders for card ${card} as UNSETTLE.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, unsettle them!",
      cancelButtonText: "No, cancel",
    });
  
    if (!confirmation.isConfirmed) return;
  
    try {
      await dishesServices().handleUnsettle(card);
  
      Swal.fire({
        title: "Success!",
        text: `All orders for card ${card} marked as UNSETTLE.`,
        icon: "success",
        timer: 2000, 
        showConfirmButton: false,
      });
  
      await printService().handleSaveCard("Active", [card]);
      await printService().handleGateControlCard("Active", [card]);
  
      await fetchSummaryData();
    } catch (error) {
      console.error("Error updating order status:", error);
      Swal.fire("Failed to update orders. Please try again.");
    }
  };
  

  const fetchSummaryData = async () => {
    try {
      const response = await dishesServices().getAllOreders();
      const orderData = response.data;

      const filteredData = orderData
        .filter((order) => order.statusChange === "INPROGRESS")
        .reduce((acc, curr) => {
          const { rfidCardId, cardId, transactionID, totalAmount } = curr;

          if (!acc[cardId]) {
            acc[cardId] = {
              card: cardId,
              kcc_Id: rfidCardId,
              OrderID: [],
              totalAmount: 0,
            };
          }

          acc[cardId].OrderID.push(transactionID);
          acc[cardId].totalAmount += totalAmount;

          return acc;
        }, {});

      const processedData = Object.values(filteredData);
      setSummaryData(processedData);
    } catch (error) {
      console.error("Error fetching summary data:", error);
    }
  };

  useEffect(() => {
    fetchSummaryData();
  }, []);

  const formatCurrency = (amount) => {
    if (isNaN(amount) || amount === null || amount === undefined) {
      return "0.00";
    }
    return `${amount.toFixed(2).toLocaleString()}`;
  };


  return (
    <>
<CenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
		<ToastContainer />
    
    <div className={'pos pos-vertical'  }>
    <div className="pos-container">
    <div >
				<PosHeader />
        <div className="nav-container">
			
    <div>
      <style>
        {`
          .table-container {
              padding: 20px;
              background-color: rgba(255, 255, 255, 0.8);
              backdrop-filter: blur(10px);
              border-radius: 8px;
              box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          }

          .table {
              width: 100%;
              border-collapse: collapse;
              background: transparent;
          }

          .table th,
          .table td {
              padding: 12px;
              text-align: left;
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }

          .table th {
              background-color: rgba(240, 240, 240, 0.5);
          }

          .table-row:hover {
              background-color: rgba(230, 230, 230, 0.3);
          }

          .exit-button {
              background-color: #f44336; /* Red */
              color: white;
              border: none;
              padding: 8px 12px;
              border-radius: 4px;
              cursor: pointer;
          }

          .exit-button:hover {
              background-color: #d32f2f; /* Darker red on hover */
          }

          .exited-button {
              background-color: #4caf50; /* Green */
              color: white;
              border: none;
              padding: 8px 12px;
              border-radius: 4px;
              cursor: not-allowed;
          }
        `}
      </style>
      <div className="table-container">
        <h2>Day End Summary</h2>
        <br></br>
        <PerfectScrollbar >
          <table className="table">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>KCC ID</th>
                <th>Card</th>
                <th>Amount(RS)</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {summaryData
                  .sort((a, b) => {
                    const orderA = Array.isArray(a.OrderID) ? Math.max(...a.OrderID) : a.OrderID;
                    const orderB = Array.isArray(b.OrderID) ? Math.max(...b.OrderID) : b.OrderID;
                    return orderB - orderA;
                  })
              .map((data, index) => (
                  <tr key={index} className="table-row">
                    <td>{data.OrderID.join(", ")}</td>
                    <td>{data.kcc_Id}</td>
                    <td>{data.card}</td>
                    <td style={{ textAlign: 'left' }}>{formatCurrency(data.totalAmount)}</td>
                    <td>
                      {exitedRows[data.card] ? (
                        <button className="exited-button" disabled>
                          Settled
                        </button>
                      ) : (
                        <button
                          className="exit-button"
                          onClick={() => onUnsettle(data.card)}
                        >
                          Not Settled
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </PerfectScrollbar>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </>
  );
}

export default DayEnd;
