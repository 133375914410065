import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import discountService from '../../../api/discountServices/discountService';
import dishesServices from '../../../api/DishesServices/disheseService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import printService from '../../../api/PrintServices/printService';
import taxServices from '../../../api/TaxServices/taxServices';

function GiftCardModal(props) {
  const {
    subTotal,
    Tax,
    Total,
    onHide,
    show,
    giftCardNumber,
    transactionIds,
    finish,
    cardList,
    selectedExecutiveType,
    setSelectedExecutiveType,
    note,
    setNote,
  } = props;
  const [cardTypes, setCardTypes] = useState([]);
  const [selectedCardType, setSelectedCardType] = useState('');
  const [cardDiscount, setCardDiscount] = useState([]);
  const [discount, setDiscount] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [card, setCard] = useState(false);
  const [stall, setstall] = useState(false);
  const [notification, setNotification] = useState({ message: '', show: false, type: '' });
  const [numberInput, setNumberInput] = useState(""); 
  const [isValid, setIsValid] = useState(false);
  const [cardIds, setCardIds] = useState([]);

  var TotalPrice=Total;
  var Tax_Total=0;
  var vat_Total = 0;
  var discount_Amount=0;
  let taxIds = "";
  let discountSequences ="";
 

  const [receivedAmount, setReceivedAmount] = useState(0);
  const [changeToReturn, setChangeToReturn] = useState(0);

  const handleKeypadClick = (value) => {
    setReceivedAmount(prev => prev == '0' ? value.toString() : prev + value.toString());
};

  const clearAmount = () => {
    setReceivedAmount(0);
  };

  const handleIncrementClick = (increment) => {
    setReceivedAmount(prev => (parseFloat(prev) + increment).toString());
};

  const handleDecimalClick = () => {
    if (!receivedAmount.toString().includes('.')) {
        setReceivedAmount(prev => prev + '.');
    }
  };

  const posKeypad = !card && (
    <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '5px',
        marginTop: '10px',
    }}>
        {/* Number Pad */}
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => (
            <Button 
                key={num} 
                onClick={() => handleKeypadClick(num)} 
                style={{
                    padding: '20px',
                    fontSize: '1.2em',
                    backgroundColor: '#f0f0f0',
                    color: '#000',
                }}
            >
                {num}
            </Button>
        ))}

        {/* Bottom Row with Zero, Decimal and Clear */}
        <Button 
            onClick={() => handleKeypadClick(0)} 
            style={{
                padding: '20px',
                fontSize: '1.2em',
                backgroundColor: '#f0f0f0',
                color: '#000',
            }}
        >
            0
        </Button>
        <Button 
            onClick={handleDecimalClick} 
            style={{
                padding: '20px',
                fontSize: '1.2em',
                backgroundColor: '#f0f0f0',
                color: '#000',
            }}
        >
            .
        </Button>
        <Button 
            onClick={clearAmount} 
            style={{
                padding: '20px',
                fontSize: '1.2em',
                backgroundColor: '#f8d7da',
                color: '#000',
            }}
        >
            Clear
        </Button>
    </div>
);

const incrementButtons = !card && (
  <div style={{
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '50px',
      marginTop: '20px',
      width: '80%',
      flex: 1,
  }}>
      { [5000, 1000, 500, 100, 10].map(amount => (
          <Button 
              key={amount} 
              onClick={() => handleIncrementClick(amount)} 
              style={{
                  padding: '20px',
                  fontSize: '1.2em',
                  backgroundColor: '#d4edda',
                  color: '#000',
                  width: '100%',
                  marginBottom: '5px'
              }}
          >
              +{amount}
          </Button>
      )) }
  </div>
);

  const handleReceivedAmountChange = (e) => {
    const amountReceived = parseFloat(e.target.value);
    setReceivedAmount(amountReceived);
    setChangeToReturn(amountReceived - TotalPrice);  
  };

  var getTotalPrice = (discountPercent,Sequences) => {
    
    let discountAmount = 0;

    if (discount) {
      const amount = parseFloat(discountPercent) || 0;
      discountAmount = (Total/100)*amount;
      discount_Amount = discountAmount;
      TotalPrice -=  discountAmount || 0;
    }
    discountSequences = Sequences;

    return discountAmount.toFixed(2);
  };

  var paymethod = () => {
    
    if (Tax) {
      Tax.forEach( (VAT) => {
        if(VAT.taxType=== "VAT")
          vat_Total=VAT.taxTotal || 0;
        console.log("VATTTTTTTTTTTTTTTTTTTTTTT",vat_Total)
      });
      if (discount.length === 0) {
        TotalPrice = ((Total)-vat_Total).toFixed(2) || 0;
      }
      else{
        TotalPrice = (TotalPrice)-vat_Total || 0;
      }
    }
    return vat_Total;
  };

  var totalVat = () => {
    
    if (Tax) {
      
      Tax.forEach( (VAT) => {
        if(VAT.taxCode=== "VAT"){
          vat_Total=VAT.taxTotal || 0;}
      });
    }
    return vat_Total;
  };

  var totaltax = () => {
    if (Tax) {
      Tax.forEach((TOT) => {
          Tax_Total += parseFloat(TOT.taxTotal) || 0;
          if(taxes){
            taxes.forEach((TT) => {
            if (TOT.taxCode === TT.taxCode) {
              if (TT.taxListId) {
                  taxIds += taxIds ? `,${TT.taxListId}` : TT.taxListId;
              }
            }
          });
        }
      });
    }
  };

  const cards = async () => {
		try {
			const response = await dishesServices().getAllCards();
      var foundCard = response.data?.find(card => card.card_Id === giftCardNumber) || null;
				if (foundCard) {
          
					setReceivedAmount(foundCard.max_Amount);
        }
		} catch (error) {
			console.error("Error fetching cards:", error);
		}
		};

    const fetchTaxes = async () => {
      try {
        const response = await taxServices().getAllTax();
    
        setTaxes(response.data); 
        
      } catch (error) {
        console.error('Error fetching suppliers data:', error);
      }
      };
  

  const handleCardTypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedCardType(selectedType);
    const relevantItems = discount.filter(item => item.sequence === selectedType);
    setCardDiscount(relevantItems);
  };

  const colectiveData = async () => {
    var cashAmount =0;
    var cardAmount =0;
    discount_Amount = receivedAmount;
    discountSequences = "GIFTCARD"
    let paymentMethod = giftCardNumber ? giftCardNumber.toString() : '';
    try {
      totaltax();
      totalVat();
       await printService().handleSaveCard("Active",cardList);
       await printService().handleGateControlCard("Active",cardList);
       const response = await dishesServices().postColectiveData(
        TotalPrice,
        cashAmount,
        cardAmount,
        paymentMethod,
        discountSequences,
        taxIds,
        discount_Amount,
        Tax_Total,
        vat_Total,
        stall,
        transactionIds,
        selectedExecutiveType,
        note
      );
      
      if (response) {
        try {
            await printService().sendFullBill(response);
        } catch (error) {
        
            console.error("Failed to print full bill:", error);
        }
    }
      setNotification({ message: "Order was successful!", show: true, type: 'success' });
    } catch (error) {
      setNotification({ message: "Order failed. Please try again.", show: true, type: 'error' });
    }
  };

  const handleKeyPress = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleNumberInputChange = (event) => {
    let { value } = event.target;
    value = value.replace(/[^0-9]/g, "").slice(0, 4);
    setNumberInput(value);
    setIsValid(value.length === 4);  
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  }; 

  useEffect(() => {
    if (show) {
      cards();
      fetchTaxes();
      setCard(true);
      setCardDiscount([]);
      setSelectedCardType('')
      setstall(false)
      setChangeToReturn(0);
      setNumberInput("");
      setNote("");
      setSelectedExecutiveType("");
    }
  }, [show]);

  useEffect(() => {
    setChangeToReturn(receivedAmount - TotalPrice);
  }, [stall]);

  useEffect(() => {
    setChangeToReturn(parseFloat(receivedAmount) - TotalPrice); // Update changeToReturn when receivedAmount changes
  }, [receivedAmount, TotalPrice]);
  return (
    <>
    <ToastContainer />
    <Modal
        show={show}
        onHide={onHide}
        size="lg"
        fullscreen={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" style={{ fontWeight: 'bold', fontSize: '1.5em' }}>
                 GIFT CARD PAYMENT - {giftCardNumber}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '20px', backgroundColor: '#f9f9f9' }}>
            <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: '2.5', display: 'flex', justifyContent: 'space-between', fontSize: '1.5em' }}>
              {/* Left Section: Payment details and inputs */}
              <div style={{ flex: 1, marginRight: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                  <span>Sub Total:</span>
                  <span style={{ fontWeight: 'bold'}}> {subTotal}</span>
                </div>
                {Tax && Tax.map((T, index) => (
                  <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0px' }}>
                    <span>{T.taxCode}: {T.taxPrese}%</span>
                    <span style={{ fontWeight: 'bold'}}> {T.taxTotal}</span>
                  </div>
                ))}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', marginTop: '10px' }}>
                  <span>Total:</span>
                  <span style={{ fontWeight: 'bold'}}> {Total}</span>
                </div>
                <hr />
                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', color: 'red' }}>
                    <span>Total Amount:</span>
                    <span> {isNaN(TotalPrice) ? '0.00' : parseFloat(TotalPrice).toFixed(2)}</span>
                </div>
                <hr />
                
                {card && (
                  <>  
                    <div style={{ marginBottom: '20px' }}>
                     
                      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                        <span>Amount Received From Gift Card:</span>
                        <input
                            type="text"
                            value={receivedAmount}
                            readOnly
                            style={{ width: '150px', textAlign: 'right', fontSize: '1.2em', fontWeight: 'bold', padding: '5px',height:'40px' }}
                        />
                    </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', color: 'red', fontSize: '1.1em' }}>
                        <span>Gift Card's Remining Amount:</span>
                        <span> {changeToReturn.toFixed(2)}</span>
                    </div>

                    <div style={{ marginTop: '10px' }}>
                        <label htmlFor="note" style={{ display: 'block', marginBottom: '5px', fontSize: '0.9em' }}>
                          Add Note:
                        </label>
                          <textarea
                            id="note"
                            name="note"
                            placeholder="Enter note here..."
                            rows="1"
                            value={note}
                            onChange={handleNoteChange}
                            style={{
                              width: '100%',
                              padding: '10px',
                              fontSize: '0.9em',
                              borderRadius: '5px',
                              border: '1px solid #ccc',
                              resize: 'none',
                            }}
                          ></textarea>
                      </div> 
                    
                  </>
                )}
              </div>

              {/* Right Section: posKeypad */}
              <div style={{ flexBasis: '200px', marginTop: '-20px', marginRight: '20px' }}>
                        {incrementButtons}
                    </div>
              <div style={{ flexBasis: '300px', marginTop: '-10px' }}>
                {posKeypad}
              </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button 
                onClick={() => setstall(prev => !prev)} 
                style={{ backgroundColor: stall ? 'green' : 'red', color: 'white' }}
                hidden={true}
            >
                VAT FREE
            </Button>
            <Button onClick={() => { 
                colectiveData(); 
                onHide(); 
                finish(); 
            }}
            //  disabled={(() => {
            //   const isDisabled = (card && !isValid) || (card && selectedCardType === "") ? true : false; 
            //   return isDisabled;
            // })()}
            >
                PAY BY Gift Card
            </Button>
        </Modal.Footer>
    </Modal>

    <Modal show={notification.show} onHide={() => setNotification({ ...notification, show: false })} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {notification.type === 'success' ? (
              <>
                <FaCheckCircle size={24} style={{ color: 'green', marginRight: '12px' }} />
                <span>Payment Successful</span>
              </>
            ) : (
              <>
                <FaExclamationCircle size={24} style={{ color: 'red', marginRight: '12px' }} />
                <span>An Error Occurred</span>
              </>
            )}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {notification.message}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setNotification({ ...notification, show: false })}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
}

export default GiftCardModal;
