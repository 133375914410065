import React, { useState, useEffect } from 'react';
import taxServices from '../../../api/TaxServices/taxServices';
import { Link } from 'react-router-dom';

const CustomerScreen = () => {
  const [orderItems, setOrderItems] = useState([]);
  const [Taxes, setTaxes] = useState([]);
  const [TotalTaxesPrice, setTotalTaxesPrice] = useState(0);
  const [status, setStatus] = useState([]);
  let totalAddOnsPricee = 0;
  let taxTotals = [];

  const fetchTaxes = async () => {
    try {
      const response = await taxServices().getAllTax();
      setTaxes(response.data);
    } catch (error) {
      console.error('Error fetching Tax data:', error);
    }
  };

  const getTaxesPrice = (taxType) => {
    let TaxesPrice = 0;
    let taxPrese = 0;

    if (orderItems) {
      orderItems.forEach((order) => {
        if (order.taxItems) {
          order.taxItems.forEach((tax) => {
            if (tax.taxType === taxType) {
              TaxesPrice += tax.taxValue * order.quantity;
              taxPrese = tax.taxPercentage;
            }
          });
        }
      });

      const existingTax = taxTotals.find((t) => t.taxType === taxType);
      if (existingTax) {
        existingTax.taxTotal = TaxesPrice.toFixed(2);
      } else {
        if (taxType && TaxesPrice) {
          taxTotals.push({
            taxType: taxType,
            taxPrese: taxPrese,
            taxTotal: TaxesPrice.toFixed(2),
          });
        }
      }
    }
    return TaxesPrice.toFixed(2);
  };

  useEffect(() => {
    setStatus(localStorage.getItem('orderData'));
    fetchTaxes();

    const savedOrderData = localStorage.getItem('orderData');
    if (savedOrderData) {
      setOrderItems(JSON.parse(savedOrderData));
    }

    const handleStorageChange = (event) => {
      if (event.key === 'orderData') {
        const updatedOrderData = JSON.parse(event.newValue);
        setOrderItems(updatedOrderData);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  useEffect(() => {
    let totalTaxes = 0;

    Taxes.forEach((tax) => {
      totalTaxes += parseFloat(getTaxesPrice(tax.taxType));
    });

    setTotalTaxesPrice(totalTaxes);
    console.log('test oders:', orderItems);
  }, [orderItems, Taxes]);

  var getSubTotalPrice = (items) => {
    let subtotal = 0;
  
    if (items && Array.isArray(items)) {
      items.forEach((sub) => {
        if ((sub.addOns).length > 0) {
          sub.addOns.forEach((addon) => {
            subtotal += (addon.price+sub.itemPrice)*sub.quantity || 0;
          });
        }else{
          subtotal += sub.itemPrice *sub.quantity 
        }
      });
    }
  
    return subtotal;
  };
  

  const subtotal = getSubTotalPrice(orderItems)
  const total =  subtotal + TotalTaxesPrice;

  return (
    <div style={styles.container}>
      <div className="brand" style={styles.brand}>
        <img
          src="/assets/img/kcc-logo.png"
          className="invert-dark"
          alt="KCC Logo"
          height="70"
        />
        <h1 style={styles.title}>KCC - FOOD COURT</h1>
      </div>

      <div style={styles.orderList}>
        <h2 style={styles.sectionTitle}>Order Items</h2>
        <div style={styles.tableContainer}>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.th}>Item</th>
                <th style={styles.th}>Quantity</th>
                <th style={styles.th}>Price (RS)</th>
                <th style={styles.th}>Total (RS)</th>
              </tr>
            </thead>
            <tbody>
            {orderItems.length > 0 ? (
  orderItems.map((item) => {
    const totalAddOnsPrice = item.addOns.reduce(
      (total, addOn) => total + addOn.price, 
      0
    );
    const itemTotalPrice = item.itemPrice + totalAddOnsPrice;
    totalAddOnsPricee += itemTotalPrice;

    return (
      <tr key={item.id} style={styles.tableRow}>
        <td style={styles.td}>
          {item.itemName}
          {item.addOns.map((addOn, index) => (
            <span key={index}> + {addOn.addOn||addOn.addon} </span>
          ))}
        </td>
        <td style={styles.td}>{item.quantity}</td>
        <td style={styles.td}>{itemTotalPrice}</td>
        <td style={styles.td}>{itemTotalPrice * item.quantity}</td>
      </tr>
    );
  })
) : (
  <tr>
    <td colSpan="4" align="center" style={styles.emptyMessage}>
      No items in the order.
    </td>
  </tr>
)}

            </tbody>
          </table>
        </div>
      </div>

      <div style={styles.summary}>
        <h2 style={styles.sectionTitle}>Order Summary</h2>
        <div style={styles.summaryRow}>
          <span>Subtotal:</span>
          <span>RS {subtotal.toFixed(2)}</span>
        </div>
        <div className="small mb-2">
          {Taxes.map((tax, index) => (
            <div key={index} className="d-flex justify-content-between">
              <div>
                {tax.taxType}: {tax.taxRate} %
              </div>
              <div className="flex-1 text-end h6 mb-0">
                RS {getTaxesPrice(tax.taxType)}
              </div>
            </div>
          ))}
        </div>
        <div style={{ ...styles.summaryRow, ...styles.totalRow }}>
          <span>Total Payment:</span>
          <span>RS {total.toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '30px',
    fontFamily: "'Poppins', sans-serif",
    backgroundColor: '#f9f9f9',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  brand: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    marginBottom: '30px',
  },
  title: {
    fontSize: '2rem',
    color: '#333',
    fontWeight: '600',
  },
  orderList: {
    width: '80%',
    marginBottom: '30px',
  },
  tableContainer: {
    maxHeight: '300px',
    overflowY: 'auto',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  th: {
    padding: '12px',
    backgroundColor: '#f0f0f0',
    textAlign: 'left',
    borderBottom: '2px solid #ddd',
  },
  td: {
    padding: '12px',
    borderBottom: '1px solid #ddd',
    textAlign: 'left',
  },
  emptyMessage: {
    padding: '20px',
    fontStyle: 'italic',
    color: '#888',
  },
  summary: {
    width: '80%',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  summaryRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    fontSize: '1rem',
  },
  totalRow: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    marginTop: '15px',
  },
};

export default CustomerScreen;
