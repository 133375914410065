import { useEffect, useState, useRef } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import dishesServices from '../../../api/DishesServices/disheseService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import printService from '../../../api/PrintServices/printService';
import taxServices from '../../../api/TaxServices/taxServices'; 
import executiveService from "../../../api/ExecutiveServices/executiveService"; 

function OutletExicutiveModal(props) {

  const {
    subTotal,
    Tax,
    Total,
    onHide,
    show,
    transactionIds,
    finish,
    executiveList,
    paymentMethod,
    selectedExecutiveType,
    setSelectedExecutiveType,
    note,
    setNote,
    setIsTakeawayData,
    takeawayData,
    setIsBillPrinted,
  } = props;
  
  const [taxes, setTaxes] = useState([]);

  const [managerUsername, setManagerUsername] = useState("");
  const [managerPassword, setManagerPassword] = useState("");  
  const [errorMessage, setErrorMessage] = useState(""); 

  const [managerModal, setManagerModal] = useState(false);
  const [card, setCard] = useState(false);
  const [stall, setstall] = useState(false);

  const [notification, setNotification] = useState({ message: '', show: false, type: '' });

  const [creditLimit, setCreditLimit] = useState(0);  

  var TotalPrice = Total;
  var cashAmount = 0;
  var cardAmount = 0;
  var Tax_Total = 0;
  var vat_Total = 0;
  var discount_Amount = 0;
  var taxIds = "";
  var discountSequences = "";  

  var totalVat = () => { 
    if (Tax) { 
      Tax.forEach( (VAT) => {
        if(VAT.taxCode=== "VAT"){
          vat_Total=VAT.taxTotal || 0;}
      });
    }
    return vat_Total;
  };

  var totaltax = () => {
    if (Tax) {
      Tax.forEach((TOT) => {
          Tax_Total += parseFloat(TOT.taxTotal) || 0;
          if(taxes){
            taxes.forEach((TT) => {
            if (TOT.taxCode === TT.taxCode) {
              if (TT.taxListId) {
                  taxIds += taxIds ? `,${TT.taxListId}` : TT.taxListId;
              }
            }
          });
        }
      });
    }
  };
  
  const handleExecutiveTypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedExecutiveType(selectedType);
    const relevantItems = executiveList.find(item => item.executiveCode === selectedType)?.creditLimit;
    setCreditLimit(relevantItems);
    const remainingCredit = parseFloat(relevantItems - Total).toFixed(2);
    if (remainingCredit < 0) {
      const exceededAmount = Math.abs(remainingCredit).toFixed(2);
      //----Temporaraly commented-------
      //setNote(`Bill total value exceeds credit limit by ${exceededAmount}.`);
    } else {
      //setNote("");
    }
  };

  const handlePayAsExecutive = () => {
    setManagerModal(true);
  };

   const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const closeModal = () => {
    setManagerUsername("");
    setManagerPassword("");
    setErrorMessage("");
    setManagerModal(false);
  };

  const handleManagerApprovalSubmit = async() => {
    const validationResult = await dishesServices().validManager(managerUsername, managerPassword);

		if (!validationResult.success) { 
			setErrorMessage(validationResult.message);
			return;  
		}
	
		setErrorMessage(""); 
    
    setManagerModal(false); 
		complimentaryData();
    onHide();
    finish(); 
  };

  const complimentaryData = async () => {
    try {
       
      const executiveCreditLimit = parseFloat(creditLimit - Total);
      totaltax();
      totalVat(); 
       
      const response = await dishesServices().postColectiveData(
        TotalPrice,
        cashAmount,
        cardAmount,
        paymentMethod,
        discountSequences,
        taxIds,
        discount_Amount,
        Tax_Total,
        vat_Total,
        stall,
        transactionIds,
        selectedExecutiveType,
        note
      );
      await executiveService().handleExecutiveCrediLimit(
        executiveCreditLimit,
        selectedExecutiveType
      );
      

      if (response) {
        setIsBillPrinted(false);
        setNotification({
          message: "Executive Payment was Successful!",
          show: true,
          type: "success",
        });
        
        if (takeawayData) {
          try {
            setIsTakeawayData(false); 
            localStorage.removeItem("TAKEAWAY");
          } catch (error) {
            console.error("Failed to print full bill:", error);
          } 
        }
      }
      
    } catch (error) {
      setNotification({
        message: "Payment failed. Please try again.",
        show: true,
        type: "error",
      });
    }
  };

  const fetchTaxes = async () => {
      try {
        const response = await taxServices().getAllTax(); 
        setTaxes(response.data);  
      } catch (error) {
        console.error('Error fetching suppliers data:', error);
      }
  };

  useEffect(() => {
    if (show) { 
      fetchTaxes();
      setCard(true);  
      setstall(false) 
      setCreditLimit(0); 
      setTaxes([]);
      setNote('');
      setSelectedExecutiveType('');
    }
  }, [show]);
 
  return (
    <>
    <ToastContainer />
    <Modal
        show={show}
        onHide={onHide}
        size="lg"
        fullscreen={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" style={{ fontWeight: 'bold', fontSize: '1.5em' }}>
                 EXECUTIVE PAYMENT
            </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '20px', backgroundColor: '#f9f9f9' }}>
            <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: '2.5', display: 'flex', justifyContent: 'space-between', fontSize: '1.5em' }}>
  
              <div style={{ flex: 1, marginRight: '20px' ,fontSize: '0.9em'}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                  <span>Sub Total:</span>
                  <span style={{ fontWeight: 'bold'}}> {subTotal}</span>
                </div>

                {Tax && Tax.map((T, index) => (
                  <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0px',fontSize: '0.9em' }}>
                    <span>{T.taxCode}: {T.taxPrese}%</span>
                    <span style={{ fontWeight: 'bold'}}> {T.taxTotal}</span>
                  </div>
                ))}

                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', marginTop: '10px',fontSize: '0.9em' }}>
                  <span>Total:</span>
                  <span style={{ fontWeight: 'bold'}}> {Total}</span>
                </div>

                <hr /> 

                {card && (
                  <>   
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' ,fontSize: '0.9em'}}>
                      <label> Executive Type: </label> 
                        <select
                          className="form-control"
                          value={selectedExecutiveType}
                          onChange={handleExecutiveTypeChange} 
                          style={{marginLeft: 'left',  width: '250px',  fontSize: '0.9em', padding: '5px' ,height:'40px',textAlign:'left'}}
                        >
                          <option value="" disabled>Select a Executive Type</option>
                           {executiveList?.map(exType => (
                            <option key={exType.executiveCode} value={exType.executiveCode}>
                              {exType.executiveName }
                            </option>
                          ))}
                        </select>
                    </div>
                      {/* ----Temporaraly commented------- */}
                      {/* <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.9em' }}>
                        <span>Executive Type's Credit Limit:</span>
                        <span style={{ fontWeight: 'bold'}}> {parseFloat(creditLimit).toFixed(2)}</span>
                      </div>

                      <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.9em',color: 'red' }}>
                        <span>Remaining Credit Limit:</span>
                        <span style={{ fontWeight: 'bold'}}> { selectedExecutiveType ? (parseFloat(creditLimit-Total).toFixed(2)) : (parseFloat(0).toFixed(2))}</span>
                      </div> */}

                      <div style={{ marginTop: '10px' }}>
                        <label htmlFor="note" style={{ display: 'block', marginBottom: '5px', fontSize: '0.9em' }}>
                          Add Note:
                        </label>
                          <textarea
                            id="note"
                            name="note"
                            placeholder="Enter note here..."
                            rows="2"
                            value={note}
                            onChange={handleNoteChange}
                            style={{
                              width: '100%',
                              padding: '10px',
                              fontSize: '0.9em',
                              borderRadius: '5px',
                              border: '1px solid #ccc',
                              resize: 'none',
                            }}
                          ></textarea>
                      </div> 
                  </>
                )}
              </div> 
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button 
                onClick={() => setstall(prev => !prev)} 
                style={{ backgroundColor: stall ? 'green' : 'red', color: 'white' }}
                hidden={true}
            >
                VAT FREE
            </Button>
            <Button onClick={() => { 
                handlePayAsExecutive();  
            }}
             disabled={(() => {
              const isDisabled = (card && selectedExecutiveType === "") ? true : false; 
              return isDisabled;
              })()}
            >
                PAY AS EXECUTIVE
            </Button>
        </Modal.Footer>
    </Modal>

    <Modal show={notification.show} onHide={() => setNotification({ ...notification, show: false })} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {notification.type === 'success' ? (
              <>
                <FaCheckCircle size={24} style={{ color: 'green', marginRight: '12px' }} />
                <span>Payment Successful</span>
              </>
            ) : (
              <>
                <FaExclamationCircle size={24} style={{ color: 'red', marginRight: '12px' }} />
                <span>An Error Occurred</span>
              </>
            )}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {notification.message}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setNotification({ ...notification, show: false })}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>

    <Modal show={managerModal} onHide={() => setManagerModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Executive Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Manager approval is required to proceed with this payment.</p>
          <div>
            <label>Manager Username:</label>
            <input
              type="text"
              className="form-control"
              value={managerUsername}
              onChange={(e) => setManagerUsername(e.target.value)}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <label>Manager Password:</label>
            <input
              type="password"
              className="form-control"
              value={managerPassword}
              onChange={(e) => setManagerPassword(e.target.value)}
            />
          </div>
          {errorMessage && <p style={{ color: "red", marginTop: "10px" }}>{errorMessage}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Cancel</Button>
          <Button variant="primary" onClick={handleManagerApprovalSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OutletExicutiveModal;
