import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import discountService from '../../../api/discountServices/discountService';
import dishesServices from '../../../api/DishesServices/disheseService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import printService from '../../../api/PrintServices/printService';
import taxServices from '../../../api/TaxServices/taxServices';
import Swal from 'sweetalert2';

function OutletMultiPaymentModal(props) {
  const {
    subTotal,
    Tax,
    Total,
    onHide,
    show,
    paymentMethod,
    transactionIds,
    finish,
    selectedExecutiveType,
    setSelectedExecutiveType,
    note,
    setNote,
    setIsTakeawayData,
    takeawayData,
    isTakeawayData,
    setIsBillPrinted
  } = props;

  const [cardTypes, setCardTypes] = useState([]);
  const [cardDiscount, setCardDiscount] = useState([]);
  const [discount, setDiscount] = useState([]);
  const [taxes, setTaxes] = useState([]);

  const [selectedCardType, setSelectedCardType] = useState(''); 
  const [SelectPaymentMethods, setSelectPaymentMethods] = useState("");
  
  const [stall, setstall] = useState(false);

  const [notification, setNotification] = useState({ message: '', show: false, type: '' });
 
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [receivedAmountCOMBANK, setReceivedAmountCOMBANK] = useState(0);
  const [receivedAmountSAMPATH, setReceivedAmountSAMPATH] = useState(0);
  const [receivedAmountAMEX, setReceivedAmountAMEX] = useState(0);
  const [receivedAmountGTV, setReceivedAmountGTV] = useState(0);
  const [receivedAmountGTV_NO, setReceivedAmountGTV_NO] = useState(0);
  const [changeToReturn, setChangeToReturn] = useState(0);
  const [recivedTotalAmount, setRecivedTotalAmount] = useState(0);

  var TotalPrice = Total;
  var Tax_Total = 0;
  var vat_Total = 0;
  var discount_Amount = 0;
  let taxIds = "";
  let discountSequences = "";

  const handleKeypadClick = (value,type) => {
    switch (type) {
      case "CASH":
        setReceivedAmount(prev => prev == '0' ? value.toString() : prev + value.toString()); 
        break;
    
      case "COM":
         setReceivedAmountCOMBANK(prev => prev == '0' ? value.toString() : prev + value.toString());
        break;
    
      case "SAM":
        setReceivedAmountSAMPATH(prev => prev == '0' ? value.toString() : prev + value.toString());
        break;
    
      case "AMEX":
        setReceivedAmountAMEX(prev => prev == '0' ? value.toString() : prev + value.toString()); 
        break;
    
      case "GIFT":
        setReceivedAmountGTV(prev => prev == '0' ? value.toString() : prev + value.toString());
        break;

        case "GIFT_NO":
        setReceivedAmountGTV_NO(prev => prev == '0' ? value.toString() : prev + value.toString())
        break;
    
      default:
        console.error("Invalid payment type");
    };

  };

  const clearAmount = (type) => {
    switch (type) {
      case "CASH":
        setReceivedAmount(0);
        break;
    
      case "COM":
        setReceivedAmountCOMBANK(0)
        break;
    
      case "SAM":
        setReceivedAmountSAMPATH(0)
        break;
    
      case "AMEX":
        setReceivedAmountAMEX(0)
        break;
    
      case "GIFT":
        setReceivedAmountGTV(0)
        break;

        case "GIFT_NO":
        setReceivedAmountGTV_NO(0)
        break;
    
      default:
        console.error("Invalid payment type");
    };
  };

  const exactAmount = (type) => {
    switch (type) {
      case "CASH":
        setReceivedAmount(TotalPrice);
        break;
    
      case "COM":
        setReceivedAmountCOMBANK(TotalPrice)
        break;
    
      case "SAM":
        setReceivedAmountSAMPATH(TotalPrice)
        break;
    
      case "AMEX":
        setReceivedAmountAMEX(TotalPrice)
        break;
    
      case "GIFT":
        setReceivedAmountGTV(TotalPrice)
        break;
    
      default:
        console.error("Invalid payment type");
    };
  };

  const handleIncrementClick = (increment,type) => {
    switch (type) {
      case "CASH":
        setReceivedAmount(prev => (parseFloat(prev) + increment).toString());
        break;
    
      case "COM":
        setReceivedAmountCOMBANK(prev => (parseFloat(prev) + increment).toString())
        break;
    
      case "SAM":
        setReceivedAmountSAMPATH(prev => (parseFloat(prev) + increment).toString())
        break;
    
      case "AMEX":
        setReceivedAmountAMEX(prev => (parseFloat(prev) + increment).toString())
        break;
    
      case "GIFT":
        setReceivedAmountGTV(prev => (parseFloat(prev) + increment).toString())
        break;
    
      default:
        console.error("Invalid payment type");
    };
  };

  const handleDecimalClick = (type) => {
    switch (type) {
      case "CASH":
        if (!receivedAmount.toString().includes('.')) {
          setReceivedAmount(prev => prev + '.');
      }
        break;
    
      case "COM":
        if (!receivedAmount.toString().includes('.')) {
          setReceivedAmountCOMBANK(prev => prev + '.');
      }
        break;
    
      case "SAM":
        if (!receivedAmount.toString().includes('.')) {
          setReceivedAmountSAMPATH(prev => prev + '.');
      }
        break;
    
      case "AMEX":
        if (!receivedAmount.toString().includes('.')) {
          setReceivedAmountAMEX(prev => prev + '.');
      }
        break;
    
      case "GIFT":
        if (!receivedAmount.toString().includes('.')) {
          setReceivedAmountGTV(prev => prev + '.'); 
      }
        break;

        case "GIFT_NO":
          if (!receivedAmount.toString().includes('.')) {
            setReceivedAmountGTV_NO(prev => prev + '.');
        }
        break;
        
    
      default:
        console.error("Invalid payment type");
    };
    
  };

  const handleCardTypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedCardType(selectedType);
    const relevantItems = discount.filter(item => item.sequence === selectedType);
    setCardDiscount(relevantItems);
  };

  var getTotalPrice = (discountPercent,sequence) => {
    
    let discountAmount = 0;

    if (discount) {
      const amount = parseFloat(discountPercent) || 0;
      discountAmount = (Total/100)*amount;
      
      TotalPrice -=  discountAmount || 0;
    
      discount_Amount += discountAmount || 0;
    }

    return discountAmount.toFixed(2);
  };

  const handleReceivedAmountChange = (value, type) => {
    const amountReceived = parseFloat(value);
    switch (type) {
      case "CASH":
        setReceivedAmount(amountReceived);
        break;
    
      case "COM":
        setReceivedAmountCOMBANK(amountReceived)
        break;
    
      case "SAM":
        setReceivedAmountSAMPATH(amountReceived)
        break;
    
      case "AMEX":
        setReceivedAmountAMEX(amountReceived)
        break;
    
      case "GIFT":
        setReceivedAmountGTV(amountReceived)
        break;
    
      default:
        console.error("Invalid payment type");
    };

    setChangeToReturn(amountReceived - TotalPrice);  
  };
  
  var totalVat = () => { 
    if (Tax) {
      Tax.forEach( (VAT) => {
        if(VAT.taxCode=== "VAT"){
          vat_Total=VAT.taxTotal || 0;}
      });
    }
    return vat_Total;
  };

  var totaltax = () => {
    if (Tax) {
      Tax.forEach((TOT) => {
          Tax_Total += parseFloat(TOT.taxTotal) || 0;
          if(taxes){
            taxes.forEach((TT) => {
            if (TOT.taxCode === TT.taxCode) {
              if (TT.taxListId) {
                  taxIds += taxIds ? `,${TT.taxListId}` : TT.taxListId;
              }
            }
          });
        }
      });
    }
  };

  const colectiveData = async () => {
    var cashAmount =0;
    var cardAmount =0;
    var giftCardNo = '';

    var remarks = [
      receivedAmount,
      receivedAmountCOMBANK,
      receivedAmountSAMPATH,
      receivedAmountAMEX,
      receivedAmountGTV,
    ].join('^');
    

  if (receivedAmount !== 0) {
      cashAmount = parseFloat(receivedAmount);
      if (receivedAmount) {
        discountSequences += discountSequences ? `,${"CASH"}` : "CASH";
      }
  }

  if (receivedAmountCOMBANK !== 0) {
      cardAmount += parseFloat(receivedAmountCOMBANK);
      if (receivedAmountCOMBANK) {
        discountSequences += discountSequences ? `,${"COM"}` : "COM";
      }
  }

  if (receivedAmountSAMPATH !== 0) {
      cardAmount += parseFloat(receivedAmountSAMPATH);
      if (receivedAmountSAMPATH) {
        discountSequences += discountSequences ? `,${"SAM"}` : "SAM";
      }
  }

  if (receivedAmountAMEX !== 0) {
      cardAmount += parseFloat(receivedAmountAMEX);
      if (receivedAmountAMEX) {
        discountSequences += discountSequences ? `,${"AMEX"}` : "AMEX";
      }
  }

  if (receivedAmountGTV !== 0) {
    if (receivedAmountGTV_NO == 0) {
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Please Enter Gift Card Number`,
        confirmButtonColor: '#d33',
        allowOutsideClick: false,
      });
      return;
    }
    cardAmount += parseFloat(receivedAmountGTV);
    giftCardNo = receivedAmountGTV_NO
    if (receivedAmountGTV) {
      discountSequences += discountSequences ? `,${"GIFT"}` : "GIFT";
    }

  }
  
    try {
       
      totaltax();
      totalVat();
        
      let response;
 
      if (takeawayData && isTakeawayData) {
        response = await dishesServices().postColectiveData(
          TotalPrice,
          cashAmount,
          cardAmount,
          paymentMethod,
          discountSequences,
          taxIds,
          discount_Amount,
          Tax_Total,
          vat_Total,
          stall,
          transactionIds,
          selectedExecutiveType,
          remarks,
          giftCardNo,
          note
        );
      } else {
        response = await dishesServices().updateOutletColectiveData(
          TotalPrice,
          cashAmount,
          cardAmount,
          paymentMethod,
          discountSequences,
          taxIds,
          discount_Amount,
          Tax_Total,
          vat_Total,
          stall,
          transactionIds,
          selectedExecutiveType,
          remarks,
          giftCardNo,
          note
        );
      }
 
      if (response) {
        setIsBillPrinted(false);
        setNotification({
          message: "Payment was successful!",
          show: true,
          type: "success",
        });
 
        if (takeawayData && isTakeawayData) {
          try {
            setIsTakeawayData(false);
            printService().sendFullBill(response);
            localStorage.removeItem("TAKEAWAY");
          } catch (printError) {
            console.error("Failed to print full bill:", printError);
          }
        }
      }

    } catch (error) { 
      console.error("Order failed:", error);
      setNotification({
        message: "Order failed. Please try again.",
        show: true,
        type: "error",
      });
    }

  };

  const handleKeyPress = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  }; 

  const fetchDiscount = async () => {
    try {
      const response = await discountService().getAlldiscounts();
      if(response){
        
        setDiscount(response);

        const uniqueCardTypes = [...new Set(response.map(item => item.sequence))];
        setCardTypes(uniqueCardTypes); 
      }

    } catch (error) {
      console.error('Error fetching suppliers data:', error);
    }
  };

  const fetchTaxes = async () => {
    try {
      const response = await taxServices().getAllTax();
      setTaxes(response.data); 
    } catch (error) {
      console.error('Error fetching suppliers data:', error);
    }
  };

  const posKeypad = (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "5px",
        marginTop: "-30px",
      }}
    >
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
        <Button
          key={num}
          onClick={() => handleKeypadClick(num, SelectPaymentMethods)}
          style={{
            padding: "20px",
            fontSize: "1.2em",
            backgroundColor: "#FFB74D", 
            color: "#333", 
            border: "2px solid #000", 
          }}
        >
          {num}
        </Button>
      ))}

      <Button
        onClick={() => handleKeypadClick(0, SelectPaymentMethods)}
        style={{
          padding: "20px",
          fontSize: "1.2em",
          backgroundColor: "#FFB74D",
          color: "#333",
          border: "2px solid #000", 
        }}
      >
        0
      </Button>
      <Button
        onClick={() => handleDecimalClick(SelectPaymentMethods)}
        style={{
          padding: "20px",
          fontSize: "1.2em",
          backgroundColor: "#FFB74D",
          color: "#333", 
          border: "2px solid #000", 
        }}
      >
        .
      </Button>
      <Button
        onClick={() => clearAmount(SelectPaymentMethods)}
        style={{
          padding: "20px",
          fontSize: "1.2em",
          backgroundColor: "#FF7043",
          color: "#333", 
          border: "2px solid #000", 
        }}
      >
        CLR
      </Button>

      <Button
        onClick={() => exactAmount(SelectPaymentMethods)}
        style={{
          padding: "20px",
          fontSize: "1.2em",
          backgroundColor: "#333", 
          color: "#FFB74D", 
          border: "2px solid #FFB74D", 
          gridColumn: "1 / -1",
        }}
      >
        Exact Amount
      </Button>
    </div>
  );

  const amountDisplay = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        marginLeft: "50px",
        marginTop: "20px",
        width: "90%",
        flex: 1,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {Tax &&
          Tax.map((T, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "1px",
                marginTop: "-40px",
              }}
            >
              <span
                style={{
                  fontSize: "1.0em",
                  color: "#555",
                  fontWeight: "600",
                }}
              >
                {T.taxCode}: {T.taxPrese}%
              </span>
              <div
                style={{
                  fontSize: "1.0em",
                  fontWeight: "bold",
                  color: "#333",
                }}
              >
                {T.taxTotal}
              </div>
            </div>
          ))}
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "1px",
          }}
        >
          <span
            style={{
              fontSize: "1.0em",
              color: "#555",
              fontWeight: "600",
              marginTop: "-20px",
            }}
          >
            <select
              value={selectedCardType}
              onChange={handleCardTypeChange}
              style={{
                marginLeft: "auto",
                width: "200px",
                fontSize: "o.9em",
                padding: "5px",
                height: "40px",
              }}
            >
              <option value="" disabled>
                Select Discount
              </option>
              {cardTypes.map((cardType) => (
                <option key={cardType} value={cardType}>
                  {cardType.charAt(0).toUpperCase() + cardType.slice(1)}
                </option>
              ))}
            </select>
            {cardDiscount[0]?.discountPercent} %
          </span>
          <div
            style={{
              fontSize: "1.0em",
              fontWeight: "bold",
              color: "#333",
            }}
          >
            {getTotalPrice(
              cardDiscount[0]?.discountPercent,
              discount[0]?.sequence
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "-20px",
        }}
      >
        <span
          style={{
            fontSize: "1.0em",
            fontWeight: "bold",
            color: "#555",
            fontWeight: "600",
          }}
        >
          Total Amount:
        </span>
        <div
          style={{
            fontSize: "1.0em",
            fontWeight: "bold",
            color: "green",
          }}
        >
          {TotalPrice.toFixed(2)}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "-20px",
        }}
      >
        <span
          style={{
            fontSize: "1.0em",
            fontWeight: "bold",
            color: "#555",
            fontWeight: "600",
          }}
        >
          Balance:
        </span>
        <div
          style={{
            fontSize: "1.0em",
            fontWeight: "bold",
            color: "red",
          }}
        >
          {changeToReturn.toFixed(2)}
        </div>
      </div>
    </div>
  );

  const incrementButtons = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "50px",
        marginTop: "-20px",
        width: "80%",
        flex: 1,
      }}
    >
      {[5000, 1000, 500, 100, 10].map((amount) => (
        <Button
          key={amount}
          onClick={() => handleIncrementClick(amount, SelectPaymentMethods)}
          style={{
            padding: "20px",
            fontSize: "1.2em",
            backgroundColor: "#333", 
            color: "#FFB74D", 
            border: "2px solid #FFB74D", 
            width: "100%",
            marginBottom: "5px",
            borderRadius: "5px", 
          }}
        >
          +{amount}
        </Button>
      ))}
    </div>
  );

  useEffect(() => {
    if (show) {
      fetchDiscount();
      setReceivedAmount(0);
      fetchTaxes(); 
      setCardDiscount([]);
      setSelectedCardType('')
      setstall(false)
      setChangeToReturn(0); 
      setNote("");
      setSelectedExecutiveType("");
      setReceivedAmount(0);
      setReceivedAmountCOMBANK(0);
      setReceivedAmountSAMPATH(0);
      setReceivedAmountAMEX(0);
      setReceivedAmountGTV(0);
    }
  }, [show]);

  useEffect(() => {
    setChangeToReturn((parseFloat(receivedAmount)+
    parseFloat(receivedAmountCOMBANK)+
    parseFloat(receivedAmountSAMPATH)+
    parseFloat(receivedAmountAMEX)+
    parseFloat(receivedAmountGTV)) - TotalPrice); 
  }, [receivedAmount,receivedAmountCOMBANK,receivedAmountSAMPATH,receivedAmountAMEX,receivedAmountGTV, TotalPrice]);

  useEffect(() => {
    setRecivedTotalAmount(parseFloat(receivedAmount)+
    parseFloat(receivedAmountCOMBANK)+
    parseFloat(receivedAmountSAMPATH)+
    parseFloat(receivedAmountAMEX)+
    parseFloat(receivedAmountGTV)); // Update changeToReturn when receivedAmount changes
  }, [receivedAmount,receivedAmountCOMBANK,receivedAmountSAMPATH,receivedAmountAMEX,receivedAmountGTV]);

  return (
    <>
      <ToastContainer />
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        fullscreen={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{ padding: "20px", backgroundColor: "#f9f9f9" }}
          closeButton
        >
          <div
            style={{
              fontFamily: "Arial, sans-serif",
              lineHeight: "2",
              display: "flex",
              justifyContent: "space-between",
              fontSize: "1.5em",
            }}
          >
            <div style={{ flex: 1, marginRight: "20px" }}>
              <div>
                <style>
                  {`
                    .custom-table {
                      width: 100%;
                      font-size: 1.5rem;
                      border-collapse: collapse;
                      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                      overflow: hidden;
                    }

                    .custom-table th {
                      background-color: #FFB74D; 
                      color: #333; 
                      text-align: center;
                      padding: 10px;
                      border: 1px solid #333;  
                    }

                    .custom-table td {
                      padding: 15px;
                      border: 1px solid #333;  
                      text-align: center;
                    }

                    .custom-table tr:nth-child(odd) {
                      background-color: #FFF3E0;  
                    }

                    .custom-table tr:nth-child(even) {
                      background-color: #FFE0B2;  
                    }

                    .custom-table tr:hover {
                      background-color: #FFB74D;  
                      color: #333;  
                      border-color: #333;  
                    }

                    .custom-table input {
                      font-size: 1.4rem;
                      padding: 5px;
                      border: 1px solid #FFB74D;  
                      border-radius: 5px;
                      background-color: #FFF3E0; 
                      color: #333;  
                    }

                    .custom-table input:focus {
                      outline: none;
                      border-color: #FFB74D;  
                      box-shadow: 0 0 5px rgba(255, 183, 77, 0.5);  
                    }

                    .theme-button {
                      font-size: 1.2rem;
                      padding: 10px 20px;
                      border: 1px solid #333;  
                      border-radius: 8px;
                      background-color: #FFB74D;  
                      color: #333;  
                      cursor: pointer;
                      transition: background-color 0.3s, transform 0.2s;
                    }

                    .theme-button:hover {
                      background-color: #FFE0B2;  
                      color: #333;  
                      transform: scale(1.05);  
                    }

                    .theme-button:disabled {
                      background-color: #FFF3E0;  
                      color: #666;  
                      cursor: not-allowed;
                    }

                    textarea {
                      border: 1px solid #FFB74D; 
                      border-radius: 5px;
                      padding: 10px;
                      font-size: 1rem; 
                      color: #333;  
                    }

                    textarea:focus {
                      outline: none;
                      border-color: #FFB74D;  
                      box-shadow: 0 0 5px rgba(255, 183, 77, 0.5);  
                    }
                  `}
                </style>

                <div className="table-responsive">
                  <table className="table custom-table text-center">
                    <thead className="table-header">
                      <tr>
                        <th>Payment Type</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="table-row">
                        <td>CASH</td>
                        <td>
                          <input
                            type="text"
                            readOnly
                            className="form-control custom-input text-center"
                            value={receivedAmount ? receivedAmount : ""}
                            onChange={(e) =>
                              handleReceivedAmountChange(e.target.value, "CASH")
                            }
                            onKeyPress={handleKeyPress}
                            onClick={() => setSelectPaymentMethods("CASH")}
                          />
                        </td>
                      </tr>
                      <tr className="table-row">
                        <td>COMMERCIAL</td>
                        <td>
                          <input
                            type="text"
                            readOnly
                            className="form-control custom-input text-center"
                            value={
                              receivedAmountCOMBANK ? receivedAmountCOMBANK : ""
                            }
                            onChange={(e) =>
                              handleReceivedAmountChange(e.target.value, "COM")
                            }
                            onClick={() => setSelectPaymentMethods("COM")}
                          />
                        </td>
                      </tr>
                      <tr className="table-row">
                        <td>SAMPATH</td>
                        <td>
                          <input
                            type="text"
                            readOnly
                            className="form-control custom-input text-center"
                            value={
                              receivedAmountSAMPATH ? receivedAmountSAMPATH : ""
                            }
                            onChange={(e) =>
                              handleReceivedAmountChange(e.target.value, "SAM")
                            }
                            onKeyPress={handleKeyPress}
                            onClick={() => setSelectPaymentMethods("SAM")}
                          />
                        </td>
                      </tr>
                      <tr className="table-row">
                        <td>AMEX</td>
                        <td>
                          <input
                            type="text"
                            readOnly
                            className="form-control custom-input text-center"
                            value={receivedAmountAMEX ? receivedAmountAMEX : ""}
                            onChange={(e) =>
                              handleReceivedAmountChange(e.target.value, "AMEX")
                            }
                            onKeyPress={handleKeyPress}
                            onClick={() => setSelectPaymentMethods("AMEX")}
                          />
                        </td>
                      </tr>
                      <tr className="table-row">
                        <td>GIFT VOUC</td>
                        <td>
                          <input
                            type="text"
                            readOnly
                            className="form-control custom-input text-center"
                            value={receivedAmountGTV ? receivedAmountGTV : ""}
                            onChange={(e) =>
                              handleReceivedAmountChange(e.target.value, "GIFT")
                            }
                            onKeyPress={handleKeyPress}
                            onClick={() => setSelectPaymentMethods("GIFT")}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <textarea
                  id="note"
                  name="note"
                  placeholder="Enter note here..."
                  rows="3"
                  value={note}
                  onChange={handleNoteChange}
                  style={{
                    width: "80%",
                    padding: "10px",
                    fontSize: "0.9em",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    resize: "none",
                  }}
                ></textarea>
                <input
                  type="text"
                  readOnly
                  className="form-control text-center"
                  placeholder="Gift Card No"
                  style={{ width: "20%" }}
                  value={receivedAmountGTV_NO ? receivedAmountGTV_NO : ""}
                  onChange={(e) =>
                    handleReceivedAmountChange(e.target.value, "GIFT_NO")
                  }
                  onKeyPress={handleKeyPress}
                  onClick={() => setSelectPaymentMethods("GIFT_NO")}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                padding: "20px",
              }}
            >
              <div style={{ marginBottom: "10px" }}>{amountDisplay}</div>

              <div style={{ display: "flex", gap: "20px" }}>
                <div style={{ display: "flex", gap: "20px" }}>
                  <div style={{ flexBasis: "200px", marginTop: "-10px" }}>
                    {incrementButtons}
                  </div>
                  <div style={{ flexBasis: "300px", marginTop: "0" }}>
                    {posKeypad}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setstall((prev) => !prev)}
            style={{ backgroundColor: stall ? "green" : "red", color: "white" }}
            hidden={true}
          >
            VAT FREE
          </Button>
          <Button
            onClick={() => {
              colectiveData();
              onHide();
              finish();
            }}
            style={{
              fontSize: "1.5rem",
              padding: "15px 30px",
              borderRadius: "8px",
              color: "#fff",
              border: "none",
              cursor: "pointer",
            }}
            disabled={recivedTotalAmount === 0 || changeToReturn < 0}
          >
            PAY
          </Button>
          <Button
            onClick={() => {
              onHide();
            }}
            style={{
              fontSize: "1.5rem",
              padding: "15px 30px",
              borderRadius: "8px",
              backgroundColor: "red",
              color: "#fff",
              border: "none",
              cursor: "pointer",
            }}
          >
            EXIT
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={notification.show}
        onHide={() => setNotification({ ...notification, show: false })}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div style={{ display: "flex", alignItems: "center" }}>
              {notification.type === "success" ? (
                <>
                  <FaCheckCircle
                    size={24}
                    style={{ color: "green", marginRight: "12px" }}
                  />
                  <span>Payment Successful</span>
                </>
              ) : (
                <>
                  <FaExclamationCircle
                    size={24}
                    style={{ color: "red", marginRight: "12px" }}
                  />
                  <span>An Error Occurred</span>
                </>
              )}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{notification.message}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setNotification({ ...notification, show: false })}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OutletMultiPaymentModal;
