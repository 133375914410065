import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import dishesServices from "../../api/DishesServices/disheseService.js";
import Swal from "sweetalert2";
import { toast, ToastContainer } from 'react-toastify';
import CenteredModal from "./customerOder/CenteredModal.js";
import PosHeader from '../../components/topbar/posHeader.jsx';

function CashierCheckout() {
  const [summaryData, setSummaryData] = useState([]);
  const [exitedRows, setExitedRows] = useState({});
  const [modalShow, setModalShow] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const moment = require("moment");
  
  const cashier = async () => {
    try {
      setIsLoading(true);
      const response = await dishesServices().getAllCashier();
      const response2 = await dishesServices().getLogDetails();

      const cashierData = response.data;
      const originalLogData = response2.data;

      // Initialize exited rows from log details based on ExitStatus
      const exitedStatus = {};
      originalLogData.forEach((log) => {
        if (log.exitStatus === "Exited") {
          exitedStatus[log.logId] = true;
        }
      });
      setExitedRows(exitedStatus);

      // Filter out logs with invalid logout times if necessary
      const logData = originalLogData.filter(
        (log) => log.loginOutTime !== null
      );

      const processedData = [];

      logData.forEach((log) => {
        const { employeeID, floatValue, loginTime, loginOutTime } = log;
        const loginDate = new Date(loginTime);
        const logoutDate = new Date(loginOutTime);

        const employeeTransactions = cashierData.filter((transaction) => {
          const transactionDate = new Date(transaction.tStamp);
          return (
            transaction.employeeID === employeeID &&
            transactionDate >= loginDate &&
            transactionDate <= logoutDate
          );
        });

        if (employeeTransactions.length > 0) {
          const stalls = {};

          employeeTransactions.forEach((item) => {
            const { stallId, cashAmount, cardAmount, totalBill, paymentMethod } = item;

            if (!stalls[stallId]) {
              stalls[stallId] = { CARD: 0, CASH: 0 };
            }
      
            if (paymentMethod === "MULTY") {
              if (cashAmount === 0) {
                stalls[stallId].CARD += cardAmount;
              } else if (cardAmount === 0) {
                stalls[stallId].CASH += cashAmount;
              } else {
                stalls[stallId].CARD += cardAmount;
                stalls[stallId].CASH += totalBill - cardAmount;
              }
            } else {
              stalls[stallId].CARD += cardAmount;
              stalls[stallId].CASH += cashAmount;
            }
          });

          Object.keys(stalls).forEach((stallId) => {
            processedData.push({
              employeeID,
              stallId,
              floatValue,
              CARD: stalls[stallId].CARD,
              CASH: stalls[stallId].CASH,
              loginTime: loginTime,
              logoutTime: loginOutTime,
              logId: log.logId,
            });
          });
        }
      });

      setSummaryData(processedData);
      console.log("Processed Data:", processedData);
    } catch (error) {
      if (error.response) {
        console.error(
          "Server error:",
          error.response.status,
          error.response.data
        );
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error:", error.message);
      }
      console.error("Error stack trace:", error.stack);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    cashier();
  }, []);

  const onExit = async (logId, employeeID) => {
    const confirmation = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to mark this cashier as exited?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, exit!",
    });

    if (!confirmation.isConfirmed) return;

    try {
      await dishesServices().handleExit(logId, employeeID);
      Swal.fire("Exited!", "Cashier has been marked as exited.", "success");

      // Mark this row as exited
      setExitedRows((prev) => ({
        ...prev,
        [logId]: true,
      }));
    } catch (error) {
      console.error("Error during exit:", error);
      Swal.fire("Error", "Exit failed. Please try again.", "error");
    }
  };

  const [posMobileSidebarToggled, setPosMobileSidebarToggled] = useState(false);
  const formatCurrency = (amount) => {
    if (isNaN(amount) || amount === null || amount === undefined) {
      return "0.00";
    }
    return `${amount.toFixed(2).toLocaleString()}`;
  };

  const formatTime = (amount) => {
    if (!amount) return "Invalid Time";
    return moment(amount).format("YYYY-MM-DD HH:mm:ss");
  };

  const loaderStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  };
  
  return (
    <>
<CenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
		<ToastContainer />
    
    <div className={'pos pos-vertical'  }>
    <div className="pos-container">
    {isLoading && (
              <div style={loaderStyle}>
                <div className="spinner-border text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
    <div >
				<PosHeader />
        <div className="nav-container">
			
    <div>
      <style>
        {`
          .table-container {
              padding: 20px;
              background-color: rgba(255, 255, 255, 0.8);
              backdrop-filter: blur(10px);
              border-radius: 8px;
              box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          }

          .table {
              width: 100%;
              border-collapse: collapse;
              background: transparent;
          }

          .table th,
          .table td {
              padding: 12px;
              text-align: left;
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }

          .table th {
              background-color: rgba(240, 240, 240, 0.5);
          }

          .table-row:hover {
              background-color: rgba(230, 230, 230, 0.3);
          }

          .exit-button {
              background-color: #f44336; /* Red */
              color: white;
              border: none;
              padding: 8px 12px;
              border-radius: 4px;
              cursor: pointer;
          }

          .exit-button:hover {
              background-color: #d32f2f; /* Darker red on hover */
          }

          .exited-button {
              background-color: #4caf50; /* Green */
              color: white;
              border: none;
              padding: 8px 12px;
              border-radius: 4px;
              cursor: not-allowed;
          }
        `}
      </style>
      <div className="table-container">
        <h2>Cashier Checkout Summary</h2>
        <br></br>
        <PerfectScrollbar >
          <table className="table">
            <thead>
              <tr>
                <th>Cashier ID</th>
                <th>Stall ID</th>
                <th>Card Transactions(RS)</th>
                <th>Float Value(RS)</th>
                <th>Cash Transactions(RS)</th>
                <th>Cash In Hand(RS)</th>
                <th>LogIn Time</th>
                <th>LogOut Time</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {summaryData
                .slice()
                .sort((a, b) => new Date(b.logoutTime) - new Date(a.logoutTime))
                .map((data, index) => (
                  <tr key={index} className="table-row">
                    <td>{data.employeeID}</td>
                    <td>{data.stallId}</td>
                    <td>{formatCurrency(data.CARD)}</td>
                    <td>{formatCurrency(data.floatValue)}</td>
                    <td>{formatCurrency(data.CASH)}</td>
                    <td>{formatCurrency(data.floatValue + data.CASH)}</td>
                    <td>{formatTime(new Date(data.loginTime))}</td>
                    <td>{formatTime(new Date(data.logoutTime))}</td>
                    <td>
                      {exitedRows[data.logId] ? (
                        <button className="exited-button" disabled>
                          Exited
                        </button>
                      ) : (
                        <button
                          className="exit-button"
                          onClick={() => onExit(data.logId, data.employeeID)}
                        >
                          Exit
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </PerfectScrollbar>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </>
  );
}

export default CashierCheckout;
