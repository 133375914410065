import React from 'react';

function LayoutStarter() {
	return (
		<div>
			<ul className="breadcrumb">
				<li className="breadcrumb-item"><a href="#/">LAYOUT</a></li>
				<li className="breadcrumb-item active">STARTER PAGE</li>
			</ul>
		
			<h1 className="page-header">
				Starter Page <small>page header description goes here...</small>
			</h1>
		
			<p>
				Start build your page here
			</p>
		</div>
	)
}

export default LayoutStarter;