import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import dishesServices from "../../../api/DishesServices/disheseService";
import printService from "../../../api/PrintServices/printService";
import Swal from "sweetalert2";
import { displayName } from "react-quill";

const OpenCashierExitModal = ({ isOpen, onClose, summaryData }) => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedSummary, setSelectedSummary] = useState(null);

  const formatDate = (date) => (date ? new Date(date).toLocaleString() : "N/A");

  const formatCurrency = (amount) => {
    if (isNaN(amount) || amount === null || amount === undefined) {
      return "0.00";
    }
    return `${amount.toFixed(2).toLocaleString()}`;
  };

  useEffect(() => {
    if (isOpen) {
      setFormData({ username: "", password: "" });
      setErrors({});
      setSelectedSummary(summaryData[0] || null);
    } else {
      setSelectedSummary(null);
    }
  }, [isOpen, summaryData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleAccountantValidation = async (e) => {
    e.preventDefault();
    setErrors({});
    setIsSubmitting(true);

    const { username, password } = formData;
    if (!username.trim() || !password) {
      setErrors({
        username: !username.trim() ? "Username is required" : "",
        password: !password ? "Password is required" : "",
      });
      setIsSubmitting(false);
      return;
    }

    try {
      const validationResult = await dishesServices().validAccountant(
        username,
        password
      );

      if (!validationResult.success) {
        Swal.fire("Error", "Invalid username or password", "error");
        return;
      }

      if (selectedSummary) {
        const formattedData = {
          cashierID: selectedSummary.employeeID,
          displayName: selectedSummary.displayName,
          stallID: selectedSummary.stallId,
          cardTransactions: selectedSummary.CARD,
          floatValue: selectedSummary.floatValue,
          cashTransactions: selectedSummary.CASH,
          cashInHand: selectedSummary.floatValue + selectedSummary.CASH,
          loginTime: selectedSummary.loginTime,
          logoutTime: selectedSummary.logoutTime,
        };
  
        try {
          await printService().sendCashierExitData(formattedData);
          console.log("Success", "Cashier exit data sent successfully!", "success");
        } catch (error) {
          console.error("Failed to send cashier exit data:", error);
        }
  
        // Proceed with logout even if the sendCashierExitData fails
        await dishesServices().handleExit(selectedSummary.logId, selectedSummary.employeeID);
        onClose();
        localStorage.clear();
        Swal.fire({
          icon: "info",
          title: "Logged Out",
          text: "You have been logged out.",
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          navigate("/login");
        });
      } else {
        Swal.fire("Error", "No summary data available", "error");
      }
    } catch (error) {
      console.error("Validation error:", error);
      Swal.fire("Error", "An error occurred during validation", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h3>Cashier Exit</h3>
        <p>
          Confirm the cashier exit details and validate with accountant
          credentials.
        </p>
        <form onSubmit={handleAccountantValidation}>
          <div className="form-group">
            <label htmlFor="username">Accountant Username:</label>
            <input
              id="username"
              name="username"
              type="text"
              value={formData.username}
              onChange={handleInputChange}
              className={errors.username ? "error" : ""}
              disabled={isSubmitting}
            />
            {errors.username && (
              <span className="error-message">{errors.username}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="password">Accountant Password:</label>
            <input
              id="password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleInputChange}
              className={errors.password ? "error" : ""}
              disabled={isSubmitting}
            />
            {errors.password && (
              <span className="error-message">{errors.password}</span>
            )}
          </div>

          <div className="summary-details">
            <h4>Cashier Exit Summary:</h4>
            {selectedSummary ? (
              <ul>
                <li><strong>Cashier Name:</strong> {selectedSummary.displayName}</li>
                <li><strong>Stall ID:</strong> {selectedSummary.stallId}</li>
                <li><strong>Card Transactions:</strong> {formatCurrency(selectedSummary.CARD)}</li>
                <li><strong>Cash Transactions:</strong> {formatCurrency(selectedSummary.CASH)}</li>
                <li><strong>Float Value:</strong> {formatCurrency(selectedSummary.floatValue)}</li>
                <li><strong>Login Time:</strong> {formatDate(selectedSummary.loginTime)}</li>
                <li><strong>Logout Time:</strong> {formatDate(selectedSummary.logoutTime)}</li>
              </ul>
            ) : (
              <p>No summary available.</p>
            )}
          </div>

          <div className="button-group">
            <button
              type="submit"
              className="submit-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Validating..." : "Submit"}
            </button>
          </div>
        </form>
      </div>

      <style jsx>{`
        .modal-backdrop {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999;
        }

        .modal-content {
          background: white;
          padding: 30px;
          border-radius: 12px;
          width: 500px;
          max-width: 90%;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
        }

        .form-group {
          margin-bottom: 20px;
        }

        label {
          display: block;
          margin-bottom: 8px;
          font-weight: 500;
          color: #333;
        }

        input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 6px;
          transition: border-color 0.3s;
        }

        input:focus {
          outline: none;
          border-color: #7b7b7b;
          box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
        }

        input.error {
          border-color: #dc3545;
        }

        .error-message {
          color: #dc3545;
          font-size: 0.875rem;
          margin-top: 4px;
          display: block;
        }

        .summary-details {
          background: #f8f9fa;
          padding: 15px;
          border-radius: 6px;
          margin-bottom: 20px;
        }

        .summary-details h4 {
          margin-bottom: 10px;
          font-size: 1.1rem;
        }

        ul {
          list-style: none;
          padding: 0;
        }

        ul li {
          margin-bottom: 8px;
          font-size: 0.95rem;
        }

        .button-group {
          display: flex;
          gap: 10px;
          justify-content: flex-end;
        }

        button {
          padding: 10px 20px;
          border-radius: 6px;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.3s;
        }

        .submit-button {
          background-color: #F57C00;
          color: white;
          border: none;
        }

        .submit-button:hover:not(:disabled) {
          background-color: #dd6800;
        }

        button:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      `}</style>
    </div>
  );
};

export default OpenCashierExitModal;
