import React, { useEffect, useContext, useState, useRef } from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import Form from 'react-bootstrap/Form';
import { AppSettings } from '../../../config/app-settings.js';
import { Modal } from 'bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dishesServices from '../../../api/DishesServices/disheseService.js';
import taxServices from '../../../api/TaxServices/taxServices.js';
import { useLocation } from "react-router-dom"; 
import printService from '../../../api/PrintServices/printService.js';
import PosHeader from '../../../components/topbar/posHeader.jsx';
import tableServices from '../../../api/TableServices/tableServices.js';
import OpenItemModal from "./OpenItemModal.jsx";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import MenuItemsAccessPermissions from "../../../enums/menuItemsAccessPermissions.js";


function PosOutletCustomerOrder() {

	const location = useLocation();
	const context = useContext(AppSettings);
	const navigate = useNavigate();
 
	const [tableData, setTableData] = useState([]);
	const [allMenuItems, setAllMenuItems] = useState([]);
	const [orderData, setOrderData] = useState([]);  
	const [taxData, setTaxData] = useState([]);  
	const [modalSelectedAddon, setModalSelectedAddon] = useState([]);
	const [voidReasonData, setVoidReasonData] = useState([]);
	const [outletTables, setOutletTables] = useState([]); 
	const [groups, setGroups] = useState([]);

	const [voidReason, setVoidReason] = useState("");
	const [managerUsername, setManagerUsername] = useState("");
	const [managerPassword, setManagerPassword] = useState(""); 
	const [selectedTableId, setSelectedTableId] = useState("");
	const [errorMessage, setErrorMessage] = useState(""); 

	const [modalData, setModalData] = useState();
	const [modalQuantity, setModalQuantity] = useState();
	const [modal, setModal] = useState(); 

	const [openItems, setOpenItems] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	
	const handleModalOpen = () => setIsModalOpen(true);
	const handleModalClose = () => setIsModalOpen(false);
	 
	const [status, setStatus] = useState(false);
	const [posMobileSidebarToggled, setPosMobileSidebarToggled] = useState(false); 
	const [stall, setstall] = useState(false);      
	const [isVoidData, setIsVoidData] = useState(false);
	const [isEmptyVoidData, setIsEmptyVoidData] = useState(false);   
  	const [isLoading, setIsLoading] = useState(false);  
  	const [isSubmitting, setIsSubmitting] = useState(false);  
	
  	const [tableIds, setLocalTableId] = useState("NO");
	const [tableStatus, setTableStatus] = useState("FALSE");
	const [categoryType, setCategoryType] = useState("all");
	const [orderType, setOrderType] = useState("DINING");
    
	const [total, setTotal] = useState(0);
	const [totalPrice, setTotalPrice] = useState(0);
  	const [transactionId, setTransactionId] = useState([]); 

	const [notification, setNotification] = useState({ message: '', show: false, type: '' });
	const [confirmationVisibility, setConfirmationVisibility] = React.useState(() => {
		const visibility = {};
		orderData?.forEach((order) => {
			visibility[order.id] = false; 
		});
		return visibility;
	});
	 
	const modalInstanceRef = useRef(null);

	const voidData = JSON.parse(localStorage.getItem("voidbill") || "{}");

	const stallId = localStorage.getItem("stallID");
	const [selectedButton, setSelectedButton] = useState(stallId);

	const stallIdInt = parseInt(stallId, 10);
	
	const hasPermission = (stalls) => stalls.includes(stallId);

	const [showTableOrders, setShowTableOrders] = useState(false);
    const [currentTableOrders, setCurrentTableOrders] = useState([]);
    const [selectedTableForModal, setSelectedTableForModal] = useState(null);
	const [isFirstSelection, setIsFirstSelection] = useState(true);

	
	const handleTableClick = async (tableId) => {
		try {
			
			const serverResponse = await dishesServices().getAllTableOreders(tableId);
			
			if (serverResponse && serverResponse.length > 0) {
			const currentCartOrders = orderData.filter(order => order.tableId === tableId);
	
			const submittedOrders = serverResponse.flatMap(order => 
				order.transactionItems?.map(item => {
					
					const itemTaxTotal = item.taxItems?.reduce((sum, tax) => 
						sum + (parseFloat(tax.taxValue) || 0), 0) || 0;
	
					return {
						itemName: item.itemName,
						quantity: item.quantity,
						statusChange: order.statusChange || 'INPROGRESS',
						itemPrice: item.itemPrice,
						taxAmount: itemTaxTotal,
						addOns: item.addOns || []
					};
				}) || []
			);
	
			const processedCartOrders = currentCartOrders.map(order => {
				const itemTaxTotal = order.taxItems?.reduce((sum, tax) => 
					sum + (parseFloat(tax.taxValue) || 0), 0) || 0;
	
				return {
					itemName: order.itemName,
					quantity: order.quantity,
					statusChange: 'NEW',
					itemPrice: order.itemPrice,
					taxAmount: itemTaxTotal,
					addOns: order.addOns || []
				};
			});
	
			const allOrders = [...processedCartOrders, ...submittedOrders];
	        
			if (allOrders.length > 0) {
			setCurrentTableOrders(allOrders);
			setSelectedTableForModal(tableId);
			setShowTableOrders(true);
			}
		 }
		} catch (error) {
			console.error('Error fetching table orders:', error);
		}
	};

	let isVoidBillData = location.state?.fromOngoingOrders;
 
	var SubTotal = 0;
	var TotalTaxesPrice = 0;
	var TotalPrice = 0;
	var TaxesPrice = 0;

	const loaderStyle = {
			position: 'fixed',
			top: 0,
			left: 0,
			width: '100vw',
			height: '100vh',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
			zIndex: 1000,
	};


	var toggleMobileSidebar = (event) => {
		event.preventDefault();
		setPosMobileSidebarToggled(true);
	}

	var toggleConfirmation = (event, id, value) => {
		event.preventDefault();
		
		if (orderData) {
			const newData = orderData.map(obj => {
				if (obj.id === id) {
					return {...obj, confirmation: value};
				}
				return obj;
			});

			setTotal(0);
			setOrderData(newData);
		}
	}
	
	var dismissMobileSidebar = (event) => {
		event.preventDefault();
		setPosMobileSidebarToggled(false);
	}
	
	const showType = (event, subCategory) => {
		event.preventDefault();
		if (tableData) {
			const updatedTableData = tableData.map(item => ({
				...item,
				hide: subCategory !== 'all' && item.subCategory !== subCategory
			}));
			 
			setTableData(updatedTableData);
			setCategoryType(subCategory);
		}
	}
	
	var showPosItemModal = (event, data) => {
		event.preventDefault();
		var taxes = [];
		var totalTaxInd = 0;
	
		if (data && taxData) {
			taxData?.forEach((tax) => {
				const taxPercentage = parseFloat(tax.taxRate) || 0;
				const price = parseFloat(data.price) || 0;
	
				const option1 = {
					"taxItemId": 0,
					"orderItemId": 0,
					"acumaticaTaxId": 0,
					"taxCode": tax.taxCode,
					"taxType": tax.taxType,
					"taxPercentage": taxPercentage,
					"taxValue": (price / 100) * taxPercentage
				};
	
				totalTaxInd += (price / 100) * taxPercentage;
				taxes.push(option1);
			});
		    
			if (data.menuItemAddOns.length === 0) {
				
					setTotal(0);
					setOrderData((prevOrderData) => {
						const dataExists = prevOrderData.some(
							(obj) => obj.menuItemId === data.menuItemId
						);
	
						if (dataExists) {
							addaQty(event, data.menuItemId);
							return prevOrderData;
						} else {
							return [
								...prevOrderData,
								{
									orderItemId: 0,
									transactionID: 0,
									menuItemId: data.menuItemId,
									itemName: data.title,
									quantity: 1,
									taxItems: taxes,
									itemPrice: data.price,
									addOns: [],
									addOnTotal: 0,
									createdByScreenID: "NEED-CLARIFICATION-FROM-ACU",
									id: uuidv4(),
									stallID: data.stallID,
									tableId: selectedTableId 
								},
							];
						}
					});
			
			} else {
				setModalData(data);
				setModalQuantity(1);
				setModalSelectedAddon([]);
				modal.show();
			}
		}
	};
	 
	var deductQty = (event, id) => {
		event.preventDefault();
		
		if (orderData) {
			const newData = orderData.map(obj => {
				if (obj.id === id) {
					var newQty = parseInt(obj.quantity) - 1;
					
					if (newQty < 1) {
						newQty = 1;
					}
					return {...obj, quantity: newQty};
				}
				
				return obj;
			});

			setTotal(0);
			setOrderData(newData);
		}
	}
	
	var addQty = (event, id) => {
		event.preventDefault();
		
		if (orderData) {
			const newData = orderData.map(obj => {
				if (obj.id === id) {
					var newQty = parseInt(obj.quantity) + 1;
					return {...obj, quantity: newQty};
				}
				
				return obj;
			});
			
			setTotal(0);
			setOrderData(newData);
		}
	}

	var addModalQty = (event) => {
		event.preventDefault();
		if (modalQuantity) {
			var newQty = parseInt(modalQuantity) + 1;
			
			setModalQuantity(newQty);
		}
	}

	const handleQtyChange = (event, orderId) => {
		const newQuantity = event.target.value;
		if (/^\d*$/.test(newQuantity)) {
		setTotal(0);
		setOrderData((prevOrders) =>
		  prevOrders.map((order) =>
			order.id === orderId
			  ? { ...order, quantity: Math.max(1, newQuantity) }
			  : order
		  )
		);
		}
	};

	var addaQty = (event, menuItemId) => {
		event.preventDefault();
		
		if (orderData) {
			const newData = orderData.map(obj => {
				if (obj.menuItemId === menuItemId) {
					var newQty = parseInt(obj.quantity) + 1;
					return {...obj, quantity: newQty};
				}
				
				return obj;
			});
			setTotal(0);
			setOrderData(newData);
		}
	}

	var deductModalQty = (event) => {
		event.preventDefault();
		
		if (modalQuantity) {
			var newQty = parseInt(modalQuantity) - 1;
		
			if (newQty < 1) {
				newQty = 1;
			}
			setModalQuantity(newQty);
		}
	}

	var getOrderTotal = () => {
		return (orderData) ? orderData.length : 0;
	}
	
	var getSubTotalPrice = () => {
		let subTotal = 0;
	
		if (orderData) {
			orderData?.forEach(order => {
				const itemPrice = parseFloat(order.itemPrice) || 0;
				const addOnTotal = parseFloat(order.addOnTotal) || 0;
				const quantity = parseInt(order.quantity) || 0;
	
				subTotal += (itemPrice + addOnTotal) * quantity;
			});
			SubTotal += subTotal;
		}
		 
		return subTotal.toFixed(2);
	};
	
	var getTaxesPrice = (taxType) => {
		var TaxesPrice = 0;
		 
		orderData?.forEach( (order) => {
			
			order?.taxItems?.forEach( (tax) => {
			if(tax.taxType == taxType){
				TaxesPrice += (tax.taxValue)*order.quantity
			}
			});
		});
		TotalTaxesPrice += TaxesPrice;
		 
		return TaxesPrice.toFixed(2);
	}
	 
	var removeOrder = (event, id) => {
		event.preventDefault();
		
		if (orderData) {
			const newData = orderData.filter(function(order) { 
				return order.id !== id
			});
			setTotal(0);
			setOrderData(newData);
 
		}
	}
	 
	var getTotalDishPrice = (order) => {
		let totalPrice = 0;
	
		if (stall) {
			totalPrice = (Math.round((
				order.itemPrice + 
				order.addOnTotal + 
				order.taxItems.reduce((acc, tax) => acc + parseFloat(tax.taxValue || 0), 0)) * order.quantity));
		} else {
			totalPrice = (order.itemPrice + order.addOnTotal) * order.quantity;
		}
	
		return totalPrice.toFixed(2);
	};
 
	var handleAddonChange = (event) => {
		var elms = [].slice.call(document.querySelectorAll('input[name="addon"]'));
		var targetValue = [];
		
		elms.map(function(elm) {
			if (elm.checked) {
				targetValue.push({
					value: elm.value,
					price: elm.getAttribute('data-price')
				});
			}
			return true;
		});
		
		setModalSelectedAddon(targetValue);
	}

	const openModal = () => {
		const modalElement = document.getElementById("modalVoidItem");
		modalInstanceRef.current = new Modal(modalElement);
		modalInstanceRef.current.show();
	};

	const openCancelOrderModal = () => {
		const modalElement = document.getElementById("cancelOrderModal");
		modalInstanceRef.current = new Modal(modalElement);
		modalInstanceRef.current.show();
	};

	const closeModal = () => {
		if (modalInstanceRef.current) {
			modalInstanceRef.current.hide();
		}
	
		setVoidReason("");
		setManagerUsername("");
		setManagerPassword("");
		setErrorMessage("");
	};

	const handleSubmitOrder = async () => { 

		const validationResult = await dishesServices().validManager(managerUsername, managerPassword);

		if (!validationResult.success) {  
			setErrorMessage(validationResult.message);
			return;  
		}
	
		setErrorMessage("");

		try { 
			sendVoidData();
		} catch (error) { 
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Void order failed. Please try again.',
				confirmButtonColor: '#d33',
				allowOutsideClick: false,
			})
		}

	};
 
	const handleCancelOrder = async () => { 

		const validationResult = await dishesServices().validManager(managerUsername, managerPassword);

		if (!validationResult.success) { 
			setErrorMessage(validationResult.message);
			return;  
		}
	
		setErrorMessage(""); 

		try { 
			sendCancelData();
		} catch (error) { 
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Void order failed. Please try again.',
				confirmButtonColor: '#d33',
				allowOutsideClick: false,
			})
		}
		 
	};

	const submitOrder = (event) => {
		event.preventDefault();   
		sendOutletData(); 
	};

	const sendCancelData = async () => { 
		try {  
			await dishesServices().updateOutletTransactionsStatusToVoid(
				transactionId,
				voidReason,
				managerUsername
			);
	
				setIsVoidData(false);
				setOrderData([]); 
				setTransactionId([]);
				setModalSelectedAddon([]); 
		
				if (voidData) {
					localStorage.removeItem("voidbill");
				}
		
				SubTotal = 0;
				TotalTaxesPrice = 0;
				TaxesPrice = 0;
					
				setTotalPrice(0);
				setSelectedTableId(0);
				setLocalTableId("NO"); 

				navigate(`/pos/counter-checkout-outlet`);
		  
		} catch (error) {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Order failed. Please try again.',
				confirmButtonColor: '#d33',
				allowOutsideClick: false,			
			})
		}
	};

	const sendVoidData = async () => {
		localStorage.removeItem("TAKEAWAY"); 
		if (!orderData || orderData.length === 0) {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Order data is empty. Please add items to your order.',
				confirmButtonColor: '#d33',
				allowOutsideClick: false,
			});
			return;
		}

		try { 
			await dishesServices().updateOutletTransactionsStatusToVoid(
				transactionId,
				voidReason,
				managerUsername
			);

			try { 
				const groupedOrders = orderData?.reduce((groups, item) => {
					(groups[item.stallID] = groups[item.stallID] || []).push(item);
					return groups;
				}, {});

				let allResponses = [];
				let lastStallIdProcessed = null;  
	
				for (const stallId in groupedOrders) {
					if (Object.hasOwnProperty.call(groupedOrders, stallId)) {
						const stallOrderData = groupedOrders[stallId];

						let outletSubTotal = stallOrderData.reduce((total, item) => { 
							return total + item.itemPrice * item.quantity;
						}, 0);

						let TaxesPrice = 0;

						stallOrderData?.forEach((order) => {
							order?.taxItems?.forEach((tax) => { 
								const taxType = tax?.taxType;  
								if (taxType === tax?.taxType) {
									TaxesPrice += tax.taxValue * order.quantity;
								}
							});
						});

						let outletTotalTax = TaxesPrice;
						let outletTotal = outletSubTotal + outletTotalTax;
						let voidFrom = stallOrderData[0].transactionID; 
						let voidedStall = stallOrderData[0].stallID;

						try {
							const response = await dishesServices().postOutletVoidData(
								stallOrderData,
								outletTotal,
								outletTotalTax,
								outletSubTotal,
								voidFrom,
								status,
								selectedTableId,
								"",
								voidedStall
							);

							if (response?.data) {
								allResponses.push(response.data);

								let menuItemFullList = allMenuItems;
								let invoiceData = response.data;
								let transactionItems = invoiceData.transactionItems;

								let beverageItems = [];
								let otherItems = [];

								transactionItems.forEach((item) => {
								let menuItem = menuItemFullList.find(menu => menu.menuItemId === item.menuItemId);
								let type = menuItem?.menuItemType || "NONE";

								if (type === "Beverage") {
									beverageItems.push(item);
								} else {
									otherItems.push(item);
								}
							});

							const processInvoiceList = (items, isBeverage = false) => {
								if (items.length > 0) {
									let invoiceClone = { ...invoiceData, transactionItems: items };

									if (response?.data.tableIds === "NO") {
										let existingTakeawayData = JSON.parse(localStorage.getItem("TAKEAWAY")) || [];
 
										let existingInvoiceIndex = existingTakeawayData.findIndex(
											(invoice) => invoice.transactionID === invoiceClone.transactionID
										);

										if (existingInvoiceIndex !== -1) { 
											existingTakeawayData[existingInvoiceIndex].transactionItems = [
												...existingTakeawayData[existingInvoiceIndex].transactionItems,
												...items,
											];
										} else { 
											existingTakeawayData.push(invoiceClone);
										}
 
										localStorage.setItem("TAKEAWAY", JSON.stringify(existingTakeawayData));
										lastStallIdProcessed = stallId;
									}
 

									if (isBeverage) {
										printService().sendOutletVoidBOTBill(invoiceClone,voidFrom);  
									} else {
										printService().sendOutletVoidKOTBill(invoiceClone,voidFrom);  
									}
								}
							};

							processInvoiceList(beverageItems, true);  
							processInvoiceList(otherItems, false);  

							setTimeout(() => {
								setIsSubmitting(false);
							}, 2000);
 
							}
						} catch (error) {

							setIsSubmitting(false);
							console.error(`Failed to process stallID ${stallId}:`, error);
							setNotification({ message: `Order failed for stall ${stallId}. Please try again.`, show: true, type: 'error' });
						
						}
					}
				}
  
				if (voidData) {
					localStorage.removeItem("voidbill");
				}
	
				if (orderType === "TAKEAWAY" && lastStallIdProcessed) {
					
					const takeawayBillState = {
						selectedTableId,
						fromTakeawayOrders: true,
					};
					navigate(`/pos/counter-checkout-outlet`, { state: takeawayBillState });
				} else {
					
					navigate(`/pos/counter-checkout-outlet`);
				}

				setIsVoidData(false);
				setOrderData([]); 
				setTransactionId([]);
				setModalSelectedAddon([]); 
				setTotalPrice(0);
				setSelectedTableId("");
				setLocalTableId("NO"); 
				SubTotal = 0;
				TotalTaxesPrice = 0;
				TaxesPrice = 0;

			} catch (error) {

				setIsSubmitting(false);
				console.error("Order submission failed:", error);
				setNotification({ message: "Order failed. Please try again.", show: true, type: 'error' });

			}

		} catch (error) {
			setIsSubmitting(false);
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Order failed. Please try again.',
				confirmButtonColor: '#d33',
				allowOutsideClick: false,
			});
		}
	};
    
	const sendOutletData = async () => {
		if (!orderData || orderData.length === 0) {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Order data is empty. Please add items to your order.',
				confirmButtonColor: '#d33',
				allowOutsideClick: false,
			});
			return;
		}

		try {
			const groupedOrders = orderData?.reduce((groups, item) => {
				(groups[item.stallID] = groups[item.stallID] || []).push(item);
				return groups;
			}, {});

			let allResponses = [];
			let lastStallIdProcessed = null;

			for (const stallId in groupedOrders) {
				if (Object.hasOwnProperty.call(groupedOrders, stallId)) {
					const stallOrderData = groupedOrders[stallId];

					let outletSubTotal = stallOrderData.reduce((total, item) => total + item.itemPrice * item.quantity, 0);

					let TaxesPrice = 0;

					stallOrderData?.forEach((order) => {
						order?.taxItems?.forEach((tax) => {
							const taxType = tax?.taxType;
							if (taxType === tax?.taxType) {
								TaxesPrice += tax.taxValue * order.quantity;
							}
						});
					});
                   
					let outletTotalTax = TaxesPrice;
					let outletTotal = outletSubTotal + outletTotalTax;

					try {
						const response = await dishesServices().submitOutletOrder(
							stallOrderData,
							outletTotal,
							outletTotalTax,
							outletSubTotal,
							status,
							selectedTableId,
							"",
							stallId
						);

						if (response?.data) {
							allResponses.push(response.data);

							if (selectedTableId && orderType === 'DINING') {
								setOutletTables(prevTables => 
									prevTables.map(table => ({
										...table,
										alreadyAssigned: table.tableId === selectedTableId ? true : table.alreadyAssigned
									}))
								);
							}

							let menuItemFullList = allMenuItems;
							let invoiceData = response.data;
							let transactionItems = invoiceData.transactionItems;

							let beverageItems = [];
							let otherItems = [];

							transactionItems.forEach((item) => {
								let menuItem = menuItemFullList.find(menu => menu.menuItemId === item.menuItemId);
								let type = menuItem?.menuItemType || "NONE";

								if (type === "Beverage") {
									beverageItems.push(item);
								} else {
									otherItems.push(item);
								}
							});

							const processInvoiceList = (items, isBeverage = false) => {
								if (items.length > 0) { 
									let invoiceClone = { ...invoiceData, transactionItems: items };

									if (orderType === "TAKEAWAY") { 
										let existingTakeawayData = JSON.parse(localStorage.getItem("TAKEAWAY")) || [];
 
										let existingInvoiceIndex = existingTakeawayData.findIndex(
											(invoice) => invoice.transactionID === invoiceClone.transactionID
										);

										if (existingInvoiceIndex !== -1) { 
											existingTakeawayData[existingInvoiceIndex].transactionItems = [
												...existingTakeawayData[existingInvoiceIndex].transactionItems,
												...items,
											];
										} else { 
											existingTakeawayData.push(invoiceClone);
										}
 
										localStorage.setItem("TAKEAWAY", JSON.stringify(existingTakeawayData));
										lastStallIdProcessed = stallId;
									}
 
									if (isBeverage) {
										printService().sendOutletBOTBill(invoiceClone);  
									} else {
										printService().sendOutletKOTBill(invoiceClone);  
									}
								}
							};
 
							processInvoiceList(beverageItems, true);  
							processInvoiceList(otherItems, false);  

							setTimeout(() => {
								setIsSubmitting(false);
							}, 2000);
						}

					} catch (error) {
						setIsSubmitting(false);
						console.error(`Failed to process stallID ${stallId}:`, error);
						setNotification({ message: `Order failed for stall ${stallId}. Please try again.`, show: true, type: 'error' });
						Swal.fire({
							icon: 'error',
							title: 'Error',
							html: 'Order failed. Please <span style="color: red; font-weight: bold;">Refresh the page</span> and try again.',
							confirmButtonColor: '#d33',
							allowOutsideClick: false,
						});
					}
				}
			}

			if (orderType === "TAKEAWAY" && lastStallIdProcessed) {
				const takeawayBillState = {
					selectedTableId,
					fromTakeawayOrders: true,
				};
				navigate(`/pos/counter-checkout-outlet`, { state: takeawayBillState });
			}

			setOrderData([]);
			setModalSelectedAddon([]);
			setSelectedTableId('');
			setLocalTableId("NO");
			setTotalPrice(0);
			SubTotal = 0;
			TaxesPrice = 0;

		} catch (error) {

			setIsSubmitting(false);
			console.error("Order submission failed:", error);
			setNotification({ message: "Order failed. Please try again.", show: true, type: 'error' });
		
		}
	};


	var addToCart = (event) => {
			event.preventDefault(); 
			modal.hide();
			 
			var addOns = [];
			var taxes = [];
			var totalAddOn = 0;
			var totalTaxInd = 0;
			
			if (modalSelectedAddon) {
				modalSelectedAddon?.forEach( (SelectedAddon) => {
					const price = parseFloat(SelectedAddon.price) || 0;
					var option = {
						"addOnID": 0,
						"orderItemId": 0,
						"addon": SelectedAddon.value,
						"price": price,
						"key": "addon",
					};
					totalAddOn += parseFloat(SelectedAddon.price) || 0;
					addOns.push(option);
				});
				
			}
	
			if (taxData) {
			
				taxData?.forEach((tax) => {
					const taxPercentage = parseFloat(tax.taxRate) || 0;
					const price = parseFloat(modalData.price) || 0;
					const addOn = parseFloat(totalAddOn) || 0;
	
			
					const option1 = {
						"taxItemId": 0,
						"orderItemId": 0,
						"acumaticaTaxId": 0,
						"taxCode": tax.taxCode,
						"taxType": tax.taxType,
						"taxPercentage": taxPercentage,
						"taxValue": ((addOn + price) / 100) * taxPercentage
					};
					totalTaxInd += ((addOn + price) / 100) * taxPercentage;
					taxes.push(option1)
				});
			}
			setTimeout(() => {
				setTotal(0);
				setOrderData([...orderData, {
					"orderItemId": 0,
					"transactionID": 0,
					"menuItemId": modalData?.menuItemId,
					"itemName": modalData?.title,
					"quantity": modalQuantity,
					"taxItems": taxes,
					"itemPrice": modalData?.price,
					"addOns": addOns,
					"addOnTotal":totalAddOn,
					"createdByScreenID":"NEED-CLARIFICATION-FROM-ACU",
					"id": (orderData?.length || 0) + 1,
				}]);
			}, 500);
	}

	const fetchDishes = async () => {
		if (!stallId) {
			return;
		}

		setIsLoading(true);

		try { 
			const accessibleStalls = MenuItemsAccessPermissions[stallId] || [];
 
			const stallIdsString = accessibleStalls.join(',');
 
			const response = await dishesServices().getMenuItemsByStall(stallIdsString);
			response.sort((a, b) => a.title.localeCompare(b.title));
			 
			setAllMenuItems(response);
  
			const filteredData = response.filter(item => item.stallID === stallId);
			 
			setTableData(filteredData);

		} catch (error) {
			console.error('Error fetching menu items:', error);
		} finally {
			setIsLoading(false);
		}
	};
 
	const fetchTaxes = async () => {
			try {
				const response = await taxServices().getAllTax();
			
				setTaxData(response.data); 
			
			} catch (error) {
				console.error('Error fetching suppliers data:', error);
			}
	};
 
	const fetchTables = async () => {
		try {
			  
			const response = await dishesServices().getAllTables();
 
			const filteredTables = response.data.filter(table => table.stallId === stallId);
 
			const tablesWithOrderStatus = await Promise.all(filteredTables.map(async (table) => {
				const tableOrdersResponse = await dishesServices().getAllTableOreders(table.tableId); 
  
				const updatedTable = {
					...table, 
					alreadyAssigned: Array.isArray(tableOrdersResponse) && tableOrdersResponse.length > 0 
						? tableOrdersResponse.some(order => order.statusChange === 'INPROGRESS')
						: false,   
					alreadyBilled: Array.isArray(tableOrdersResponse) && tableOrdersResponse.length > 0 
						? tableOrdersResponse.some(order => order.statusChange === 'PRINTED')
						: false   
				};
 
				return updatedTable;
			})); 
 
			setOutletTables(tablesWithOrderStatus);
		} catch (error) {
			console.error('Error fetching tables or orders:', error);
		}
	};

	const fetchStalls = async () => {
			try {
				const stallResponse = await tableServices().getStalls(); 
				return stallResponse; 
			} catch (error) { 
				return error; 
			}
	};

	const clearBillData = async () => {
      	setOrderData([]);
    };

	const handleTableChange = async (event) => {
		const selectedValue = event.target.value;

		try {
			const response = await dishesServices().getAllTableOreders(selectedValue);
 
			const hasPrintedOrders = response.some((order) => order.statusChange === "PRINTED");

			if (hasPrintedOrders) {
				setSelectedTableId("");
				Swal.fire({
					title: "Warning",
					text: "This table already has bill submitted dining orders. Please make sure to settle this table bill before adding new orders.",
					icon: "warning",
					confirmButtonText: "OK",
				});
				return;  
			}
 
			setSelectedTableId(selectedValue);

			if (isFirstSelection) {
				handleTableClick(selectedValue);
				setIsFirstSelection(false);
			}

			handleTableClick(selectedValue);
		} catch (error) {
			console.error("Error fetching table orders:", error);
			Swal.fire({
			title: "Error",
			text: "Failed to fetch table orders. Please try again.",
			icon: "error",
			confirmButtonText: "OK",
			});
		}
	};

	const handleItemNameClick = (orderId) => {
		setConfirmationVisibility((prevState) => ({
			  ...prevState,
			  [orderId]: !confirmationVisibility[orderId],
			}));
	};

	const handleOrderTypeChange = (type) => {
        setOrderType(type); 
			if (type === "TAKEAWAY") {
				setSelectedTableId(""); 
			}
    }; 

	const handleButtonClick = (id) => {

		setSelectedButton(id); 
		const filteredData = allMenuItems.filter(item => item.stallID === id);
		 
		setTableData(filteredData);

	};

	const handleAddOpenItem = (newItem) => {	
		const openItemRecord = tableData?.find((item) => item.subCategory === "OPEN FOOD" || item.subCategory === "OPEN BEVERAGE");
	  
		if (!openItemRecord) {
		  console.error("No OPEN FOOD or OPEN BEVERAGE record found in tableData or tableData is empty");
		  return;
		}
	
		const calculatedTaxes = taxData?.map((tax) => {
			const taxPercentage = parseFloat(tax.taxRate) || 0;
			const taxValue = (parseFloat(newItem.price) || 0) * (taxPercentage / 100);
			return {
				taxItemId: 0,
				orderItemId: 0,
				acumaticaTaxId: 0,
				taxCode: tax.taxCode,
				taxType: tax.taxType,
				taxPercentage,
				taxValue,
			};
		}) || [];
	
		const taxTotal = calculatedTaxes.reduce((total, tax) => total + tax.taxValue, 0);
		const totalPriceWithTaxes = parseFloat(newItem.price || 0) + taxTotal;
	
		setOrderData((prevItems) => [
		  ...prevItems,
		  {
			orderItemId: 0,
			transactionID: 0,
			itemName: newItem.foodItemName,
			quantity: newItem.quantity,
			itemPrice: newItem.price,
			taxItems: calculatedTaxes,
			addOns: [],
			addOnTotal: 0,
			menuItemId: openItemRecord.MenuItemId || openItemRecord.menuItemId,
			createdByScreenID: "NEED-CLARIFICATION-FROM-ACU",
			id: uuidv4(),
			stallID: openItemRecord.stallID,
		  },
		]);
	  
		setOpenItems((prevItems) => [...prevItems, newItem]);
	};

	useEffect(() => {
		if (selectedTableId) {
			setTableStatus("BOOKED");
		} else {
			setTableStatus("FREE"); 
		}
  	}, [selectedTableId]);  
 
	useEffect(() => {
		if (Object.keys(voidData).length === 0) {
			setIsVoidData(false);
			setOrderData([]); 
			setTransactionId([]);
		} 
	}, [JSON.stringify(voidData)]);

	useEffect(() => {
		const fetchVoidReasonsAndData = async () => {
			if (voidData && isVoidBillData) {
				setIsVoidData(true);
				setOrderData([]); 
				setTransactionId([]);
	
				let allOrderData = [];
				let totalAmount = 0;
				let transactionIDs = [];
	
				voidData.forEach((dataItem) => { 
					transactionIDs.push(dataItem.transactionID);
	
					dataItem.transactionItems?.forEach((item) => {
						allOrderData.push({
							orderItemId: 0,
							transactionID: item.transactionID,
							menuItemId: item.menuItemId,
							itemName: item.itemName,
							quantity: item.quantity,
							itemPrice: item.itemPrice,
							taxItems: item.taxItems,
							addOns: item.addOns,
							createdByScreenID: item.createdByScreenID,
							addOnTotal: 0,
							id: uuidv4(),
							stallID:dataItem.stallId,
							tableID:dataItem.tableIds,
						});
					});
	
					totalAmount += dataItem.totalAmount;
				});
	 

				localStorage.setItem("TAKEAWAY", JSON.stringify([]));
				let voidedOrderType = voidData[0].tableIds === "NO" ? "TAKEAWAY" : "DINING";
				setOrderType(voidedOrderType);
				setSelectedTableId(voidedOrderType === "DINING" ? voidData[0].tableIds : "");
				setOrderData(allOrderData);
				setTransactionId(transactionIDs);
				setTotal(totalAmount);
	
				try {
					const voidReasons = await dishesServices().getVoidReasons();
					if (Array.isArray(voidReasons)) {
						setVoidReasonData(voidReasons);
					} else {
						console.error("voidReasons is not an array");
					}
				} catch (error) {
					console.error("Error fetching void reasons:", error);
				}
			}
		};

		fetchVoidReasonsAndData();
	}, [location.state]);

	useEffect(() => {
		return () => {
			if (modalInstanceRef?.current) {
				modalInstanceRef?.current.dispose();
			}
		};
	}, []);

	useEffect(() => {
		let calculatedTotalPrice = 0;
		if (orderData) {
			orderData.forEach((order) => {
			const itemPrice = parseFloat(order.itemPrice) || 0;
			const addOnTotal = parseFloat(order.addOnTotal) || 0;
			const quantity = parseInt(order.quantity) || 0;

			calculatedTotalPrice += (itemPrice + addOnTotal) * quantity;
			});
			calculatedTotalPrice += parseFloat(TotalTaxesPrice) || 0;
		}
		if(localStorage.getItem('stallID') == 'CAFEWALK' || localStorage.getItem('stallID') == 'BAKESNCAKE') {
			calculatedTotalPrice = parseFloat((Math.round(calculatedTotalPrice || 0)).toFixed(2))
		}
		setTotalPrice(calculatedTotalPrice);
		if (calculatedTotalPrice > 0) {
			setIsEmptyVoidData(false);  
		} else {
			setIsEmptyVoidData(true);  
		}
	}, [orderData, TotalTaxesPrice]);  

	useEffect(() => { 
		localStorage.setItem('orderData', JSON.stringify(orderData)); 
	}, [orderData]);

	  useEffect(() => {
		if (!stallId) return;
 
		const accessibleGroups = MenuItemsAccessPermissions[stallId] || [];
 
		setGroups(accessibleGroups);
	}, [stallId]);

	useEffect(() => { 
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentFullHeight(true);
		context.setAppContentClass('p-0');
	
		fetchDishes();
		fetchTaxes();
		fetchTables(); 
		fetchStalls();
		setModal(new Modal(document.getElementById("modalPosItem")));
		localStorage.setItem('customerScreenStatus',"Order");
		if(localStorage.getItem('stallID') == 'CAFEWALK' || localStorage.getItem('stallID') == 'BAKESNCAKE') {
			setstall(true);
		}	  
		
		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentFullHeight(false);
			context.setAppContentClass('');
		}
	}, []);

	
	return (
		<>
 
		<ToastContainer />
		<div className="h01-100 content-with-header">
			<div className={'pos pos-with-menu pos-with-sidebar ' + ((posMobileSidebarToggled) ? 'pos-mobile-sidebar-toggled' : '')} id="pos">
				<div className="pos-container">
				<div className="pos-header fixed-header">
				<PosHeader />
				</div>
					<div className="pos-menu h02-100">
						<div className="nav-container">
						<PerfectScrollbar className="">
							<ul className="nav nav-tabs">
								{tableData && (
									<>
										<li className="nav-item">
											<a 
												className={'nav-link' + (categoryType === "all" ? ' active' : '')} 
												onClick={(event) => showType(event, "all")} 
												href="#/"
											>
												<i className="fa fa-fw fa-utensils"></i> All Dishes
											</a>
										</li>
										{[...new Map(tableData?.map((category) => [category?.subCategory, category])).values()]
											.map((category, index) => (
												<li className="nav-item" key={index}>
													<a 
														className={'nav-link' + ((category?.subCategory === categoryType) ? ' active' : '')} 
														onClick={(event) => {
															if (category?.subCategory === "OPEN FOOD" || category?.subCategory === "OPEN BEVERAGE") {
															event.preventDefault();
															handleModalOpen();
															} else {
															showType(event, category?.subCategory);
															}
														}} 
														href="#/"
													>
														{category?.subCategory}
													</a>
												</li>
											))
										}
										<li className="nav-item"></li>
									</>
								)}
							</ul>
						</PerfectScrollbar>
						
						<OpenItemModal
							isOpen={isModalOpen}
							onClose={handleModalClose}
							onSubmit={handleAddOpenItem}
						/>
						</div>
					</div>

					<div className="pos-content">

					<div className="pos-content-container">

					{isLoading && (
						<div style={loaderStyle}>
							<div className="spinner-border text-light" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					)}

					{isSubmitting && (
						<div
							style={{
							position: 'fixed',
							top: 0,
							left: 0,
							width: '100vw',
							height: '100vh',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: 'rgba(0, 0, 0, 0.5)',
							zIndex: 1000,
							}}
						>
						<div role="status">
							<span
								style={{
								color: '#F57C00',
								fontSize: '1.5rem',
								fontWeight: 'bold',
								}}
							>
							Submitting order, please wait...
							</span>
						</div>
					</div>
					)}
						
					<div>
						<div style={{ display: 'flex', alignItems: 'center', width: '100%', marginRight: '20px' }}>
							<div
								className="btn-group"
								style={{ marginBottom: '5px', display: 'flex', justifyContent: 'center', width: '100%'}}
							>
								{groups.map((group) => (
									<button
									key={group}
									type="button"
									className={`btn ${selectedButton === group ? 'btn-primary' : 'btn-outline-primary'}`}
									onClick={() => handleButtonClick(group)}
									style={ group === 'KITCHEN' ? { backgroundColor: '#333', color: '#FFB74D'} : {} }
									>
									{group}
									</button>
								))}
							</div>

						</div>
						<div className="row gx-4">
							{tableData && tableData.filter(item =>
								item?.subCategory !== "OPEN FOOD" && 
								item?.subCategory !== "OPEN BEVERAGE" &&
							 	!item.hide).length > 0 ? (
								tableData.filter(item =>
									item?.subCategory !== "OPEN FOOD" && 
									item?.subCategory !== "OPEN BEVERAGE" &&
									!item.hide
									).map((item, index) => (
									<div className="col-xxl-3 col-xl-4 col-lg-6 col-md-4 col-sm-6 pb-4" key={index}>
										<a 
											href="#" 
											className="pos-product" 
											onClick={(event) => {
												showPosItemModal(event, item);
											}}
										>
										<div className={`info ${orderData?.some(prevItem => prevItem.id === item.id) ? 'highlight' : ''}`} >
										<div className="title" style={{ fontWeight: 'bold', marginBottom: '8px' }}>
											{item?.title}
										</div>
										<div className="price">
											RS {item?.price?.toFixed(2)}
										</div>
										</div>
										</a>
										</div>
								))
							) : (
								!isLoading && <div className="col-12">No records found</div>
							)}
						</div>
					</div>
					
					</div>
				</div>
					
					<div className="pos-sidebar" id="pos-sidebar">
						<div className="h-100 d-flex flex-column p-0">
						<div className="pos-sidebar-header">
						<div className="back-btn">
							<button type="button" onClick={dismissMobileSidebar} className="btn">
								<i className="fa fa-chevron-left"></i>
							</button>
						</div>
               
						<div style={{ display: 'flex', alignItems: 'center', width: '100%', marginRight:'20px' }}>
							<div className="btn-group" style={{ marginBottom: '5px', display: 'flex', justifyContent: 'center' }}>
							<button
								type="button"
								className={`btn ${orderType === 'TAKEAWAY' ? 'btn-primary' : 'btn-outline-primary'}`}
								disabled={(voidData && isVoidData)}
								onClick={() => handleOrderTypeChange('TAKEAWAY')}
							>
								TAKEAWAY
							</button>
							<button
								type="button"
								className={`btn ${orderType === 'DINING' ? 'btn-primary' : 'btn-outline-primary'}`}
								disabled={(voidData && isVoidData)}
								onClick={() => handleOrderTypeChange('DINING')}
							>
								DINING
							</button>
						</div>
							{orderType === 'DINING' && (
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<select
										className="form-select mr-2"
										value={selectedTableId}
										onChange={handleTableChange}
										disabled={orderType === 'TAKEAWAY' || (voidData && isVoidData)}
										style={{ marginBottom: '5px', width: '70%', marginLeft: '30px' }}
									>
										<option value="" disabled>Assign a table</option>
										{outletTables.length > 0 ? (
											outletTables
												.filter((table) => table.stallId === stallId)  
												.map((table) => (
													<option
														key={table.tableId}
														value={table.tableId}
														style={{
															backgroundColor: table.alreadyBilled 
																? '#FF7043' 
																: table.alreadyAssigned 
																	? '#FFB74D' 
																	: 'transparent',  
														}}
													>
														{table.tableId}
													</option>
												))
										) : (
											<option>No tables available</option>
										)}
									</select>
									{selectedTableId && (
										<button
											className="btn btn-outline-primary ms-2"
											onClick={() => handleTableClick(selectedTableId)}
											style={{ whiteSpace: 'nowrap' }}
										>
										Orders
										</button>
									)}
								</div>
							)}
						</div> 
						</div>
						<div className="d-flex align-items-center justify-content-center" style={{ color: '#F57C00', fontSize: '28px', fontWeight: 'bold' }}>
								{selectedTableId ? `DINING - ${selectedTableId}` : ""}
						</div>
					    <div style={{ 
								marginBottom: '10px', 
								display: 'flex', 
								flexWrap: 'wrap',  
								gap: '10px', 
								padding: '5px 15px',
								maxWidth: '100%'  
								}}>
								{outletTables
									.filter(table => table?.stallId === stallId && (table?.alreadyAssigned || table?.alreadyBilled))
									.reduce((rows, table, index) => {
								    if (index % 7 === 0) rows.push([])
									rows[rows.length - 1].push(table);
									return rows;
									}, [])
									.map((row, rowIndex) => (
									<div 
										key={rowIndex} 
										style={{
										display: 'flex',
										gap: '10px',
										width: '100%'
										}}
									>
										{row.map(table => (
										<div
											key={table.tableId}
											style={{
											padding: '6px 12px',
											backgroundColor: table.alreadyBilled ? '#ff7043' : '#F57C00',
											//backgroundColor: '#F57C00',
											fontSize: '13px',
											borderRadius: '4px',
											color: table.alreadyBilled ? '#000000' : '#000000',
											//color: '#000000',
											whiteSpace: 'nowrap',
											display: 'flex',
											alignItems: 'center',
											fontWeight: '500',
											marginBottom: '10px' 
											}}
										>
											{String(table.tableId).padStart(2, '0')}&nbsp;
											<span style={{ color: table.alreadyBilled ? '#000000' : '#000000'}}>
											{table.alreadyBilled}
											</span>
										</div>
										))}
									</div>
									))}
						</div>			
						<hr className="opacity-1 my-10px" />
							<PerfectScrollbar className="pos-sidebar-body tab-content h-100">
								<div className="tab-pane fade h-100 show active" id="newOrderTab">
									{orderData && orderData?.length > 0 ? (orderData?.map((order, index) => (
										<div className="pos-order" key={index}>
											<div className="pos-order-product">
												<div className="flex-1">
													<div className="h6 mb-1" onClick={() => handleItemNameClick(order.id)} style={{ cursor: 'pointer' }}>{order?.itemName} [ x {order?.quantity}]</div>
													<div className="small mb-2">
														{voidData && isVoidData ? (
															<>
																{order?.addOns && order?.addOns.map((addOns, index) => {
																 
																return (
																<div key={index}>
																	- {"addOn"}: {addOns.addOn} - RS {addOns.price}
																</div>
																);
																})}
															</>
															):(
															<>
																{order?.addOns && order?.addOns.map((addOns, index) => {
																 
																return (
																<div key={index}>
																	- {addOns.key}: {addOns.addon} - RS {addOns.price}
																</div>
																);
																})}
															</>
															)
																
															}
													</div>

													{confirmationVisibility[order.id] && (<div className="d-flex">
														<a href="#/" className="btn btn-secondary btn-sm" onClick={(event) => deductQty(event, order.id)}><i className="fa fa-minus"></i></a>
														<input
															type="text"
															className="form-control w-50px form-control-sm mx-2 bg-white bg-opacity-25 text-center no-spinner"
															value={order?.quantity}
															onChange={(event) => handleQtyChange(event, order.id)}
															min="1"
														/>

														<a href="#/" className="btn btn-secondary btn-sm" onClick={(event) => addQty(event, order.id)}><i className="fa fa-plus"></i></a>
													</div>)}
												</div>
											</div>
											<div className="pos-order-price d-flex flex-column">
											<div>RS { getTotalDishPrice(order)}</div>
											{confirmationVisibility[order.id] && (<div className="text-end mt-auto">
													<button onClick={(event) => toggleConfirmation(event, order.id, true)} className="btn btn-default btn-sm">
													<i className="fa fa-trash"></i>
													</button>
												</div>)}
											</div>
											{order?.confirmation && (
												<div className="pos-order-confirmation text-center d-flex flex-column justify-content-center">
													<div className="mb-1">
														<i className="bi bi-trash fs-36px lh-1"></i>
													</div>
													<div className="mb-2">Remove this item?</div>
													<div>
														<button onClick={(event) => toggleConfirmation(event, order.id, false)} className="btn btn-outline-white btn-sm ms-auto me-2 width-100px">No</button>
														<button onClick={(event) => removeOrder(event, order.id)} className="btn btn-outline-theme btn-sm width-100px">Yes</button>
													</div>
												</div>
											)}
										</div>
									))) : (
										<div className="h-100 d-flex align-items-center justify-content-center text-center p-20">
											<div>
												<div className="mb-3 mt-n5">
													<i className="bi bi-bag text-white text-opacity-50" style={{fontSize: '6em'}}></i>
												</div>
												<h5>No order found</h5>
											</div>
										</div>
									)}
								</div>
						
								<div className="tab-pane fade h-100" id="orderHistoryTab">
									<div className="h-100 d-flex align-items-center justify-content-center text-center p-20">
										<div>
											<div className="mb-3 mt-n5">
												<svg width="6em" height="6em" viewBox="0 0 16 16" className="text-gray-300" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
													<path fillRule="evenodd" d="M14 5H2v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5zM1 4v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4H1z"/>
													<path d="M8 1.5A2.5 2.5 0 0 0 5.5 4h-1a3.5 3.5 0 1 1 7 0h-1A2.5 2.5 0 0 0 8 1.5z"/>
												</svg>
											</div>
											<h5>No order history found</h5>
										</div>
									</div>
								</div>
							</PerfectScrollbar>
							
							<div className="pos-sidebar-footer">
							<div style={{ display: stall ? 'none' : 'block' }}>
								<div className="d-flex align-items-center mb-2">
									<div>Subtotal</div>
									<div className="flex-1 text-end h6 mb-0">RS {getSubTotalPrice()}</div>
								</div>
								<div className="small mb-2">
									{taxData && taxData?.map((tax, index) => (
									<div key={index} className="d-flex justify-content-between">
										<div>{tax?.taxType}: {tax?.taxRate} %</div>
										<div className="flex-1 text-end h6 mb-0">RS {getTaxesPrice(tax?.taxType)}</div>
									</div>
									))}
								</div>
							</div>

								<hr className="opacity-1 my-10px" />
								<div className="d-flex align-items-center mb-2">
									<div>Total</div>
									 <div className="flex-1 text-end h4 mb-0">RS {(voidData && isVoidData) ? ((total != 0) ? (total.toFixed(2)) : (totalPrice.toFixed(2)) ) : (totalPrice.toFixed(2))}</div>
								</div>
								<div className="mt-3">
									<div className="d-flex">
										{voidData && isVoidData ? (
											<>
											<button
												className="btn btn-default flex-fill w-70px me-10px d-flex align-items-center justify-content-center"
												onClick={openCancelOrderModal}
											>
												<span>
												<i className="fa fa-trash fa-lg my-10px d-block mx-auto"></i>
												<span className="small fw-semibold">
													Void Full Order
												</span>
												</span>
											</button>
											<button
												className="btn btn-theme flex-fill d-flex align-items-center justify-content-center"
												onClick={openModal}
												disabled={isEmptyVoidData}
											>
												Submit Order
											</button>
											</>
										) : (
											<>

											<button
												className="btn btn-default w-70px me-10px d-flex align-items-center justify-content-center"
												onClick={clearBillData}
											>
											<span>
												<i className="fa fa-trash fa-lg my-10px d-block"></i>
												<span className="small fw-semibold">Clear</span>
											</span>
											</button>
											 
											<button
												className="btn btn-theme flex-fill d-flex align-items-center justify-content-center"
												onClick={(event) => {
													setIsSubmitting(true); 
													submitOrder(event);
												}}
												disabled={(orderType ==="DINING" && selectedTableId === "")}
											>
												Submit Order
											</button>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		
			<a href="#/" className="pos-mobile-sidebar-toggler" onClick={toggleMobileSidebar}>
				<i className="fa fa-shopping-bag"></i>
				<span className="badge">{getOrderTotal()}</span>
			</a>
	
			<div className="modal modal-pos fade" id="modalPosItem">
				<div className="modal-dialog modal-lg">
					<div className="modal-content border-0">
						{modalData && (
							<div>
								<div className="p-0">
									<button data-bs-dismiss="modal" className="btn-close position-absolute top-0 end-0 m-4">&nbsp;</button>
									<div className="modal-pos-product">
										<div className="modal-pos-product-info">
											<div className="fs-4 fw-semibold">{ modalData?.title }</div>
											<div className="text-body text-opacity-50 mb-2">
												{ modalData?.description }
											</div>
											<div className="fs-3 fw-bold mb-3">RS { modalData?.price?.toFixed(2) }</div>
											<div className="d-flex mb-3">
												<button className="btn btn-secondary" onClick={(event) => deductModalQty(event)}><i className="fa fa-minus"></i></button>
												<input type="text" value={modalQuantity} readOnly className="form-control w-50px fw-bold mx-2 text-center" />
												<button className="btn btn-secondary" onClick={(event) => addModalQty(event)}><i className="fa fa-plus"></i></button>
											</div>
											<hr className="mx-n4" />
											{modalData && modalData?.menuItemAddOns && (
												<div className="mb-2">
													<div className="fw-bold">Add On:</div>
													<div className="option-list">
														{modalData?.menuItemAddOns?.map((addon, index) => (
															<div className="option" key={index}>
																<input 
																	type="checkbox" 
																	name="addon" 
																	className="option-input" 
																	onChange={(event) => handleAddonChange(event)} 
																	value={addon?.addOnName} 
																	data-price={addon?.addOnPrice} 
																	id={'addon' + index}
																	checked={modalSelectedAddon.some(selected => selected?.value === addon?.addOnName)} 
																/>
																<label className="option-label" htmlFor={'addon' + index}>
																	<span className="option-text">{addon?.addOnName}</span>
																	<span className="option-price">+RS {addon?.addOnPrice.toFixed(2)}</span>
																</label>
															</div>
														))}
													</div>
												</div>
											)}
											<hr className="mx-n4" />
											<div className="row">
												<div className="col-4">
													<button className="btn btn-default fw-semibold mb-0 d-block py-3 w-100" data-bs-dismiss="modal">Cancel</button>
												</div>
												<div className="col-8">
													<button className="btn btn-theme fw-semibold d-flex justify-content-center align-items-center py-3 m-0 w-100" onClick={(event) => addToCart(event)}>Add to cart <i className="bi bi-plus fa-2x ms-2 my-n3"></i></button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className="modal fade" id="modalVoidItem"
				tabIndex="-1"
				aria-labelledby="voidOrderLabel"
				aria-hidden="true"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="voidOrderLabel">
								Confirm Void Order
							</h5>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body"> 
							<div className="mb-3">
								<label>Reason for Voiding Order:</label>
								<select
									className="form-control"
									value={voidReason}
									onChange={(e) => setVoidReason(e.target.value)}
									style={{ width: "100%" }}
								>
									<option value="" disabled>Select a reason</option>
									{voidReasonData?.map((reason) => (
									<option key={reason.reasonId} value={reason.reasonCode}>
										{reason.description}
									</option>
									))}
								</select>
							</div>
							<div className="row mb-3">
								<div className="col">
									<label>Manager Username:</label>
									<input
										type="text"
										className="form-control"
										value={managerUsername}
										onChange={(e) => setManagerUsername(e.target.value)}
									/>
								</div>
								<div className="col">
									<label>Manager Password:</label>
									<input
										type="password"
										className="form-control"
										value={managerPassword}
										onChange={(e) => setManagerPassword(e.target.value)}
									/>
								</div>
							</div>

							{errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
						</div>

						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								data-bs-dismiss="modal"
								onClick={closeModal}
							>
								Cancel
							</button>
							<button
								type="button"
								className="btn btn-theme" 
								onClick={(event) => {
									setIsSubmitting(true);
									event.preventDefault();
									handleSubmitOrder();
								}}
								disabled={!managerUsername || !managerPassword || !voidReason}
							>
								Submit Order
							</button>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" id="cancelOrderModal"
				tabIndex="-1"
				aria-labelledby="cancelOrderLabel"
				aria-hidden="true"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="cancelOrderLabel">
								Confirm Void Full Order
							</h5>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body">
							<p>Are you sure you want to cancel this order?</p>
							<p className="text-danger">
								<strong>
									Once confirmed, this action cannot be reversed.
								</strong>
							</p>
							<div className="mb-3">
								<label>Reason for Voiding Order:</label>
								<select
									className="form-control"
									value={voidReason}
									onChange={(e) => setVoidReason(e.target.value)}
									style={{ width: "100%" }}
								>
									<option value="" disabled>Select a reason</option>
									{voidReasonData?.map((reason) => (
									<option key={reason.reasonId} value={reason.reasonCode}>
										{reason.description}
									</option>
									))}
								</select>
							</div>
							<div className="row mb-3">
								<div className="col">
									<label>Manager Username:</label>
									<input
										type="text"
										className="form-control"
										value={managerUsername}
										onChange={(e) => setManagerUsername(e.target.value)}
									/>
								</div>
								<div className="col">
									<label>Manager Password:</label>
									<input
										type="password"
										className="form-control"
										value={managerPassword}
										onChange={(e) => setManagerPassword(e.target.value)}
									/>
								</div>
							</div>

							{errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
						</div>

						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-secondary"
								data-bs-dismiss="modal"
								onClick={closeModal}
							>
								Cancel
							</button>
							<button
								type="button"
								className="btn btn-danger"
								onClick={handleCancelOrder}
								disabled={!managerUsername || !managerPassword || !voidReason}
							>
								Confirm Void Full Order
							</button>
						</div>
					</div>
				</div>
			</div>

			{/* Add this after your last modal */}
			<div 
				className={`modal fade ${showTableOrders ? 'show' : ''}`} 
				id="tableOrdersModal" 
				tabIndex="-1" 
				style={{ 
					display: showTableOrders ? 'block' : 'none',
					backgroundColor: 'rgba(0, 0, 0, 0.5)'
				}}
			>
				<div className="modal-dialog modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Current Orders for Table {selectedTableForModal}</h5>
							<button 
								type="button" 
								className="btn-close" 
								onClick={() => setShowTableOrders(false)}
							></button>
						</div>
						<div className="modal-body">
							{currentTableOrders?.length > 0 ? (
								<div className="table-responsive">
									<table className="table table-hover">
										<thead>
											<tr>
												<th>Item Name</th>
												<th className="text-center">Quantity</th>
												<th className="text-end">Price</th>
											</tr>
										</thead>
										<tbody>
											{currentTableOrders.map((order, index) => (
												<tr key={index}>
													<td>
														{order.itemName}
														{order.addOns?.length > 0 && (
															<div className="small text-muted">
																{order.addOns.map((addon, idx) => (
																	<div key={idx}>
																		+ {addon.addon || addon.addOn} (RS {addon.price})
																	</div>
																))}
															</div>
														)}
													</td>
													<td className="text-center">{order.quantity}</td>
													<td className="text-end">
														RS {((
															order.itemPrice + 
															(order.taxAmount || 0) +
															(order.addOns?.reduce((sum, addon) => 
																sum + (parseFloat(addon.price) || 0), 0) || 0)
														) * order.quantity).toFixed(2)}
													</td>
												</tr>
											))}
										</tbody>
										<tfoot>
											<tr>
												<td colSpan="2" className="text-end text-muted">Subtotal:</td>
												<td className="text-end text-muted">
												RS {currentTableOrders.reduce((sum, order) => {
													const addOnTotal = order.addOns?.reduce((addSum, addon) => 
													addSum + (parseFloat(addon.price) || 0), 0) || 0;
													return sum + ((
													parseFloat(order.itemPrice) + 
													addOnTotal
													) * order.quantity);
												}, 0).toFixed(2)}
												</td>
											</tr>
											{taxData && taxData.map((tax, index) => {
												const taxTotal = currentTableOrders.reduce((sum, order) => {
												const itemSubtotal = (order.itemPrice + 
													(order.addOns?.reduce((addSum, addon) => 
													addSum + (parseFloat(addon.price) || 0), 0) || 0)) * order.quantity;
												return sum + (itemSubtotal * (tax.taxRate / 100));
												}, 0);

												return (
												<tr key={index}>
													<td colSpan="2" className="text-end text-muted">
													{tax.taxType} ({tax.taxRate}%):
													</td>
													<td className="text-end text-muted">
													RS {taxTotal.toFixed(2)}
													</td>
												</tr>
												);
											})}
											<tr className="fw-bold">
												<td colSpan="2" className="text-end">Total:</td>
												<td className="text-end">
													RS {currentTableOrders.reduce((sum, order) => {
														const addOnTotal = order.addOns?.reduce((addSum, addon) => 
															addSum + (parseFloat(addon.price) || 0), 0) || 0;
														return sum + ((
															parseFloat(order.itemPrice) + 
															(order.taxAmount || 0) + 
															addOnTotal
														) * order.quantity);
													}, 0).toFixed(2)}
												</td>
											</tr>
										</tfoot>
									</table>
								</div>
							) : (
								<p className="text-center m-3">No active orders for this table.</p>
							)}
						</div>
						<div className="modal-footer">
							<button 
								type="button" 
								className="btn btn-secondary" 
								onClick={() => setShowTableOrders(false)}
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>

			</div>
		</>
	)
}

export default PosOutletCustomerOrder;
