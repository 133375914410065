import React, { useEffect, useContext, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import dishesServices from '../../api/DishesServices/disheseService.js';
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PosHeader from '../../components/topbar/posHeader.jsx';

function PosKitchenOrder() {
	const context = useContext(AppSettings);
	const [time] = useState(getTime());
	const [orderData, setOrderData] = useState([]);
	const [clickedButton, setClickedButton] = useState({});
	const stallId = localStorage.getItem('stallID');

	function checkTime(i) {
		if (i < 10) { i = "0" + i };
		return i;
	}
	
	function getTime() {
		var today = new Date();
		var h = today.getHours();
		var m = today.getMinutes();
		var a;
		m = checkTime(m);
		a = (h > 11) ? 'pm' : 'am';
		h = (h > 12) ? h - 12 : h;
	
		setTimeout(() => {
			getTime();
		}, 500);
		return h + ":" + m + a;
	}

	const handleButtonClick = (transactionID) => {
		setClickedButton((prevState) => ({
			...prevState,
			[transactionID]: prevState[transactionID] === '#72a4f1' ? 'red' : '#72a4f1',
		}));

		// Send SMS on button click
		sendSMS(transactionID);
	};
	

	const sendSMS = (transactionID) => {

		const smsData = {
			messages: [
				{
					destinations: [{ to: "94719527778" }], 
					from: "447491163443",
					text: `Dear Madushi,\nYour order is ready for pickup! We've carefully prepared your items (order 0001) and they're waiting for you at our worldspice outlet.`,
				},
			],
		};

		axios.post(process.env.REACT_APP_INFOBIP_SMS_API_URL, smsData, {
			headers: {
				Authorization: `App ${process.env.REACT_APP_INFOBIP_SMS_API_KEY}`,
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		})
		.then((response) => {
			toast.success("Complete was successful!");

		})
	
	};

	const fetchOrders = async () => {
		try {
			const response = await dishesServices().getAllOreders();
			const filteredOrders = response.data.filter(order => order.statusChange !== "VOID" && stallId.includes(order.stallId));
			setOrderData(filteredOrders);
		} catch (error) {
			console.error('Error fetching orders data:', error);
		}
	};

	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentFullHeight(true);
		context.setAppContentClass('p-0');
		
		fetchOrders();
		
		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentFullHeight(false);
			context.setAppContentClass('');
		};
	}, []);

	const orderName = nameChange();

	function nameChange() {
		if (stallId === 7) {
			return "Table";
		} else {
			return "Card No";
		}
	}

	return (
		<div className="pos pos-vertical pos-with-header" id="pos">
			<div className="pos-container">
			<div className="pos-header fixed-header">
				<PosHeader />
			</div>
				
				<div className="pos-content">
					<PerfectScrollbar className="pos-content-container h-100 p-0">
						{orderData && orderData.length > 0 ? (orderData.map((order, orderIndex) => (
							<div className="pos-task" key={orderIndex}>
								<div className="pos-task-info">
									<div className="h5 mb-1">{orderName} {order.cardId}</div>
									<div className="h3 mb-1" style={{ fontSize: '0.875rem' }}>Stall ID - {order.stallId}</div>
									<div className="h6 mb-1">
										Order ID - { order.transactionID === 0 ? '-' : order.transactionID }
									</div>
									<div className="pos-task-product-action">
										<button
											className="btn btn-primary"
											style={{
												backgroundColor: clickedButton[order.transactionID],
											}}
											onClick={() => handleButtonClick(order.transactionID)}
										>
											Complete
										</button>
									</div>
									{order.orderTime && (<div><span className={((order.urgent) ? 'text-danger' : '')}>{order.orderTime}</span> time</div>)}
									{order.totalOrderTime && (<div>All dish served<br />{order.totalOrderTime} total time</div>)}
								</div>
								<div className="pos-task-body">
									<div className="fs-16px mb-3"></div>
									<div className="row gx-4" >
										{order.transactionItems && order.transactionItems.length > 0 ? (order.transactionItems.map((transactionItem, index) => (
											<div className="col-lg-3" key={index} style={{
												margin: '10px',
												border: '3px solid #72a4f1',
												borderRadius: '10px'
											}}>
												<div className={'pos-task-product' + ((transactionItem.status === 'Completed' || transactionItem.status === 'Cancelled') ? ' completed' : '')}>
													<div className="pos-task-product-info">
														<div className="flex-1">
															<div className="d-flex mb-1">
																<div className="fs-5 mb-0 fw-semibold flex-1 fw-semibold">{transactionItem.itemName}</div>
																<div className="fs-5 mb-0 fw-semibold">x{transactionItem.quantity}</div>
															</div>
															<div className="small mb-2">
																{transactionItem.addOns && (transactionItem.addOns.map((addOns, index) => (
																	<div key={index}>- {addOns.key}: {addOns.addOn}</div>
																)))}
															</div>
														</div>
													</div>
												</div>
											</div>
										))) : (
											<div className="col-12">
												No records found
											</div>
										)}
									</div>
								</div>
							</div>
						))) : (
							<div>
								No records found
							</div>
						)}
					</PerfectScrollbar>
				</div>
			</div>
		</div>
	);
}

export default PosKitchenOrder;
