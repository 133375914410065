import React from "react";
import {API_CONFIGURATIONS} from "../constants/apiConfigurations";
import axios from "axios";

const executiveService = () => {

  const getAllExecutiveList = async () => { 
    const response = await axios.get(`${API_CONFIGURATIONS.GET_ALL_EXECUTIVE_LIST}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error.response;
      });
    return response;
  };

  const handleExecutiveCrediLimit = async (creditLimit, executiveCode) => {
    if (!executiveCode) {
      throw new Error("Invalid input: Executive Code or Credit Limit is missing/invalid.");
    }

    try {
      const response = await axios.put( `${API_CONFIGURATIONS.GET_ALL_EXECUTIVE_LIST}/${executiveCode}`, { creditLimit } );
      return response.data;
    } catch (error) {
      console.error("Error in request:", error);
      throw error.response || error;
    }
  };


  return {
    getAllExecutiveList,
    handleExecutiveCrediLimit,
  };
};
export default executiveService;